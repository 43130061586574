<section id="checkout-success" class="container-fluid">
  <ng-container *ngIf="(isLoading | async) === false">
    <ng-container *ngIf="isPaymentSuccessful">
      <div class="logo-container">
        <img src="assets/gifs/success.gif" alt="gif" />
      </div>

      <h4 class="flwmn-heading-6 fw-700 text-center">Payment Successful</h4>

      <p class="flwmn-para-1 text-center">
        Your payemt has been processed. Details of your <br />
        transaction are available in your billing page.
      </p>
    </ng-container>

    <ng-container *ngIf="!isPaymentSuccessful">
      <div class="logo-container">
        <img src="assets/gifs/failure.gif" alt="gif" />
      </div>

      <h4 class="flwmn-heading-6 fw-700 text-center">Verification Failed</h4>

      <p class="flwmn-para-1 text-center">
        Your payment was not successful, <br />
        details will be available in your billing page.
      </p>
    </ng-container>

    <div class="cta-container">
      <button
        *ngIf="!isLoggedIn && isPaymentSuccessful"
        matRipple
        class="btn flwmn-btn-primary"
        type="button"
        (click)="onInviteTeamMembers()"
      >
        Invite team members
      </button>

      <button
        *ngIf="isLoggedIn && isPaymentSuccessful"
        matRipple
        class="btn flwmn-btn-primary"
        type="button"
        (click)="onSetupUsers()"
      >
        Setup up your users
      </button>

      <button
        *ngIf="!isLoggedIn"
        matRipple
        class="btn flwmn-btn-primary-outline"
        type="button"
        (click)="onLogOut()"
      >
        Skip to login
      </button>

      <button
        *ngIf="isLoggedIn"
        matRipple
        class="btn flwmn-btn-primary-outline"
        type="button"
        [routerLink]="['/app/account-settings/billing']"
        routerLinkActive="router-link-active"
      >
        Back to billing
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="(isLoading | async) !== false">
    <h4 class="flwmn-heading-6 fw-700 text-center d-flex">
      Verifying Payment ...

      <app-button-loader [primaryColor]="true"></app-button-loader>
    </h4>

    <p class="flwmn-para-1 text-center">
      Sit tight while we verify your payment.
    </p>
  </ng-container>
</section>
