import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ActivatedRoute,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser,
  FacebookLoginProvider,
} from '@abacritt/angularx-social-login';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';
import { Observable, Subscription, filter } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ThirdPartyErrorComponent } from '../modals/third-party-error/third-party-error.component';
import { HelperService } from 'src/app/@core/services/helper.service';
import { regexValidator } from 'src/app/@core/utils/helpers';
import { ThemeService } from 'src/app/@core/services/theme.service';
import { InteractionStatus } from '@azure/msal-browser';
import {
  ConfirmDeviceStatusEnum,
  ThirdPartyTypeEnum,
} from 'src/app/@core/enums';
import { AuthService } from 'src/app/@core/services/auth.service';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import * as GeneralActions from 'src/app/@core/stores/general/general.actions';
import * as generalSelectors from 'src/app/@core/stores/general/general.selectors';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loadingRouteConfig: boolean = false;
  isLoading!: Observable<boolean>;

  confirmDeviceStatus!: Observable<ConfirmDeviceStatusEnum>;

  verifyStatus: boolean = false;
  isDeviceVerified: boolean = false;
  deviceGuid: string | null = null;
  year!: number;
  loginForm!: FormGroup;
  show: boolean = false;
  socialUser!: SocialUser;
  loginInstance: 'normal' | 'verifiedEmail' = 'normal';
  isFromDeveloperWebsite: boolean = false;
  ipAddress: string;
  location: string;

  intervalId: any;
  startTime: number;

  minutes: number = 0;
  seconds: number = 0;
  interval: any;
  countdownDuration: number = 5 * 60;

  private subscription: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private socialAuthService: SocialAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    public dialog: MatDialog,
    private helperService: HelperService,
    private themeService: ThemeService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService
  ) {
    this.subscription.add(
      this.route.queryParams.subscribe((res) => {
        if (res?.developer) {
          this.isFromDeveloperWebsite = res?.developer;
        }
      })
    );
  }

  ngOnInit(): void {
    this.themeService.removeWebappThemes();

    this.listenToLazyLoadedModules();

    this.isLoading = this.store.pipe(select(authSelectors.getAuthIsLoading));

    this.confirmDeviceStatus = this.store.pipe(
      select(authSelectors.getAuthConfirmDeviceStatus)
    );

    this.year = new Date().getFullYear();

    this.createLoginForm();

    this.manageLoginInstance();

    this.authService.listenToAzureADLoginSuccess();

    this.manageAzureADLoginAfterRedirect();

    this.getIpAddressDetails();

    this.listenToGetIpAddressDetailsSelector();

    this.listenToVerifyDevice();

    this.listenForConfirmDeviceStatus();
  }

  listenToLazyLoadedModules() {
    this.subscription.add(
      this.router.events.subscribe((event) => {
        window.scrollTo(0, 0);

        if (event instanceof RouteConfigLoadStart) {
          this.loadingRouteConfig = true;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.loadingRouteConfig = false;
        }
      })
    );
  }

  listenToVerifyDevice() {
    this.subscription.add(
      this.store
        .pipe(select(authSelectors.getAuthToVerifyDevice))
        .subscribe((resp: any) => {
          if (resp) {
            this.verifyStatus = resp.verifyStatus;
            this.deviceGuid = resp.deviceGuid;

            if (this.verifyStatus === true) {
              this.startPeriodicToGetDeviceStatus();
              this.startCountdown();
            }
          }
        })
    );
  }

  listenForConfirmDeviceStatus() {
    this.subscription.add(
      this.store
        .pipe(select(authSelectors.getAuthConfirmDeviceStatus))
        .subscribe((resp: any) => {
          if (resp && resp.verifyDeviceStatus.isSuccess === true) {
            if (resp.verifyDeviceStatus.isDeviceVerified === true) {
              this.stopPeriodicalToGetDeviceStatus();
              this.isDeviceVerified = true;
            } else if (
              resp.verifyDeviceStatus.deviceVerificationStatus ===
              ConfirmDeviceStatusEnum.Rejected
            ) {
              window.location.reload();
            }
          }
        })
    );
  }

  createLoginForm() {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [
        null,
        [
          Validators.required,
          regexValidator(new RegExp('^(?=.*?[A-Z])'), {
            uppercaseLetter: true,
          }),
          regexValidator(new RegExp('(?=.*?[a-z])'), { lowercaseLetter: true }),
          regexValidator(new RegExp('(?=.*?[0-9])'), { number: true }),
          regexValidator(new RegExp('(?=.*?[#?!@$%^&*-])'), {
            specialCharacter: true,
          }),
          regexValidator(new RegExp('.{8,}$'), { minimum: true }),
          // Validators.pattern(
          //   '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
          // ),
        ],
      ],
    });
  }

  get loginFormControls() {
    return this.loginForm.controls;
  }

  getErrorMessage(instance: string) {
    this.loginForm.get('email')?.updateValueAndValidity();
    if (
      instance === 'email' &&
      this.loginFormControls.email.hasError('required')
    ) {
      return 'Please enter your email';
    } else if (
      instance === 'email' &&
      this.loginFormControls.email.hasError('email')
    ) {
      return 'Sorry, this is not a valid email';
    } else if (
      instance === 'password' &&
      this.loginFormControls.password.hasError('required')
    ) {
      return 'Please enter your password';
    } else if (
      instance === 'password' &&
      this.loginFormControls['password'].hasError('uppercaseLetter')
    ) {
      return 'Your password must have at least 1 uppercase letter';
    } else if (
      instance === 'password' &&
      this.loginFormControls['password'].hasError('lowercaseLetter')
    ) {
      return 'Your password must have at least 1 lowercase letter.';
    } else if (
      instance === 'password' &&
      this.loginFormControls['password'].hasError('number')
    ) {
      return 'Your password must have at least a digit (0-9)';
    } else if (
      instance === 'password' &&
      this.loginFormControls['password'].hasError('specialCharacter')
    ) {
      return 'Your password must have at least a special character';
    } else if (
      instance === 'password' &&
      this.loginFormControls['password'].hasError('minimum')
    ) {
      return 'Your password must have at least a minimum of 8 characters.';
    }
    // else if (
    //   instance === 'password' &&
    //   this.loginFormControls.password.hasError('pattern')
    // ) {
    //   return 'Your password must have at least 1 uppercase, digit (0-9), special character, and a minimum of 8 characters.';
    // }
    else {
      return;
    }
  }

  manageLoginInstance() {
    const isEmailVerified = this.route.snapshot.queryParams['isEmailVerified'];
    const email = this.route.snapshot.queryParams['email'];

    if (isEmailVerified === 'true' && email) {
      this.loginInstance = 'verifiedEmail';

      this.loginForm.patchValue({
        email: email,
      });
    } else {
      this.loginInstance = 'normal';
    }
  }

  onLoginWithGoogle(): void {
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((userData) => {
        if (userData.email) {
          this.helperService.manageDeveloperTokenAndCallFunction(
            this.loginWithGoogle.bind(this, userData)
          );
        } else {
          this.onThirdPartySignInError('Google');
        }
      });
  }

  loginWithGoogle(userData: SocialUser) {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.store.dispatch(
      AuthActions.LoginWithThirdParty({
        payload: {
          email: userData.email,
          thirdPartyType: ThirdPartyTypeEnum.Google,
          keep_me_logged_in: true,
        },
      })
    );
  }

  onLoginWithFacebook(): void {
    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((userData) => {
        this.helperService.manageDeveloperTokenAndCallFunction(
          this.loginWithFacebook.bind(this, userData)
        );

        if (!userData.email) {
          this.onThirdPartySignInError('Facebook');
        }
      });
  }

  loginWithFacebook(userData: SocialUser) {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.store.dispatch(
      AuthActions.LoginWithThirdParty({
        payload: {
          email: userData.email,
          thirdPartyType: ThirdPartyTypeEnum.Facebook,
          keep_me_logged_in: true,
        },
      })
    );
  }

  onThirdPartySignInError(thirdPartyType: string) {
    this.dialog.open(ThirdPartyErrorComponent, {
      data: thirdPartyType,
      disableClose: true,
      panelClass: 'third-party-error-container',
    });
  }

  onLoginWithLinkedIn(): void {
    this.helperService.manageDeveloperTokenAndCallFunction(
      this.loginWithLinkedIn.bind(this)
    );
  }

  loginWithLinkedIn() {
    this.store.dispatch(AuthActions.LinkedInSignUp());

    if (localStorage.getItem('linkedInAction') !== null) {
      localStorage.removeItem('linkedInAction');
    }

    localStorage.setItem('linkedInAction', 'Login');
  }

  onLoginWithMicrosoft() {
    this.authService.initializeLoginWithAzureAD();
  }

  manageAzureADLoginAfterRedirect() {
    this.subscription.add(
      this.msalBroadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          )
        )
        .subscribe(() => {
          const userData = this.msalService.instance.getActiveAccount();

          if (userData) {
            this.store.dispatch(AuthActions.IsLoading({ payload: true }));

            this.store.dispatch(
              AuthActions.LoginWithThirdParty({
                payload: {
                  email: userData.username,
                  thirdPartyType: ThirdPartyTypeEnum.Microsoft,
                  keep_me_logged_in: true,
                },
              })
            );
          }
        })
    );
  }

  onSubmit() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.login.bind(this)
    );
  }

  login() {
    this.store.dispatch(
      AuthActions.LoginStart({
        payload: {
          email: this.loginForm.value.email,
          password: this.loginForm.value.password,
          keep_me_logged_in: true,
          ipAddress: this.ipAddress,
          location: this.location,
          browser: `${this.helperService.getBrowserName()}${
            window.screen.width * window.devicePixelRatio
          }${window.screen.height * window.devicePixelRatio}${
            navigator.appVersion
          }`,
          deviceId: '',
          deviceName: '',
        },
      })
    );
  }

  getIpAddressDetails() {
    this.store.dispatch(GeneralActions.GetIpAddressDetails());
  }

  listenToGetIpAddressDetailsSelector() {
    this.subscription.add(
      this.store
        .select(generalSelectors.getIpAddressDetails)
        .subscribe((resData: any) => {
          if (resData !== null) {
            this.ipAddress = resData?.ip;
            this.location = resData?.country_name;
            // console.log(resData);
          }
        })
    );
  }

  getDeviceStatus() {
    if (this.deviceGuid !== null) {
      this.store.dispatch(
        AuthActions.ConfirmDeviceStatus({
          payload: { deviceGuid: this.deviceGuid },
        })
      );
    }
  }

  startPeriodicToGetDeviceStatus(): void {
    const endTime = Date.now() + this.countdownDuration * 1000;

    this.getDeviceStatus();

    this.intervalId = setInterval(() => {
      if (Date.now() >= endTime) {
        this.stopPeriodicalToGetDeviceStatus();

        window.location.reload();
      } else {
        this.getDeviceStatus();
      }
    }, 10000);
  }

  stopPeriodicalToGetDeviceStatus(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startCountdown() {
    this.minutes = Math.floor(this.countdownDuration / 60);
    this.seconds = this.countdownDuration % 60;

    this.interval = setInterval(() => {
      if (this.countdownDuration > 0) {
        this.countdownDuration--;
        this.minutes = Math.floor(this.countdownDuration / 60);
        this.seconds = this.countdownDuration % 60;
      } else {
        this.clearCountdown();
      }
    }, 1000);
  }

  clearCountdown() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.clearCountdown();
    this.stopPeriodicalToGetDeviceStatus();
  }
}
