import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { InviteComponent } from './invite/invite.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { LinkedInResponseComponent } from './linked-in-response/linked-in-response.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { WelcomeOnboardComponent } from './welcome-onboard/welcome-onboard.component';
import { IssueComponent } from './issue/issue.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { DeviceVerificationComponent } from './device-verification/device-verification.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full',
  // },

  {
    path: 'login',
    component: LoginComponent,
  },

  {
    path: 'login/issue',
    component: IssueComponent,
  },

  // {
  //   path: 'register',
  //   redirectTo: 'register/individual',
  //   pathMatch: 'full',
  // },

  {
    path: 'register',
    component: RegisterComponent,
  },

  // {
  //   path: 'register/individual',
  //   component: RegisterComponent,
  // },

  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },

  {
    path: 'user-device-verification',
    component: DeviceVerificationComponent,
  },

  {
    path: 'reset',
    component: ResetPasswordComponent,
    children: [
      {
        path: '?email=email&token=token',
        component: ResetPasswordComponent,
      },
    ],
  },

  // {
  //   path: 'register/corporate',
  //   component: RegisterComponent,
  // },

  {
    path: 'register/welcome-onboard/:email',
    component: WelcomeOnboardComponent,
  },

  {
    path: 'register/issue',
    component: IssueComponent,
  },

  {
    path: 'invite',
    component: InviteComponent,
    children: [
      {
        path: '?email=email',
        component: InviteComponent,
      },
    ],
  },

  {
    path: 'verify',
    component: EmailConfirmationComponent,
    children: [
      {
        path: '?email=email&token=token',
        component: EmailConfirmationComponent,
      },
    ],
  },

  {
    path: 'linkedInResponse',
    component: LinkedInResponseComponent,
    children: [
      {
        path: '?code=code',
        component: LinkedInResponseComponent,
      },
    ],
  },

  {
    path: 'azure-active-directory',
    component: MsalRedirectComponent,
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
