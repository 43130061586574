<ng-container *ngIf="(isLoading | async) !== false">
  <div class="general-loader-container">
    <div class="general-loader-backdrop"></div>

    <div class="general-loader-content">
      <app-general-loader></app-general-loader>
    </div>
  </div>
</ng-container>

<section id="reset-password">
  <div class="wallpaper-container">
    <div class="wallpaper-illustrations-container">
      <img
        src="/assets/images/auth/auth-wallpaper-illustration-left.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-left"
      />

      <img
        src="/assets/images/auth/auth-wallpaper-illustration-right.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-right"
      />
    </div>
  </div>

  <div class="page">
    <div class="page-inner">
      <div class="body">
        <div class="logo-container">
          <a routerLink="/">
            <img
              src="/assets/logo/flowmono-logo-white.svg"
              alt="Flowmono Logo White"
              class="logo"
            />
          </a>
        </div>

        <div class="content-section">
          <div class="form-section">
            <div class="reset-password-image-container">
              <img src="/assets/icons/auth/password.svg" alt="Reset Password" />
            </div>

            <h5 class="flwmn-heading-5 fw-700 header-text">Reset Password?</h5>

            <p class="flwmn-caption subheader-text">
              Your new password must be different from previously used password.
            </p>

            <form [formGroup]="resetPasswordForm" (submit)="onSubmit()">
              <ng-template #visibleEyeIcon>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3866 8.00001C10.3866 9.32001 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C9.31995 5.61334 10.3866 6.68001 10.3866 8.00001Z"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ng-template>

              <ng-template #hiddenEyeIcon>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.68661 6.31334L6.31328 9.68668C5.87995 9.25334 5.61328 8.66001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C8.65995 5.61334 9.25328 5.88001 9.68661 6.31334Z"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.8799 3.84669C10.7132 2.96669 9.3799 2.48669 7.9999 2.48669C5.64656 2.48669 3.45323 3.87336 1.92656 6.27336C1.32656 7.21336 1.32656 8.79336 1.92656 9.73336C2.45323 10.56 3.06656 11.2734 3.73323 11.8467"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.61328 13.02C6.37328 13.34 7.17995 13.5133 7.99995 13.5133C10.3533 13.5133 12.5466 12.1267 14.0733 9.72668C14.6733 8.78668 14.6733 7.20668 14.0733 6.26668C13.8533 5.92002 13.6133 5.59335 13.3666 5.28668"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.34 8.46667C10.1666 9.40668 9.39996 10.1733 8.45996 10.3467"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.31325 9.68665L1.33325 14.6666"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6668 1.33331L9.68677 6.31331"
                    stroke="var(--flwmn-primary-color)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ng-template>

              <div class="input-style1 input-md mb-1">
                <label class="input-label">New Password</label>

                <mat-form-field appearance="outline" [style.width.%]="100">
                  <input
                    matInput
                    [type]="show ? 'text' : 'password'"
                    formControlName="newPassword"
                  />

                  <span matSuffix class="img-fluid" (click)="show = !show">
                    <ng-container *ngIf="show">
                      <ng-container
                        *ngTemplateOutlet="visibleEyeIcon"
                      ></ng-container>
                    </ng-container>

                    <ng-container *ngIf="!show">
                      <ng-container
                        *ngTemplateOutlet="hiddenEyeIcon"
                      ></ng-container>
                    </ng-container>
                  </span>

                  <mat-error
                    *ngIf="resetPasswordFormControls.newPassword.invalid"
                  >
                    <img
                      src="/assets/icons/error.svg"
                      alt=""
                      class="img-fluid error-icon"
                    />{{ getErrorMessage('newPassword') }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="input-style1 input-md mb-1">
                <label class="input-label">Repeat Password</label>

                <mat-form-field appearance="outline" [style.width.%]="100">
                  <input
                    matInput
                    [type]="show2 ? 'text' : 'password'"
                    formControlName="confirmNewPassword"
                  />

                  <span matSuffix class="img-fluid" (click)="show2 = !show2">
                    <ng-container *ngIf="show2">
                      <ng-container
                        *ngTemplateOutlet="visibleEyeIcon"
                      ></ng-container>
                    </ng-container>

                    <ng-container *ngIf="!show2">
                      <ng-container
                        *ngTemplateOutlet="hiddenEyeIcon"
                      ></ng-container>
                    </ng-container>
                  </span>

                  <mat-error
                    *ngIf="resetPasswordFormControls.confirmNewPassword.invalid"
                  >
                    <img
                      src="/assets/icons/error.svg"
                      alt=""
                      class="img-fluid error-icon"
                    />{{ getErrorMessage('confirmNewPassword') }}
                  </mat-error>
                </mat-form-field>
              </div>

              <button
                matRipple
                type="submit"
                class="btn flwmn-btn-primary flwmn-btn-md flwmn-btn-full reset-btn"
                [disabled]="resetPasswordForm.invalid"
              >
                Reset password
              </button>
            </form>
          </div>
        </div>
      </div>

      <footer>
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>
  </div>
</section>
