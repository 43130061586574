import { createReducer, on, Action } from '@ngrx/store';
import * as SLACOnfigActions from './sla-configuration.actions';
import { ConfigurationInterface } from '../../interfaces/sla-configuration.interface';

export interface SLAConfigurationState {
  creationStatus: boolean;
  configurations: ConfigurationInterface[];
  products: any[];
  microservices: any[];
  targetObjects: any[];
  isLoading: boolean;
  currentConfiguration: ConfigurationInterface | null;
}

const initialState: SLAConfigurationState = {
  creationStatus: false,
  configurations: [],
  products: [],
  microservices: [],
  targetObjects: [],
  isLoading: false,
  currentConfiguration: null,
};

const slaConfigurationReducerInternal = createReducer(
  initialState,
  on(SLACOnfigActions.SaveAllConfigurations, (state, { payload }) => ({
    ...state,
    configurations: payload,
  })),
  on(SLACOnfigActions.SaveAllProducts, (state, { payload }) => {
    return {
      ...state,
      products: [...payload],
    };
  }),
  on(SLACOnfigActions.SaveMicroservices, (state, { payload }) => {
    return {
      ...state,
      microservices: [...payload],
    };
  }),
  on(SLACOnfigActions.SaveTargetObjects, (state, { payload }) => {
    return {
      ...state,
      targetObjects: payload,
    };
  }),
  on(SLACOnfigActions.CreationStatus, (state, { payload }) => {
    return {
      ...state,
      creationStatus: payload,
    };
  }),
  on(SLACOnfigActions.IsLoading, (state, { payload }) => {
    return {
      ...state,
      isLoading: payload,
    };
  }),
  on(SLACOnfigActions.GetCurrentConfiguration, (state, { payload }) => ({
    ...state,
    currentConfiguration:
      state.configurations.find((config) => config.guId === payload) ?? null,
  }))
);

export const slaConfigurationReducer = (
  state: SLAConfigurationState | undefined,
  action: Action
) => {
  return slaConfigurationReducerInternal(state, action);
};
