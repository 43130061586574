import { createReducer, on, Action } from '@ngrx/store';
import * as MangageSLAActions from './manage-sla.actions';

export interface ManageSLAState {
  showDashboard: boolean;
  isLoading: boolean;
  signingRequestCategories: any[];
  processCategories: any[];
  departments: any[];
  users: any[];
  units: any[];
  roles: any[];
  divisions: any[];
  allSlaInstanaces: {
    entity: any[];
    count: number;
  };
  runningSlaInstanaces: {
    entity: any[];
    count: number;
  };
  pausedSlaInstanaces: {
    entity: any[];
    count: number;
  };
  passedSlaInstanaces: {
    entity: any[];
    count: number;
  };
  completedSlaInstanaces: {
    result: any[];
    count: number;
  };
  failedSlaInstanaces: {
    entity: any[];
    count: number;
  };
  currentSLA: { [key: string]: any };
  showAnalytics: { [key: string]: boolean };
  donutChartData: any[];
  dashboardData: { [key: string]: any };
  topFiveTeams: any[];
  topFivePerformers: any[];
  metricsPercentageData: any[];
  lineChartData: any[];
  escalationRateData: { [key: string]: any };
  averageCompletion: any[];
  gotoTourNextStep: number;
  creationSuccessful: boolean;
  slaById: { [key: string]: any };
  slaByCategoryName: { [key: string]: any };
  slaInstancesById: { [key: string]: any };
}

const initialState: ManageSLAState = {
  isLoading: false,
  showDashboard: false,
  signingRequestCategories: [],
  processCategories: [],
  departments: [],
  users: [],
  units: [],
  roles: [],
  divisions: [],
  allSlaInstanaces: {
    entity: [],
    count: 0,
  },
  runningSlaInstanaces: {
    entity: [],
    count: 0,
  },
  pausedSlaInstanaces: {
    entity: [],
    count: 0,
  },
  passedSlaInstanaces: {
    entity: [],
    count: 0,
  },
  completedSlaInstanaces: {
    result: [],
    count: 0,
  },
  failedSlaInstanaces: {
    entity: [],
    count: 0,
  },
  currentSLA: {},
  donutChartData: [],
  dashboardData: {},
  topFiveTeams: [],
  topFivePerformers: [],
  metricsPercentageData: [],
  lineChartData: [],
  escalationRateData: {},
  averageCompletion: [],
  showAnalytics: {},
  gotoTourNextStep: 0,
  creationSuccessful: false,
  slaById: {},
  slaByCategoryName: {},
  slaInstancesById: [],
};

const manageSLAReducerInternal = createReducer(
  initialState,
  on(MangageSLAActions.ToggleDashboard, (state, { payload }) => ({
    ...state,
    showDashboard: payload,
  })),
  on(MangageSLAActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(MangageSLAActions.SaveSigningRequestCategories, (state, { payload }) => ({
    ...state,
    signingRequestCategories: [...payload],
  })),
  on(MangageSLAActions.SaveProcessCategories, (state, { payload }) => ({
    ...state,
    processCategories: [...payload],
  })),
  on(MangageSLAActions.SaveAllDepartments, (state, { payload }) => ({
    ...state,
    departments: [...payload],
  })),
  on(MangageSLAActions.SaveAllDivisions, (state, { payload }) => ({
    ...state,
    divisions: [...payload],
  })),
  on(MangageSLAActions.SaveAllUsers, (state, { payload }) => ({
    ...state,
    users: [...payload],
  })),
  on(MangageSLAActions.SaveAllRoles, (state, { payload }) => ({
    ...state,
    roles: [...payload],
  })),
  on(MangageSLAActions.SaveAllUnits, (state, { payload }) => ({
    ...state,
    units: [...payload],
  })),
  on(MangageSLAActions.CreationStatus, (state, { payload }) => ({
    ...state,
    creationSuccessful: payload,
  })),
  on(MangageSLAActions.SaveSlaById, (state, { payload }) => ({
    ...state,
    slaById: payload,
  })),
  on(MangageSLAActions.SaveSlaByCategoryName, (state, { payload }) => {
    return {
      ...state,
      slaByCategoryName: payload,
    };
  }),
  on(MangageSLAActions.SaveAllSlaInstances, (state, { payload }) => ({
    ...state,
    allSlaInstanaces: {
      entity: payload.result,
      count: payload.count,
    },
  })),
  on(MangageSLAActions.SaveRunningSlaInstances, (state, { payload }) => ({
    ...state,
    runningSlaInstanaces: {
      entity: payload.entity,
      count: payload.count,
    },
  })),
  on(MangageSLAActions.SavePausedSlaInstances, (state, { payload }) => ({
    ...state,
    pausedSlaInstanaces: {
      entity: payload.entity,
      count: payload.count,
    },
  })),
  on(MangageSLAActions.SaveCompletedSlaInstances, (state, { payload }) => ({
    ...state,
    completedSlaInstanaces: {
      result: payload.result,
      count: payload.count,
    },
  })),
  on(MangageSLAActions.SavePassedSlaInstances, (state, { payload }) => ({
    ...state,
    passedSlaInstanaces: {
      entity: payload.entity,
      count: payload.count,
    },
  })),
  on(MangageSLAActions.SaveFailedSlaInstances, (state, { payload }) => ({
    ...state,
    failedSlaInstanaces: {
      entity: payload.entity,
      count: payload.count,
    },
  })),
  on(MangageSLAActions.SaveSlaInstancesById, (state, { payload }) => ({
    ...state,
    slaInstancesById: payload,
  })),
  on(MangageSLAActions.SaveSlaDashboardData, (state, { payload }) => {
    return {
      ...state,
      dashboardData: payload,
    };
  }),
  on(MangageSLAActions.SaveAverageCompletionData, (state, { payload }) => {
    return {
      ...state,
      averageCompletion: payload,
    };
  }),
  on(MangageSLAActions.SaveEscalationRateData, (state, { payload }) => {
    return {
      ...state,
      escalationRateData: payload,
    };
  }),
  on(MangageSLAActions.SaveTopFiveTeams, (state, { payload }) => ({
    ...state,
    topFiveTeams: payload,
  })),
  on(MangageSLAActions.SaveTopFivePerformers, (state, { payload }) => ({
    ...state,
    topFivePerformers: payload,
  })),
  on(MangageSLAActions.SaveSlaMetricsPercentageData, (state, { payload }) => ({
    ...state,
    metricsPercentageData: [...payload],
  })),
  on(MangageSLAActions.SaveSlaDonutData, (state, { payload }) => ({
    ...state,
    donutChartData: payload,
  })),
  on(MangageSLAActions.SaveSlaLineChartData, (state, { payload }) => ({
    ...state,
    lineChartData: [...payload],
  })),
  on(MangageSLAActions.UpdateShowAnalytics, (state, { payload }) => ({
    ...state,
    showAnalytics: payload,
  })),
  on(MangageSLAActions.TourNextStep, (state, { payload }) => ({
    ...state,
    gotoTourNextStep: payload,
  }))
);

export const manageSLAReducer = (
  state: ManageSLAState | undefined,
  action: Action
) => {
  return manageSLAReducerInternal(state, action);
};
