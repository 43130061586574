import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  share,
  // tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromApp from '../app/app.reducer';
import * as formBuilderDashboardActions from './fmbr-dashboard.actions';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { HelperService } from '../../services/helper.service';
import { NotificationService } from '../../services/notification.service';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';

@Injectable()
export class FmbrDashboardEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private helperService: HelperService,
    private notificationService: NotificationService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(
      formBuilderDashboardActions.IsLoading({ payload: false })
    );

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  GetFormbuilderDonutChartData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formBuilderDashboardActions.GetFormbuilderDonutChart),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/Project/piechartstats/${authState.user.SubscriberId}/${authState.user.UserId}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                formBuilderDashboardActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return formBuilderDashboardActions.SaveFormbuilderDonutChartData(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                this.store.dispatch(
                  formBuilderDashboardActions.SaveFormbuilderDonutChartData({
                    payload: [],
                  })
                );

                return {
                  type: '[Formbuilder Dashboard] Failed To Get Document Donut Chart Data Dashboard',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Formbuilder Dashboard][CatchError] Failed To Get Formbuilder Donut Chart Data Dashboard ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  GetFormbuilderLineChartData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formBuilderDashboardActions.GetFormbuilderLineChart),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/Project/barchartstats/${authState.user.SubscriberId}/${authState.user.UserId}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                formBuilderDashboardActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return formBuilderDashboardActions.SaveFormbuilderLineChartData(
                  {
                    payload: resData.entity,
                  }
                );
              } else {
                this.store.dispatch(
                  formBuilderDashboardActions.SaveFormbuilderLineChartData({
                    payload: [],
                  })
                );

                return {
                  type: `[Formbuilder Dashboard] Failed To Get Formbuilder Line Chart Data Dashboard`,
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Formbuilder Dashboard][CatchError] Failed To Get Formbuilder Line Chart Data Dashboard ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getDashboardMetric$ = createEffect(() =>
    this.actions$.pipe(
      ofType(formBuilderDashboardActions.GetDashboardMetric),
      withLatestFrom(this.store.select('auth')),
      switchMap(([dashboardData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/Project/projectmetrics/${authState.user?.SubscriberId}/${authState.user?.UserId}/0/1`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              if (resData.succeeded === true) {
                return formBuilderDashboardActions.SaveDashboardMetric({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return formBuilderDashboardActions.SaveDashboardMetric({
                  payload: [],
                });
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[Formbuilder Dashboard][CatchError] Failed To Get Dashboard Metric ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getDraftsAction$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(formBuilderDashboardActions.GetDraftsAction),
        withLatestFrom(this.store.select('auth')),
        switchMap(([dashboardData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/Project/projectstatus/${authState.user.SubscriberId}/${authState.user.UserId}/1/${dashboardData.payload.skip}/${dashboardData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  formBuilderDashboardActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Formbuilder Dashboard] Get Dashboard Drafts Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Formbuilder Dashboard] Failed To Get Dashboard Drafts',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Formbuilder Dashboard][CatchError] Failed To Get Dashboard Drafts ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getRecentProjectsAction$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(formBuilderDashboardActions.GetRecentProjectsAction),
        withLatestFrom(this.store.select('auth')),
        switchMap(([dashboardData, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/Project/projects/${authState.user.SubscriberId}/${authState.user.UserId}/${dashboardData.payload.skip}/${dashboardData.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  formBuilderDashboardActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Formbuilder Dashboard] Get Dashboard Recent Projects Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Formbuilder Dashboard] Failed To Get Dashboard Recent Projects',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Formbuilder Dashboard][CatchError] Failed To Get Dashboard Recent Projects ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );
}
