import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction('[My forms] Reset Store');

export const IsLoading = createAction(
  '[My forms] Is Loading',
  props<{
    payload: boolean;
  }>()
);
export const IsLoadingDeleteForm = createAction(
  '[My forms] Is Loading Delete Form',
  props<{
    payload: boolean;
  }>()
);

export const IsLoadingRenameForm = createAction(
  '[My forms] Is Loading Rename Form',
  props<{
    payload: boolean;
  }>()
);

export const GetAllForms = createAction(
  '[My forms] Get All Forms',
  props<{
    payload: {
      page: number;
      take: number;
    };
  }>()
);

export const SaveAllForms = createAction(
  '[My forms] Save All Forms',
  props<{
    payload: {
      data: any;
      count: number;
    };
  }>()
);

export const DeleteForm = createAction(
  '[My forms] Delete Form ',
  props<{ payload: { projectGuId: string } }>()
);

export const RenameForm = createAction(
  '[My forms] Rename Form',
  props<{
    payload: {
      projectGuId: number;
      name: string;
    };
  }>()
);
