import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
  mergeMap,
} from 'rxjs/operators';
import * as RecipientSignatureAction from './recipient-signature.actions';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import {
  USE_DEVELOPER_TOKEN,
  USE_ACCESS_TOKEN,
} from '../../interceptors/app.interceptor.service';
import { HelperService } from '../../services/helper.service';

@Injectable()
export class RecipientSignatureEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    this.store.dispatch(RecipientSignatureAction.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getRecipientActiveSignatures$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RecipientSignatureAction.GetRecipientActiveSignatures),
        withLatestFrom(this.store.select('auth')),
        mergeMap(([recipientSignatureData, authState]) => {
          const subscriberId = recipientSignatureData.payload.subscriberId
            ? recipientSignatureData.payload.subscriberId
            : authState.user.SubscriberId;
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/RecipientSignature/${subscriberId}/${recipientSignatureData.payload.email}/${recipientSignatureData.payload.skip}/${recipientSignatureData.payload.take}`,

              {
                // params: params,
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  RecipientSignatureAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    RecipientSignatureAction.SaveRecipientActiveSignatures({
                      payload: resData.entity,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Recipient Signature] Failed To Get Recipient Active Signatures',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Recipient Signature] Failed To Get Recipient Active Signatures ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getRecipientSignatures$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RecipientSignatureAction.GetRecipientSignatures),
        withLatestFrom(this.store.select('auth')),
        switchMap(([recipientSignatureData, authState]) => {
          const subscriberId = recipientSignatureData.payload.subscriberId
            ? recipientSignatureData.payload.subscriberId
            : authState.user.SubscriberId;
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/RecipientSignature/all/${subscriberId}/${recipientSignatureData.payload.email}/${recipientSignatureData.payload.skip}/${recipientSignatureData.payload.take}`,

              {
                // params: params,
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  RecipientSignatureAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    RecipientSignatureAction.SaveRecipientSignatures({
                      payload: resData.entity,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Recipient Signature] Failed To Get Recipient Signatures',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Recipient Signature] Failed To Get Recipient Signatures ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getRecipientDefaultSignature$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RecipientSignatureAction.GetRecipientDefaultSignature),
        withLatestFrom(this.store.select('auth')),
        mergeMap(([recipientSignatureData, authState]) => {
          const subscriberId = recipientSignatureData.payload.subscriberId
            ? recipientSignatureData.payload.subscriberId
            : authState.user.SubscriberId;
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/RecipientSignature/default/${subscriberId}/${recipientSignatureData.payload.email}`,

              {
                // params: params,
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  RecipientSignatureAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch(
                    RecipientSignatureAction.SaveRecipientDefaultSignature({
                      payload: resData.entity,
                    })
                  );
                } else {
                  this.store.dispatch({
                    type: '[Recipient Signature] Failed To Get Recipient Default Signature',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Recipient Signature] Failed To Get Recipient Default Signature ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  createRecipientSignature$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RecipientSignatureAction.CreateRecipientSignature),
        withLatestFrom(this.store.select('auth')),
        switchMap(([recipientSignatureData, authState]) => {
          const payload = {
            ...recipientSignatureData.payload,
            subscriberId: recipientSignatureData.payload.subscriberId
              ? recipientSignatureData.payload.subscriberId
              : authState.user.SubscriberId,
            // userId: null,
          };
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/RecipientSignature/create`,
              payload,

              {
                // params: params,
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  RecipientSignatureAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Recipient Signature] Recipient Signature Creation Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Recipient Signature] Recipient Signature Creation Was Failed',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Recipient Signature] Recipient Signature Creation Was Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  updateRecipientSignature$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RecipientSignatureAction.UpdateRecipientSignature),
        withLatestFrom(this.store.select('auth')),
        switchMap(([recipientSignatureData, authState]) => {
          const payload = {
            ...recipientSignatureData.payload,
            subscriberId: recipientSignatureData.payload.subscriberId
              ? recipientSignatureData.payload.subscriberId
              : authState.user.SubscriberId,
            // userId: null,
          };
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/RecipientSignature/update`,
              payload,

              {
                // params: params,
                context: new HttpContext().set(USE_DEVELOPER_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  RecipientSignatureAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.store.dispatch({
                    type: '[Recipient Signature] Recipient Signature Was Updated Successfully',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Recipient Signature] Failed To Update Recipient Signature',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Recipient Signature] Failed To Update Recipient Signature ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  setRecipientSignatureToDefault$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RecipientSignatureAction.SetRecipientSignatureToDefault),
        withLatestFrom(this.store.select('auth')),
        switchMap(([recipientSignatureData, authState]) => {
          const payload = {
            ...recipientSignatureData.payload,
            subscriberId: recipientSignatureData.payload.subscriberId
              ? recipientSignatureData.payload.subscriberId
              : authState.user.SubscriberId,
            // userId: authState.user.UserId,
          };
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/RecipientSignature/default`,
              payload,

              {
                // params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  RecipientSignatureAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Recipient Signature] Recipient Signature Creation Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Recipient Signature] Recipient Signature Creation Was Failed',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Recipient Signature] Recipient Signature Creation Was Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  activateRecipientSignature$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RecipientSignatureAction.ActivateRecipientSignature),
        withLatestFrom(this.store.select('auth')),
        switchMap(([recipientSignatureData, authState]) => {
          const payload = {
            ...recipientSignatureData.payload,
            subscriberId: recipientSignatureData.payload.subscriberId
              ? recipientSignatureData.payload.subscriberId
              : authState.user.SubscriberId,
            userId: authState.user.UserId,
          };
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/RecipientSignature/activate`,
              payload,

              {
                // params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  RecipientSignatureAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Recipient Signature] Recipient Signature Activation Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Recipient Signature] Recipient Signature Action Was Failed',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Recipient Signature] Recipient Signature Action Was Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deactivateRecipientSignature$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RecipientSignatureAction.DeactivateRecipientSignature),
        withLatestFrom(this.store.select('auth')),
        switchMap(([recipientSignatureData, authState]) => {
          const payload = {
            ...recipientSignatureData.payload,
            subscriberId: recipientSignatureData.payload.subscriberId
              ? recipientSignatureData.payload.subscriberId
              : authState.user.SubscriberId,
            userId: authState.user.UserId,
          };
          return this.http
            .post<GenericResponse>(
              `${environment.onyxDocDocumentUrl}/RecipientSignature/deactivate`,
              payload,

              {
                // params: params,
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  RecipientSignatureAction.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  this.store.dispatch({
                    type: '[Recipient Signature] Recipient Signature Deactivation Was Successful',
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message,
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  this.store.dispatch({
                    type: '[Recipient Signature] Recipient Signature Deactivation Failed',
                  });
                }

                return resData;
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[Recipient Signature] Recipient Signature Deactivation Failed ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );
}
