import { createAction, props } from '@ngrx/store';

import { flowmonoAssistanceReducerState } from './flowmono-assistant.reducer';

export const ResetStore = createAction('[Flowmono Assistance] Reset Store');

export const IsLoading = createAction(
  '[Flowmono Assistance] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetDocumentInsight = createAction(
  '[Flowmono Assistance] Get Document Insight',
  props<{
    payload: {
      documentId: string;
    };
  }>()
);

export const SaveDocumentInsight = createAction(
  '[Flowmono Assistance] Save Document Insight',
  props<{
    payload: any;
  }>()
);

export const GetDocumentSummary = createAction(
  '[Flowmono Assistance] Get Document Summary',
  props<{
    payload: {
      documentId?: string;
    };
  }>()
);

export const SaveDocumentSummary = createAction(
  '[Flowmono Assistance] Save Document Summary',
  props<{
    payload: flowmonoAssistanceReducerState['documentSummary'];
  }>()
);

export const GetDocumentHistory = createAction(
  '[Flowmono Assistance] Get Document History',
  props<{
    payload: {
      documentId?: string;
    };
  }>()
);

export const SaveDocumentHistory = createAction(
  '[Flowmono Assistance] Get Document History',
  props<{
    payload: flowmonoAssistanceReducerState['documentHistory'];
  }>()
);

export const CreateFeedback = createAction(
  '[Flowmono Assistance] Create Feedback',
  props<{
    payload: {
      objectGuid?: string;
      objectName?: string;
      comment: string;
      feedbackRating: number;
      feedbackType: number;
    };
  }>()
);
