import { Action, createReducer, on } from '@ngrx/store';
import * as FormBuilderActions from './form-builder.actions';

export interface State {
  isLoading: boolean;
  form: any | null;
  getFormById: any | null;
  error: any;
  projectCategories: any;
  projectTemplates: any;
  invitedCollaborators: any;
}

export const initialState: State = {
  isLoading: false,
  form: null,
  getFormById: null,
  error: null,
  projectCategories: null,
  projectTemplates: null,
  invitedCollaborators: null,
};

const formBuilderReducerInternal = createReducer(
  initialState,
  on(FormBuilderActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(FormBuilderActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(FormBuilderActions.CreateForm, (state, { payload }) => ({
    ...state,
    form: payload,
  })),
  on(FormBuilderActions.UpdateForm, (state, { payload }) => ({
    ...state,
    form: payload,
  })),
  on(FormBuilderActions.SaveProjectCategories, (state, { payload }) => ({
    ...state,
    projectCategories: payload,
  })),
  on(FormBuilderActions.SaveProjectTemplates, (state, { payload }) => ({
    ...state,
    projectTemplates: payload,
  })),
  on(FormBuilderActions.SaveInvitedCollaborators, (state, { payload }) => ({
    ...state,
    invitedCollaborators: payload,
  }))
);

export function formBuilderReducer(state: State | undefined, action: Action) {
  return formBuilderReducerInternal(state, action);
}
