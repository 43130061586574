import { createReducer, Action, on } from '@ngrx/store';
import * as FlowmonoAssistanceActions from './flowmono-assistant.actions';

export interface flowmonoAssistanceReducerState {
  isLoading: boolean;
  documentInsights: any;
  documentSummary: any;
  documentHistory: any[];
}

const initialState: flowmonoAssistanceReducerState = {
  isLoading: false,
  documentInsights: null,
  documentSummary: null,
  documentHistory: [],
};

const flowmonoAssistanceReducerInternal = createReducer(
  initialState,
  on(FlowmonoAssistanceActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(FlowmonoAssistanceActions.SaveDocumentInsight, (state, { payload }) => ({
    ...state,
    documentInsights: payload,
  })),

  on(FlowmonoAssistanceActions.SaveDocumentSummary, (state, { payload }) => ({
    ...state,
    documentSummary: payload,
  })),

  on(FlowmonoAssistanceActions.SaveDocumentHistory, (state, { payload }) => ({
    ...state,
    documentHistory: payload,
  }))
);

export function flowmonoAssistanceReducer(
  state: flowmonoAssistanceReducerState | undefined,
  action: Action
) {
  return flowmonoAssistanceReducerInternal(state, action);
}
