import {
  ComponentRef,
  EmbeddedViewRef,
  EventEmitter,
  Injectable,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { ControlBuilderComponent } from 'src/app/@components/editor/control-builder/control-builder.component';
import { DefaultControlTypeStyles } from '../constants';
import { ControlModeEnum, ControlTypeEnum, SignTypeEnum } from '../enums';
import {
  ControlMode,
  EditorMode,
  EditorSignType,
  SendDocumentsToRecipientsInstance,
  SignDocumentInstance,
} from '../types';
import { DocumentDownloadInstance } from 'src/app/@core/types';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as EditorActions from 'src/app/@core/stores/editor/editor.actions';
import { Notification } from '../interfaces';
import { NotificationService } from './notification.service';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class EditorService {
  //TODO Remove Old Implementation
  /*****************************************OLD*****************************************/

  download = new BehaviorSubject<{
    value: boolean;
    getSignedDocumentUri: boolean;
    callDownloadDocumentAPI: boolean;
    // saveFileToDevice: boolean;
    saveFileTo: 'device' | 'google drive' | false;
  }>({
    value: false,
    getSignedDocumentUri: false,
    callDownloadDocumentAPI: false,
    saveFileTo: 'device',
  });
  signedFile = new Subject<any>();
  public recipientMenuEmitter: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  decodedComponentData = new Subject<any>();
  editorMode = new BehaviorSubject('edit');
  selectedComment = new BehaviorSubject(null);
  removeAllComments = new Subject<any>();
  isComponentFormValid = new BehaviorSubject<boolean>(false);
  activePageControlItemId = new BehaviorSubject<number | null>(null);
  changeSignType = new BehaviorSubject<boolean>(true);
  signType = this.changeSignType.asObservable();

  open(): void {
    this.recipientMenuEmitter.emit(true);
  }

  close(): void {
    this.recipientMenuEmitter.emit(false);
  }
  /*************************************************************************************/

  onTouchStartInToolbar = new Subject<any>();

  constructor(
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  getFlowmonoControl(
    left: number,
    top: number,
    width: string | null,
    height: string | null,
    controlData: any,
    controlModeType: any,
    pageNumber: string,
    documentId: string
  ) {
    if (controlModeType === 'quickSign') {
      let control: any = {
        id: controlData.id,
        type: controlData.type,
        name: !controlData.name?.includes('_')
          ? `${controlData.name}_${Date.now()}`
          : controlData.name,
        label: controlData.label,
        value: controlData.value,
        selectOptions: controlData.selectOptions,
        validators: controlData.validators,
        disabled: controlData.disabled,
        coordinate: {
          position: 'absolute',
          width: width,
          height: height,
          transform: `translate3d(${left}px, ${top}px, 0px)`,
        },
        recipientId: controlData.recipientId,
        recipientEmail: controlData.recipientEmail,
        rank: 0,
        pageNumber: pageNumber,
        documentId: documentId,
      };

      if (
        controlData.type === ControlTypeEnum.text ||
        controlData.type === ControlTypeEnum.date ||
        controlData.type === ControlTypeEnum.name ||
        controlData.type === ControlTypeEnum.email ||
        controlData.type === ControlTypeEnum.jobTitle
      ) {
        control = {
          ...control,
          additionalStyles: {
            'font-family': controlData?.additionalStyles?.['font-family']
              ? controlData?.additionalStyles['font-family']
              : DefaultControlTypeStyles[controlData.type]['font-family'],

            'font-size': controlData?.additionalStyles?.['font-size']
              ? controlData?.additionalStyles['font-size']
              : DefaultControlTypeStyles[controlData.type]['font-size'],

            'font-weight': controlData?.additionalStyles?.['font-weight']
              ? controlData?.additionalStyles['font-weight']
              : DefaultControlTypeStyles[controlData.type]['font-weight'],

            color: controlData?.additionalStyles?.['color']
              ? controlData?.additionalStyles['color']
              : DefaultControlTypeStyles[controlData.type]['color'],

            'text-align': controlData?.additionalStyles?.['text-align']
              ? controlData?.additionalStyles['text-align']
              : DefaultControlTypeStyles[controlData.type]['text-align'],
          },
        };
      }

      return control;
    } else {
      return null;
    }
  }

  appendFlowmonoControlToDocument({
    control,
    dropEvent,
    viewContainerRef,
    controlsForm,
    editorMode,
    controlMode,
    signType,
    isInternalDraftControl,
    showControlMenuBar,
  }: {
    control: any;
    dropEvent: any;
    viewContainerRef: ViewContainerRef;
    controlsForm: FormGroup;
    editorMode: EditorMode;
    controlMode: ControlMode;
    signType: EditorSignType;
    isInternalDraftControl: boolean;
    showControlMenuBar: boolean;
  }): ComponentRef<ControlBuilderComponent> {
    // 1. Create a component reference from the component
    const componentRef = viewContainerRef.createComponent(
      ControlBuilderComponent
    );

    /**
     * Old Method
     * const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(ComponentBuilderComponent)
      .create(this.injector);
     */

    componentRef.instance.editorMode = editorMode;
    componentRef.instance.controlMode = controlMode;
    componentRef.instance.form = controlsForm;
    componentRef.instance.control = control;
    componentRef.instance.id = control.name;
    componentRef.instance.signType = signType;
    componentRef.instance.showControlMenuBar = showControlMenuBar;
    componentRef.instance.dropEvent = dropEvent;

    if (isInternalDraftControl === true) {
      componentRef.instance.centerControlOnDrop = false;
    }

    // componentRef.instance.formControlEmitter.subscribe((resData) => {
    //   updateControlFormControls(resData);
    // });

    // componentRef.instance.remove.subscribe((resData) => {
    //   removeControl(resData);
    // });

    /**
     * Formerly step 2
     * 2. Attach component to the appRef so that it's inside the ng component tree
     * this.appRef.attachView(componentRef.hostView);
     */

    // 2. Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    if (
      control.type === ControlTypeEnum.signature &&
      control.disabled === false
    ) {
      domElem.setAttribute('title', 'Double click to sign');
    }

    if (
      controlMode === ControlModeEnum.External &&
      control.disabled === false
    ) {
      domElem.style.cursor = 'pointer';
    }

    // if (editorMode === EditorModeEnum.Internal) {
    //   if (isInternalDraftControl === false) {
    //     domElem.style.transform = `translate3d(${dropEvent.offsetX}px, ${dropEvent.offsetY}px, 0px)`;
    //     // domElem.style.transform = `translate3d(${dropEvent.layerX}px, ${dropEvent.layerY}px, 0px)`;
    //   } else if (isInternalDraftControl === true) {
    //     domElem.style.transform = control.coordinate.transform;
    //   }
    // } else if (editorMode === EditorModeEnum.External) {
    //   domElem.style.transform = control.coordinate.transform;
    // }

    domElem.style.position = control.coordinate.position;

    domElem.style.transform = `translate3d(${dropEvent.offsetX}px, ${dropEvent.offsetY}px, 0px)`;

    domElem.setAttribute('data-control', JSON.stringify(control));

    domElem.classList.add('flwmn-control');

    // 3. Append DOM element to the body
    dropEvent.target.parentNode.appendChild(domElem);

    // if (editorMode === EditorModeEnum.Internal) {
    //   if (isInternalDraftControl === false) {
    //     dropEvent.target.parentNode.appendChild(domElem);
    //   } else if (isInternalDraftControl === true) {
    //     /**
    //      * //TODO Tobi, i don't think this is neccessary anymore
    //      * dropEvent.target.parentNode.appendChild(domElem);
    //      * seems sufficient to handle this. It's still tentative
    //      */

    //     const documentPageContainers = document.querySelectorAll(
    //       '.document-page-container'
    //     );

    //     documentPageContainers.forEach((page) => {
    //       if (page.getAttribute('data-page-number') === control.pageNumber) {
    //         componentRef.instance.dropEvent = { target: { parentNode: page } };
    //         page.appendChild(domElem);
    //       }
    //     });
    //   }
    // } else if (editorMode === EditorModeEnum.External) {
    //   dropEvent.target.parentNode.appendChild(domElem);
    //   // //TODO Fix this
    //   // const documentPageContainers = document.querySelectorAll(
    //   //   '.document-page-container'
    //   // );

    //   // documentPageContainers.forEach((page) => {
    //   //   if (page.getAttribute('data-page-number') === control.pageNumber) {
    //   //     componentRef.instance.dropEvent = { target: { parentNode: page } };
    //   //     page.appendChild(domElem);
    //   //   }
    //   // });
    // }

    // 5. Wait some time and remove it from the component tree and from the DOM
    // setTimeout(() => {
    //     this.appRef.detachView(componentRef.hostView);
    //     componentRef.destroy();
    // }, 3000);

    return componentRef;
  }

  async onReplaceDocument({ event, documentReplaceInputElement }: any) {
    const files = (event.target as HTMLInputElement).files!;

    if (files) {
      // if (validateFileType(files, ['application/pdf'])) {
      const documentsAreEncryptedPromises = Array.from(files).map(
        (file: any) => {
          // Return a promise per file
          return new Promise((resolve, reject) => {
            const reader = new FileReader();

            this.helperService.checkIfDocumentIsEncrypted(
              reader,
              file,
              resolve,
              reject
            );
          });
        }
      );

      // Wait for all promises to be resolved
      const documentsAreEncryptedResult: any = await Promise.all(
        documentsAreEncryptedPromises
      );

      if (
        documentsAreEncryptedResult.every(
          (res: any) => res.isDocumentEncrypted === false
        )
      ) {
        const continueReplaceDocumentPromises = documentsAreEncryptedResult.map(
          (item: any) => {
            // Return a promise per item
            return new Promise((resolve, reject) => {
              this.continueReplaceDocument(
                item.reader,
                item.file,
                resolve,
                reject
              );
            });
          }
        );

        // Wait for all promises to be resolved
        const continueReplaceDocumentResult: {
          file: File;
          base64String: string;
        }[] = await Promise.all(continueReplaceDocumentPromises);

        return continueReplaceDocumentResult;
        // this.replaceDocument(existingDocument, continueReplaceDocumentResult);
      } else {
        documentReplaceInputElement.nativeElement.value = null;

        const notification: Notification = {
          state: 'warning',
          message: 'Encrypted file: Please select an unencrypted file',
        };

        this.notificationService.openNotification(
          notification,
          'flwmn-notification-warning'
        );
      }
      // } else {
      //   documentReplaceInputElement.nativeElement.value = null;

      //   const notification: Notification = {
      //     state: 'warning',
      //     message: 'Unsupported file format: Please select a PDF file',
      //   };

      //   this.notificationService.openSnackBar(
      //     notification,
      //     'flwmn-notification-warning'
      //   );
      // }
    } else {
      documentReplaceInputElement.nativeElement.value = null;

      //  message: 'Max file size is ...MB'
    }
  }

  continueReplaceDocument(
    reader: FileReader,
    file: any,
    resolve: any,
    reject: any
  ) {
    reader.onload = async () => {
      try {
        const fullBase64String = reader.result!.toString();
        const base64String = fullBase64String.split(',');
        base64String;

        resolve({ file: file, base64String: base64String[1] });
      } catch (error: any) {
        const notification: Notification = {
          state: 'error',
          message: `${error.name}: ${error.message}`,
        };

        this.notificationService.openNotification(
          notification,
          'flwmn-notification-error'
        );

        reject(error);
      }
    };

    reader.readAsDataURL(file);
  }

  getControlsActualCoordinates(
    controlData: any,
    relativePage: any,
    documentIndex: number,
    allDocuments: any[]
  ): {
    width: string;
    height: string;
    xPosition: string;
    yPosition: string;
    // transform: string;
  } {
    const originalPage = allDocuments[documentIndex].pages.find((page: any) => {
      return page.pageNumber === Number(controlData.pageNumber);
    });

    const width =
      (this.pixelToNumber(originalPage!.width) *
        this.pixelToNumber(controlData.coordinate.width)) /
      this.pixelToNumber(relativePage.width);

    const height =
      (this.pixelToNumber(originalPage!.height) *
        this.pixelToNumber(controlData.coordinate.height)) /
      this.pixelToNumber(relativePage.height);

    const translate3dCoordinates = this.getTransformCoordinates(
      controlData.coordinate.transform
    );

    const x =
      (this.pixelToNumber(originalPage!.width) * translate3dCoordinates.x) /
      this.pixelToNumber(relativePage.width);

    const y =
      (this.pixelToNumber(originalPage!.height) * translate3dCoordinates.y) /
      this.pixelToNumber(relativePage.height);

    // const transform = `translate3d(${x}px, ${y}px, 0px)`;

    return {
      width: `${width}px`,
      height: `${height}px`,
      xPosition: `${x}px`,
      yPosition: `${y}px`,
      // transform: `${transform}`,
    };
  }

  pixelToNumber(value: string): number {
    return parseFloat(value.replaceAll('"', '').replaceAll('px', ''));
  }

  getTransformCoordinates(transform: string) {
    const transform3dCoordinates = transform
      .split('translate3d')[1]
      .replace('(', '')
      .replace(')', '')
      .split(',');

    const x = parseFloat(transform3dCoordinates[0].split('px')[0]);
    const y = parseFloat(transform3dCoordinates[1].split('px')[0]);
    const z = parseFloat(transform3dCoordinates[2].split('px')[0]);

    return { x, y, z };
  }

  /**
   * This function generates a controls clientCoordinates from
   * @param actualControlCoordinates which are the controls coordinates relative to the original page dimensions
   * and @param actualPageDimensions which are the original page dimensions
   * and @param clientPageDimensions which are the client page dimensions
   */
  generateControlClientCoordinates(
    actualControlCoordinates: {
      position: string;
      xPosition: string;
      yPosition: string;
      width: string;
      height: string;
    },
    actualPageDimensions: {
      width: string;
      height: string;
    },
    clientPageDimensions: {
      width: string;
      height: string;
    }
  ): {
    position: string;
    xPosition: string;
    yPosition: string;
    width: string;
    height: string;
  } {
    const xPosition: number =
      (this.pixelToNumber(clientPageDimensions.width) *
        this.pixelToNumber(actualControlCoordinates.xPosition)) /
      this.pixelToNumber(actualPageDimensions.width);

    const yPosition: number =
      (this.pixelToNumber(clientPageDimensions.height) *
        this.pixelToNumber(actualControlCoordinates.yPosition)) /
      this.pixelToNumber(actualPageDimensions.height);

    const width: number =
      (this.pixelToNumber(clientPageDimensions.width) *
        this.pixelToNumber(actualControlCoordinates.width)) /
      this.pixelToNumber(actualPageDimensions.width);

    const height: number =
      (this.pixelToNumber(clientPageDimensions.height) *
        this.pixelToNumber(actualControlCoordinates.height)) /
      this.pixelToNumber(actualPageDimensions.height);

    const clientCoordinates: {
      position: string;
      xPosition: string;
      yPosition: string;
      width: string;
      height: string;
    } = {
      position: actualControlCoordinates.position,
      xPosition: `${xPosition}px`,
      yPosition: `${yPosition}px`,
      width: `${width}px`,
      height: `${height}px`,
    };

    return clientCoordinates;
  }

  // get doSignersExistForSignWithOthers() {
  //   // const flowmonoControls = document.querySelector('.flwmn-control');

  //   // return flowmonoControls ? true : false;
  // }

  get doFlowmonoControlsExistOnDocument() {
    const flowmonoControls = document.querySelector('.flwmn-control');

    return flowmonoControls ? true : false;
  }

  get doAllFlowmonoControlsHaveActors() {
    const flowmonoControls = document.querySelectorAll('.flwmn-control');

    return Array.from(flowmonoControls).every((control) => {
      return (
        JSON.parse(control.getAttribute('data-control')!).recipientId !== 0
      );
    });
  }

  get doAllActiveFlowmonoControlsHaveValue() {
    const flowmonoControls = document.querySelectorAll('.flwmn-control');
    const activeFlowmonoControls: any[] = [];

    Array.from(flowmonoControls).forEach((control) => {
      if (
        JSON.parse(control.getAttribute('data-control')!).disabled === false
      ) {
        activeFlowmonoControls.push(control);
      }
    });

    return activeFlowmonoControls.every((control) => {
      return (
        JSON.parse(control.getAttribute('data-control')!).value !== null &&
        JSON.parse(control.getAttribute('data-control')!).value !== '' &&
        JSON.parse(control.getAttribute('data-control')!).value !== ' '
      );
    });
  }

  doAllFlowmonoControlsHaveAnyOfTheseActors(actorIds: number[]) {
    const tempObj: any = {};

    actorIds.forEach((actorId) => {
      const flowmonoControls = document.querySelectorAll('.flwmn-control');
      const tempControls: any = [];

      Array.from(flowmonoControls).forEach((control) => {
        if (
          JSON.parse(control.getAttribute('data-control')!).recipientId ===
          actorId
        ) {
          tempControls.push(control);
        }

        tempObj[actorId] = tempControls;
      });
    });

    const booleanResponse: boolean[] = [];

    for (const i in tempObj) {
      booleanResponse.push(tempObj[i].length > 0);
    }

    return booleanResponse.every((res) => res === true);
  }

  selfSignAndDownloadDocuments(
    instance: SignDocumentInstance,
    selectedDocument: any,
    selectedDocumentIndex: number,
    allDocuments: any[],
    signingRequestId: number,
    subscriberId: number,
    email: string,
    userId: string,
    bearerToken?: string
  ) {
    let allDocumentsPayload: any[] = [];

    if (instance === 'selectedDocument') {
      const response: any = this.getAllDocumentAndControls(
        selectedDocument,
        selectedDocumentIndex,
        allDocuments
      );

      allDocumentsPayload = [
        {
          documentId: response?.document?.id,
          controls: response?.controls,
        },
      ];
    } else if (instance === 'allDocuments') {
      const response: any = [];

      allDocuments.forEach((documentEl, documentIndex) => {
        response.push(
          this.getAllDocumentAndControls(
            documentEl,
            documentIndex,
            allDocuments
          )
        );
      });

      response?.forEach((resEl: any) => {
        if (resEl?.length !== 0) {
          allDocumentsPayload.push({
            documentId: resEl?.document?.id,
            controls: [...resEl.controls],
          });
        }
      });
    }

    this.selfSignDocuments(
      allDocumentsPayload,
      signingRequestId,
      subscriberId,
      email,
      userId,
      bearerToken ? bearerToken : undefined
    );
  }

  getAllDocumentAndControls(
    documentEl: any,
    documentIndex: number,
    allDocuments: any[],
    includeDisabledControls: boolean = false
  ) {
    let flowmonoControls: any = document.querySelectorAll(
      `[data-documentid='${documentEl.id}'] .flwmn-control`
    );

    if (includeDisabledControls === false) {
      flowmonoControls = Array.from(flowmonoControls)
        .map((controlEl) => {
          if (controlEl) {
            const controlData = JSON.parse(
              (controlEl as HTMLElement).getAttribute('data-control')!
            );

            if (controlData.disabled === false) {
              return controlEl;
            }
          }
        })
        .filter((controlEl) => controlEl !== undefined);
    }

    const flowmonoControlsData: any[] = [];

    flowmonoControls.forEach((element: any) => {
      flowmonoControlsData.push(
        JSON.parse(element.getAttribute('data-control')!)
      );
    });

    const clientPageDimensions: {
      pageNumber: number;
      width: string;
      height: string;
    }[] = [];

    document
      .querySelectorAll(`[data-documentid='${documentEl.id}'] .flwmn-page`)
      ?.forEach((flowmonoPage: any) => {
        clientPageDimensions.push({
          pageNumber: parseInt(flowmonoPage.getAttribute('data-page-number')!),
          width: `${flowmonoPage.getBoundingClientRect().width}px`,
          height: `${flowmonoPage.getBoundingClientRect().height}px`,
        });
      });

    let resultFlowmonoControlsData: any;

    if (flowmonoControlsData) {
      resultFlowmonoControlsData = flowmonoControlsData.map((controlData) => {
        const clientPage = clientPageDimensions.find((page) => {
          return page.pageNumber === Number(controlData.pageNumber);
        });

        const actualCoordinate = this.getControlsActualCoordinates(
          controlData,
          clientPage,
          documentIndex,
          allDocuments
        );

        const clientCoordinatesTranslate3dCoordinates =
          this.getTransformCoordinates(controlData.coordinate.transform);

        const modifiedControl = {
          ...controlData,
          additionalStyles: controlData.additionalStyles
            ? {
                fontFamily: controlData.additionalStyles['font-family'],
                fontSize: controlData.additionalStyles['font-size'],
                fontWeight: controlData.additionalStyles['font-weight'],
                color: controlData.additionalStyles['color'],
                textAlign: controlData.additionalStyles['text-align'],
              }
            : null,
          actualCoordinate: {
            position: controlData.coordinate.position,
            width: actualCoordinate.width,
            height: actualCoordinate.height,
            xPosition: actualCoordinate.xPosition,
            yPosition: actualCoordinate.yPosition,
          },
          clientCoordinate: {
            position: controlData.coordinate.position,
            width: controlData.coordinate.width,
            height: controlData.coordinate.height,
            xPosition: `${clientCoordinatesTranslate3dCoordinates.x}px`,
            yPosition: `${clientCoordinatesTranslate3dCoordinates.y}px`,
          },
        };

        delete modifiedControl.coordinate;

        return modifiedControl;
      });

      return { document: documentEl, controls: resultFlowmonoControlsData };
    }
  }

  selfSignDocuments(
    allDocumentsPayload: any,
    signingRequestId: number,
    subscriberId: number,
    email: string,
    userId: string,
    bearerToken?: string
  ) {
    this.store.dispatch(EditorActions.IsLoading({ payload: true }));

    this.store.dispatch(
      EditorActions.SelfSignDocuments({
        payload: {
          signingRequestId: signingRequestId,
          subscriberId: subscriberId,
          email: email,
          allDocuments: allDocumentsPayload,
          userId: userId,
        },
        additionalPayload: bearerToken
          ? {
              bearerToken: bearerToken,
            }
          : undefined,
      })
    );
  }

  downloadDocuments(
    instance: DocumentDownloadInstance,
    selectedDocument: any,
    allDocuments: any[],
    signingRequestId: number,
    subscriberId: number,
    userId: string,
    bearerToken?: string
  ) {
    const allDocumentIds: number[] = [];

    if (instance === 'selectedDocument') {
      allDocumentIds.push(selectedDocument?.id);
    } else if (instance === 'allDocuments') {
      allDocuments.forEach((document: any) => {
        allDocumentIds.push(document?.id);
      });
    }

    this.store.dispatch(EditorActions.IsLoading({ payload: true }));

    this.store.dispatch(
      EditorActions.DownloadDocuments({
        payload: {
          signingRequestId: signingRequestId,
          documentIds: allDocumentIds,
          subscriberId: subscriberId,
          userId: userId,
        },
        additionalPayload: bearerToken
          ? {
              bearerToken: bearerToken,
            }
          : undefined,
      })
    );
  }

  shareSelfSignedDocuments({
    instance,
    selectedDocument,
    selectedDocumentIndex,
    allDocuments,
    signingRequestId,
    subscriberId,
    userId,
    bearerToken,
    recipientEmail,
  }: {
    instance: SendDocumentsToRecipientsInstance;
    selectedDocument: any;
    selectedDocumentIndex: number;
    allDocuments: any[];
    signingRequestId: number;
    subscriberId: number;
    userId: string;
    bearerToken?: string;
    recipientEmail: string;
  }) {
    // const allDocumentIds: number[] = [];

    // if (instance === 'selectedDocument') {
    //   allDocumentIds.push(selectedDocument?.id);
    // } else if (instance === 'allDocuments') {
    //   allDocuments.forEach((document: any) => {
    //     allDocumentIds.push(document?.id);
    //   });
    // }

    let allDocumentsPayload: any[] = [];

    if (instance === 'selectedDocument') {
      const response: any = this.getAllDocumentAndControls(
        selectedDocument,
        selectedDocumentIndex,
        allDocuments
      );

      allDocumentsPayload = [
        {
          documentId: response?.document?.id,
          controls: response?.controls,
        },
      ];
    } else if (instance === 'allDocuments') {
      const response: any = [];

      allDocuments.forEach((documentEl, documentIndex) => {
        response.push(
          this.getAllDocumentAndControls(
            documentEl,
            documentIndex,
            allDocuments
          )
        );
      });

      response?.forEach((resEl: any) => {
        if (resEl?.length !== 0) {
          allDocumentsPayload.push({
            documentId: resEl?.document?.id,
            controls: [...resEl.controls],
          });
        }
      });
    }

    this.store.dispatch(EditorActions.IsLoading({ payload: true }));

    // const response: any = [];

    // allDocuments.forEach((documentEl, documentIndex) => {
    //   response.push(
    //     this.getAllDocumentAndControls(documentEl, documentIndex, allDocuments)
    //   );
    // });

    // response?.forEach((resEl: any) => {
    //   if (resEl?.length !== 0) {
    //     allDocumentsPayload.push({
    //       documentId: resEl?.document?.id,
    //       controls: [...resEl.controls],
    //     });
    //   }
    // });

    this.store.dispatch(
      EditorActions.ShareSelfSignedDocuments({
        payload: {
          signingRequestId: signingRequestId,
          controlRequests: allDocumentsPayload,
          recipientEmail: recipientEmail,
          allowRecipientsAddControls: false,
          signType: SignTypeEnum.SelfSign,
        },
        // payload: {
        //   signingRequestId: signingRequestId,
        //   documentsAndControls: allDocumentsPayload,
        //   // documentIds: allDocumentIds,
        //   subscriberId: subscriberId,
        //   userId: userId,
        // },
        // additionalPayload: bearerToken
        //   ? {
        //       bearerToken: bearerToken,
        //     }
        //   : undefined,
      })
    );
  }

  // getAllClientDocumentDimensions(allDocuments: any[]) {
  //   if (allDocuments && allDocuments?.length !== 0) {
  //     const clientDocumentDimensions: {
  //       documentId: number;
  //       clientDocumentPageDimensions: {
  //         pageNumber: number;
  //         pageWidth: string;
  //         pageHeight: string;
  //       }[];
  //     }[] = [];

  //     allDocuments?.forEach((documentEl) => {
  //       const tempClientDocumentPageDimensions: {
  //         pageNumber: number;
  //         pageWidth: string;
  //         pageHeight: string;
  //       }[] = [];

  //       document
  //         .querySelectorAll(`[data-documentid='${documentEl.id}'] .flwmn-page`)
  //         ?.forEach((flowmonoPage: any) => {
  //           tempClientDocumentPageDimensions.push({
  //             pageNumber: parseInt(
  //               flowmonoPage.getAttribute('data-page-number')!
  //             ),
  //             pageWidth: `${flowmonoPage.getBoundingClientRect().width}px`,
  //             pageHeight: `${flowmonoPage.getBoundingClientRect().height}px`,
  //           });
  //         });

  //       clientDocumentDimensions.push({
  //         documentId: documentEl.id,
  //         clientDocumentPageDimensions: tempClientDocumentPageDimensions,
  //       });
  //     });

  //   }
  // }

  // getClientDocumentDimension(documentEl: any) {
  //   if (documentEl) {
  //     const tempClientDocumentPageDimensions: {
  //       pageNumber: number;
  //       pageWidth: string;
  //       pageHeight: string;
  //     }[] = [];

  //     document
  //       .querySelectorAll(`[data-documentid='${documentEl.id}'] .flwmn-page`)
  //       ?.forEach((flowmonoPage: any) => {
  //         tempClientDocumentPageDimensions.push({
  //           pageNumber: parseInt(
  //             flowmonoPage.getAttribute('data-page-number')!
  //           ),
  //           pageWidth: `${flowmonoPage.getBoundingClientRect().width}px`,
  //           pageHeight: `${flowmonoPage.getBoundingClientRect().height}px`,
  //         });
  //       });

  //     const clientDocumentDimension: {
  //       documentId: number;
  //       clientDocumentPageDimensions: {
  //         pageNumber: number;
  //         pageWidth: string;
  //         pageHeight: string;
  //       }[];
  //     } = {
  //       documentId: documentEl.id,
  //       clientDocumentPageDimensions: tempClientDocumentPageDimensions,
  //     };

  //     return clientDocumentDimension;
  //   }
  // }

  saveAsDraft(
    allDocuments: any[],
    signingRequestId: number,
    subscriberId: number,
    userId: string,
    email: string,
    bearerToken?: string
  ) {
    const allDocumentsPayload: any[] = [];
    const response: any = [];

    allDocuments.forEach((documentEl, documentIndex) => {
      response.push(
        this.getAllDocumentAndControls(
          documentEl,
          documentIndex,
          allDocuments,
          false
        )
      );
    });

    response?.forEach((resEl: any) => {
      if (resEl?.length !== 0) {
        allDocumentsPayload.push({
          documentId: resEl?.document?.id,
          controls: [...resEl.controls],
        });
      }
    });

    this.store.dispatch(EditorActions.IsLoading({ payload: true }));

    this.store.dispatch(
      EditorActions.SaveAsDraft({
        payload: {
          subscriberId: subscriberId,
          signingRequestId: signingRequestId,
          email: email,
          documentControlsRequest: allDocumentsPayload,
          userId: userId,
        },
        additionalPayload: bearerToken
          ? {
              bearerToken: bearerToken,
            }
          : undefined,
      })
    );
  }

  sendToSignatories(
    allDocuments: any[],
    signingRequestId: number,
    subscriberId: number,
    userId: string,
    bearerToken?: string
  ) {
    const allDocumentsPayload: any[] = [];
    const response: any = [];

    allDocuments.forEach((documentEl, documentIndex) => {
      response.push(
        this.getAllDocumentAndControls(documentEl, documentIndex, allDocuments)
      );
    });

    response?.forEach((resEl: any) => {
      if (resEl?.length !== 0) {
        allDocumentsPayload.push({
          documentId: resEl?.document?.id,
          controls: [...resEl.controls],
        });
      }
    });

    this.store.dispatch(EditorActions.IsLoading({ payload: true }));

    this.store.dispatch(
      EditorActions.SendToSignatories({
        payload: {
          signingRequestId: signingRequestId,
          allDocuments: allDocumentsPayload,
          subscriberId: subscriberId,
          userId: userId,
        },
        additionalPayload: bearerToken
          ? {
              bearerToken: bearerToken,
            }
          : undefined,
      })
    );
  }

  onAcceptAndSignDocuments(
    allDocuments: any[],
    signingRequestId: number,
    recipientEmail: string,
    allowRecipientsAddControls: boolean = false,
    ipAddress: string,
    location: string
  ) {
    const allDocumentsPayload: any[] = [];
    const response: any = [];

    allDocuments.forEach((documentEl, documentIndex) => {
      response.push(
        this.getAllDocumentAndControls(documentEl, documentIndex, allDocuments)
      );
    });

    response?.forEach((resEl: any) => {
      if (resEl?.length !== 0) {
        let controls: any[] = [...resEl.controls];

        if (allowRecipientsAddControls === true) {
          controls = controls.map((control) => {
            if (control.recipientEmail === null) {
              control.recipientEmail = recipientEmail;
            }

            return control;
          });
        }

        allDocumentsPayload.push({
          documentId: resEl?.document?.id,
          controls: controls,
        });
      }
    });

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.acceptAndSignDocuments.bind(this, {
        controlRequestsPayload: allDocumentsPayload,
        signingRequestId: signingRequestId,
        recipientEmail: recipientEmail,
        allowRecipientsAddControls: allowRecipientsAddControls,
        ipAddress: ipAddress,
        location: location,
      })
    );
  }

  acceptAndSignDocuments({
    controlRequestsPayload,
    signingRequestId,
    recipientEmail,
    allowRecipientsAddControls,
    ipAddress,
    location,
  }: {
    controlRequestsPayload: any[];
    signingRequestId: number;
    recipientEmail: string;
    allowRecipientsAddControls: boolean;
    ipAddress: string;
    location: string;
  }) {
    this.store.dispatch(EditorActions.IsLoading({ payload: true }));

    this.store.dispatch(
      EditorActions.SignAndAccept({
        payload: {
          signingRequestId: signingRequestId,
          controlRequests: controlRequestsPayload,
          recipientEmail: recipientEmail,
          allowRecipientsAddControls: allowRecipientsAddControls,
          signType: SignTypeEnum.SignWithOthers,
          ipAddress: ipAddress,
          location: location,
        },
      })
    );
  }

  onChangeSignType(type: boolean) {
    this.changeSignType.next(type);
  }
}
