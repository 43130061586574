import { createAction, props } from '@ngrx/store';

export const ResetStore = createAction(
  '[Signing Request Category ] Reset Store'
);

export const IsLoading = createAction(
  '[Signing Request Category] Is loading',
  props<{
    payload: boolean;
  }>()
);

export const CreateSigningRequestCategory = createAction(
  '[Signing Request Category] Create Signing Request Category',
  props<{
    payload: {
      name: string;
      addSLAToThisCategory: true;
    };
  }>()
);

export const UpdateSigningRequestCategory = createAction(
  '[Signing Request Category] Update Signing Request Category',
  props<{
    payload: {
      categoryId: number;
      name: string;
      addSLAToThisCategory: true;
    };
  }>()
);

export const DeleteSigningRequestCategory = createAction(
  '[Signing  Request Category] Delete Signing Request Category',
  props<{
    payload: {
      categoryId: number;
    };
  }>()
);

export const GetAllSigningRequestCategory = createAction(
  '[Signing Request Category]  Get All Signing Request Category',
  props<{
    payload: {
      skip: number;
      take: number;
      searchValue?: string | null;
    };
  }>()
);

export const SaveSigningRequestCategory = createAction(
  '[Signing Request Category] Save Signing Request Category',
  props<{
    payload: any;
  }>()
);

export const GetSigningRequestCategoryAuditList = createAction(
  '[Signing Request Audit]  Get Signing Request Category Audit List',
  props<{
    payload: {
      skip: number;
      take: number;
      categoryId?: number | null;
      defaultCategory: 'All' | 'General' | null;
      searchValue?: string | null;
      filterValue?: string[] | [];
    };
  }>()
);

export const SaveSigningRequestCategoryAuditList = createAction(
  '[Signing Request Audit]  Save Signing Request Category Audit List',
  props<{
    payload: any;
  }>()
);

export const GetSigningRequestAuditListDetailById = createAction(
  '[Signing Request Audit] Get Signing Request Audit By Id',
  props<{
    payload: {
      id: number;
    };
  }>()
);

export const SaveSigningRequestAuditListDetailById = createAction(
  '[Signing Request Audit] Save Signing Request Audit By Id',
  props<{
    payload: any;
  }>()
);
