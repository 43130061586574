<ng-container
  *ngIf="
    (isAuthLoading | async) !== false ||
    (isSubscriptionLoading | async) !== false
  "
>
  <div class="general-loader-container">
    <div class="general-loader-backdrop"></div>

    <div class="general-loader-content">
      <app-general-loader></app-general-loader>
    </div>
  </div>
</ng-container>

<section id="register">
  <div class="wallpaper-container">
    <div class="wallpaper-illustrations-container">
      <img
        src="/assets/images/auth/auth-wallpaper-illustration-left.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-left"
      />

      <img
        src="/assets/images/auth/auth-wallpaper-illustration-right.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-right"
      />
    </div>
  </div>

  <div class="page">
    <div class="page-inner">
      <div class="body">
        <div class="logo-container">
          <a routerLink="/" *ngIf="!isFromDeveloperWebsite">
            <img
              src="/assets/logo/flowmono-logo-white.svg"
              alt="Flowmono Logo White"
              class="logo"
            />
          </a>

          <a routerLink="/developer" *ngIf="isFromDeveloperWebsite">
            <img
              src="/assets/logo/flowmono-logo-white.svg"
              alt="Flowmono Logo White"
              class="logo"
            />
          </a>
        </div>

        <div class="content-section">
          <div class="form-section">
            <h5 class="flwmn-heading-5 fw-700 header-text">
              <ng-container *ngIf="page === 1">
                Let's get you started!
              </ng-container>

              <ng-container *ngIf="page === 1.5">
                Company's domain name already exists
              </ng-container>

              <ng-container *ngIf="page === 2">
                You are almost done!
              </ng-container>
            </h5>

            <!-- <div
              [hidden]="page !== 1 || isFromDeveloperWebsite"
              class="routing-pill-container"
            >
              <span
                matRipple
                class="route-pill"
                data-registration-type="individual"
                (click)="navigateTo($event)"
              >
                <p class="flwmn-para-1 route-text">Individual</p>
              </span>

              <span
                matRipple
                class="route-pill"
                data-registration-type="corporate"
                (click)="navigateTo($event)"
              >
                <p class="flwmn-para-1 route-text">Corporate</p>
              </span>

              <span class="glider"></span>
            </div> -->

            <form [formGroup]="registrationForm" (submit)="onSubmit()">
              <div [hidden]="page !== 1" class="registration-form-page-1">
                <div class="registration-form-row">
                  <div class="input-style1 input-md">
                    <label class="input-label">First name</label>

                    <mat-form-field appearance="outline" [style.width.%]="100">
                      <input type="text" matInput formControlName="firstname" />

                      <mat-error
                        *ngIf="registrationFormControls.firstname.invalid"
                      >
                        <img
                          src="/assets/icons/error.svg"
                          alt=""
                          class="img-fluid error-icon"
                        />
                        {{ getErrorMessage('firstname') }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="input-style1 input-md">
                    <label class="input-label">Last name</label>

                    <mat-form-field appearance="outline" [style.width.%]="100">
                      <input type="text" matInput formControlName="lastname" />

                      <mat-error
                        *ngIf="registrationFormControls.lastname.invalid"
                      >
                        <img
                          src="/assets/icons/error.svg"
                          alt=""
                          class="img-fluid error-icon"
                        />
                        {{ getErrorMessage('lastname') }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="input-style1 input-md">
                  <label class="input-label">Email Address</label>

                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <input
                      #email
                      type="email"
                      inputmode="email"
                      matInput
                      formControlName="email"
                      (input)="
                        registrationForm.patchValue({
                          email: email.value.replaceAll(' ', '')
                        })
                      "
                    />

                    <mat-error *ngIf="registrationFormControls.email.invalid">
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('email') }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <ng-template #visibleEyeIcon>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3866 8.00001C10.3866 9.32001 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C9.31995 5.61334 10.3866 6.68001 10.3866 8.00001Z"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </ng-template>

                <ng-template #hiddenEyeIcon>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.68661 6.31334L6.31328 9.68668C5.87995 9.25334 5.61328 8.66001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C8.65995 5.61334 9.25328 5.88001 9.68661 6.31334Z"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.8799 3.84669C10.7132 2.96669 9.3799 2.48669 7.9999 2.48669C5.64656 2.48669 3.45323 3.87336 1.92656 6.27336C1.32656 7.21336 1.32656 8.79336 1.92656 9.73336C2.45323 10.56 3.06656 11.2734 3.73323 11.8467"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.61328 13.02C6.37328 13.34 7.17995 13.5133 7.99995 13.5133C10.3533 13.5133 12.5466 12.1267 14.0733 9.72668C14.6733 8.78668 14.6733 7.20668 14.0733 6.26668C13.8533 5.92002 13.6133 5.59335 13.3666 5.28668"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.34 8.46667C10.1666 9.40668 9.39996 10.1733 8.45996 10.3467"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.31325 9.68665L1.33325 14.6666"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.6668 1.33331L9.68677 6.31331"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </ng-template>

                <div class="input-style1 input-md">
                  <label class="input-label">Password</label>

                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <input
                      matInput
                      [type]="show ? 'text' : 'password'"
                      formControlName="password"
                    />

                    <span matSuffix class="img-fluid" (click)="show = !show">
                      <ng-container *ngIf="show">
                        <ng-container
                          *ngTemplateOutlet="visibleEyeIcon"
                        ></ng-container>
                      </ng-container>

                      <ng-container *ngIf="!show">
                        <ng-container
                          *ngTemplateOutlet="hiddenEyeIcon"
                        ></ng-container>
                      </ng-container>
                    </span>

                    <mat-error
                      *ngIf="registrationFormControls.password.invalid"
                    >
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('password') }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="input-style1 input-md">
                  <label class="input-label">Contact number</label>

                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <input
                      #individualPhoneNumber
                      type="tel"
                      inputmode="tel"
                      matInput
                      formControlName="phone_number"
                      (input)="
                        registrationForm.patchValue({
                          phone_number: individualPhoneNumber.value.replaceAll(
                            ' ',
                            ''
                          )
                        })
                      "
                    />
                    <mat-error
                      *ngIf="registrationFormControls.phone_number.invalid"
                    >
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('phone_number') }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div [hidden]="page !== 1" class="">
                <button
                  matRipple
                  type="button"
                  class="btn flwmn-btn-primary flwmn-btn-md continue-btn"
                  (click)="onClickContinue()"
                  [disabled]="validateContinueBtn()"
                >
                  Continue
                </button>
              </div>

              <div
                [hidden]="page !== 1 || registrationType === 'corporate'"
                class="third-party-container"
              >
                <p class="flwmn-caption fw-600 mb-0 alternate-register-text">
                  Or sign up with:
                </p>

                <div class="third-party-options">
                  <div
                    class="third-party-option"
                    matRipple
                    (click)="onSignUpWithGoogle()"
                    matTooltip="Sign up with Google"
                  >
                    <img
                      src="assets/icons/auth/google-third-party-auth.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </div>

                  <div
                    class="third-party-option"
                    (click)="onSignUpWithLinkedIn()"
                    matRipple
                    matTooltip="Sign up with LinkedIn"
                  >
                    <img
                      src="assets/icons/auth/linkedin-third-party-auth.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </div>

                  <div
                    class="third-party-option"
                    (click)="onSignUpWithMicrosoft()"
                    matRipple
                    matTooltip="Sign up with Microsoft"
                  >
                    <img
                      src="assets/icons/auth/azure-ad-third-party-auth.svg"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <p [hidden]="page !== 1" class="flwmn-para-1 bottom-text">
                You can <a routerLink="/login">Sign In</a> from here if you are
                a returning user
              </p>

              <div [hidden]="page !== 1.5" class="page-1_5">
                <div class="organization-profile-section">
                  <div class="organization-profile-picture-section">
                    <div class="organization-profile-picture-container">
                      <img
                        [src]="discoveredCompany?.logo"
                        alt="Organization Profile Picture"
                        class="img-fluid organization-profile"
                      />
                    </div>
                  </div>

                  <div class="organization-profile-name-container">
                    <h6 class="flwmn-heading-6 fw-700 mb-0">
                      {{ discoveredCompany?.name }}
                    </h6>

                    <p class="flwmn-para-1 mb-0 d-flex gap-2">
                      Team members:

                      <span class="d-flex">
                        <ng-container
                          *ngFor="let user of allUsers | slice: 0:6"
                        >
                          <div
                            class="user-initials-container"
                            [matTooltip]="user.firstName + ' ' + user.lastName"
                            randomColor
                            [backgroundColor]="true"
                          >
                            <ng-container
                              *ngIf="
                                user.profilePicture !== null;
                                else noProfilePicture
                              "
                            >
                              <img
                                class="img-fluid user-initials-img"
                                [src]="user?.profilePicture"
                                alt=""
                              />
                            </ng-container>

                            <ng-template #noProfilePicture>
                              <p class="user-initials-text">
                                {{
                                  user?.firstName + ' ' + user?.lastName
                                    | nameInitials
                                }}
                              </p>
                            </ng-template>
                          </div>
                        </ng-container>

                        <ng-container
                          *ngIf="allUsers && allUsers!.length - 6 > 0"
                        >
                          <div class="user-initials-container">
                            <p class="user-initials-text">
                              +{{ allUsers!.length - 6 }}
                            </p>
                          </div>
                        </ng-container>
                      </span>
                    </p>
                  </div>
                </div>

                <p class="flwmn-para-2 join-team-text">
                  Join the organization to collaborate with your team members
                </p>

                <!-- <div class="registration-form-page-1_5">
                  <div class="input-style1 input-md">
                    <label class="input-label">First name</label>

                    <mat-form-field appearance="outline" [style.width.%]="100">
                      <input type="text" matInput formControlName="firstname" />

                      <mat-error
                        *ngIf="registrationFormControls.firstname.invalid"
                      >
                        <img
                          src="/assets/icons/error.svg"
                          alt=""
                          class="img-fluid error-icon"
                        />
                        {{ getErrorMessage('firstname') }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="input-style1 input-md">
                    <label class="input-label">Last name</label>

                    <mat-form-field appearance="outline" [style.width.%]="100">
                      <input type="text" matInput formControlName="lastname" />

                      <mat-error
                        *ngIf="registrationFormControls.lastname.invalid"
                      >
                        <img
                          src="/assets/icons/error.svg"
                          alt=""
                          class="img-fluid error-icon"
                        />
                        {{ getErrorMessage('lastname') }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div> -->

                <button
                  matRipple
                  type="button"
                  class="btn flwmn-btn-primary flwmn-btn-md join-team-btn"
                  (click)="
                    initiateJoinExistingOrganization(discoveredCompany?.id)
                  "
                >
                  <!-- [disabled]="validateFirstNameAndLastNameForPage1_5()" -->
                  Join team
                </button>

                <p class="flwmn-para-1 dont-belong-text">
                  Don’t belong to this company?
                  <span (click)="goToPage(1)">Sign Up</span> with another email
                </p>
              </div>

              <div
                [hidden]="page !== 2"
                class="registration-form-page-2"
                (window:keypress)="keyPress($event)"
              >
                <div class="page2-navigation-section">
                  <div
                    matRipple
                    class="back-btn-container"
                    (click)="onClickPage2BackButton()"
                  >
                    <img
                      src="/assets/icons/auth/register/registration-back-btn.svg"
                      alt="Back button"
                      class="back-btn"
                    />
                  </div>

                  <div class="count-container">
                    <span class="current-count">
                      {{ currentScrollSnapSectionInView }}
                    </span>
                    <span class="total-count">
                      /{{ totalScrollSnapSections }}.
                    </span>
                  </div>
                </div>

                <div class="scroll-snap-sections">
                  <div class="scroll-snap-section">
                    <div class="bullet-container">
                      <div class="bullet-header-container">
                        <div class="bullet"></div>
                      </div>

                      <div class="bullet-body">&nbsp;</div>
                    </div>

                    <div class="input-style1 input-md">
                      <label class="input-label">
                        How did you hear about us?
                      </label>

                      <mat-form-field
                        appearance="outline"
                        [style.width.%]="100"
                      >
                        <mat-select
                          formControlName="referrer"
                          placeholder="Select a response"
                        >
                          <ng-container
                            *ngFor="
                              let referrerSource of referrerSources;
                              let i = index
                            "
                          >
                            <mat-option [value]="referrerSource.enum">
                              {{ referrerSource.source }}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                        <mat-error
                          *ngIf="registrationFormControls.referrer.invalid"
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          {{ getErrorMessage('referrer') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div
                    *ngIf="registrationType === 'corporate'"
                    class="scroll-snap-section"
                  >
                    <div class="bullet-container">
                      <div class="bullet-header-container">
                        <div class="bullet"></div>
                      </div>

                      <div class="bullet-body">&nbsp;</div>
                    </div>

                    <div class="input-style1 input-md">
                      <label class="input-label"> Company name </label>

                      <mat-form-field
                        appearance="outline"
                        [style.width.%]="100"
                      >
                        <input
                          type="text"
                          matInput
                          formControlName="company_name"
                        />
                        <mat-error
                          *ngIf="registrationFormControls.company_name.invalid"
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          {{ getErrorMessage('company_name') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div
                    *ngIf="registrationType === 'corporate'"
                    class="scroll-snap-section"
                  >
                    <div class="bullet-container">
                      <div class="bullet-header-container">
                        <div class="bullet"></div>
                      </div>

                      <div class="bullet-body">&nbsp;</div>
                    </div>

                    <div class="registration-form-row">
                      <div class="input-style1 input-md">
                        <label class="input-label"> Country </label>

                        <mat-form-field
                          appearance="outline"
                          [style.width.%]="100"
                        >
                          <input
                            #country
                            type="text"
                            matInput
                            formControlName="country"
                            [matAutocomplete]="forCountries"
                            (input)="onFilterCountry(country.value)"
                          />

                          <mat-autocomplete
                            autoActiveFirstOption
                            #forCountries="matAutocomplete"
                            (optionSelected)="onSelectCountry($event)"
                          >
                            <ng-container
                              *ngIf="
                                (allFilteredCountries | async) !== null;
                                else noCountries
                              "
                            >
                              <mat-option
                                *ngFor="
                                  let country of allFilteredCountries | async
                                "
                                [value]="country"
                              >
                                {{ country }}
                              </mat-option>
                            </ng-container>

                            <ng-template #noCountries>
                              <mat-option disabled
                                >Loading Countries...</mat-option
                              >
                            </ng-template>
                          </mat-autocomplete>

                          <mat-error
                            *ngIf="registrationFormControls.country.invalid"
                          >
                            <img
                              src="/assets/icons/error.svg"
                              alt=""
                              class="img-fluid error-icon"
                            />
                            {{ getErrorMessage('country') }}
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="input-style1 input-md">
                        <label class="input-label"> State </label>

                        <mat-form-field
                          appearance="outline"
                          [style.width.%]="100"
                        >
                          <input
                            #states
                            type="text"
                            matInput
                            formControlName="state"
                            [matAutocomplete]="forStates"
                            (input)="onFilterStates(states.value)"
                          />

                          <mat-autocomplete
                            autoActiveFirstOption
                            #forStates="matAutocomplete"
                          >
                            <ng-container
                              *ngIf="
                                (allFilteredStates | async) !== null;
                                else noStates
                              "
                            >
                              <mat-option
                                *ngFor="let state of allFilteredStates | async"
                                [value]="state.name"
                              >
                                {{ state.name }}
                              </mat-option>
                            </ng-container>

                            <ng-template #noStates>
                              <ng-container
                                *ngIf="
                                  registrationFormControls.country.value ===
                                  null
                                "
                              >
                                <mat-option disabled>Select Country</mat-option>
                              </ng-container>

                              <ng-container
                                *ngIf="
                                  registrationFormControls.country.value !==
                                  null
                                "
                              >
                                <mat-option disabled
                                  >Loading states...</mat-option
                                >
                              </ng-container>
                            </ng-template>
                          </mat-autocomplete>

                          <mat-error
                            *ngIf="registrationFormControls.state.invalid"
                          >
                            <img
                              src="/assets/icons/error.svg"
                              alt=""
                              class="img-fluid error-icon"
                            />
                            {{ getErrorMessage('state') }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div class="scroll-snap-section">
                    <div class="bullet-container">
                      <div class="bullet-header-container">
                        <div class="bullet"></div>
                      </div>

                      <div
                        *ngIf="registrationType === 'corporate'"
                        class="bullet-body"
                      >
                        &nbsp;
                      </div>
                    </div>

                    <div class="profile-theme-section">
                      <p class="input-label">Profile Image and theme</p>

                      <div class="profile-theme-container">
                        <div class="profile-picture-section">
                          <div
                            class="profile-picture-container"
                            matRipple
                            (click)="profilePictureUpload.click()"
                          >
                            <div
                              class="edit-container"
                              [ngClass]="{ 'd-none': profilePicturePreview }"
                            >
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_3510_124323)">
                                  <path
                                    d="M30.9354 7.33094C30.2966 6.66174 29.4145 6.2663 28.4106 6.2663H23.3612V6.20546C23.3612 5.44501 23.057 4.71497 22.5399 4.22828C22.0228 3.71116 21.3232 3.40698 20.5627 3.40698H11.4373C10.6464 3.40698 9.94677 3.71116 9.42966 4.22828C8.91255 4.74539 8.60837 5.44501 8.60837 6.20546V6.2663H3.58935C2.58555 6.2663 1.70342 6.66174 1.06464 7.33094C0.425856 7.96972 0 8.88227 0 9.85565V25.0039C0 26.0077 0.395437 26.8899 1.06464 27.5287C1.70342 28.1674 2.61597 28.5933 3.58935 28.5933H28.4106C29.4145 28.5933 30.2966 28.1979 30.9354 27.5287C31.5741 26.8899 32 25.9773 32 25.0039V9.85565C32 8.85185 31.6046 7.96972 30.9354 7.33094ZM30.4183 25.0039H30.3878C30.3878 25.5515 30.1749 26.0382 29.8099 26.4032C29.4449 26.7682 28.9582 26.9811 28.4106 26.9811H3.58935C3.04183 26.9811 2.55513 26.7682 2.19011 26.4032C1.8251 26.0382 1.61217 25.5515 1.61217 25.0039V9.85565C1.61217 9.30812 1.8251 8.82143 2.19011 8.45641C2.55513 8.09139 3.04183 7.87847 3.58935 7.87847H9.46008C9.91635 7.87847 10.2814 7.51345 10.2814 7.05717V6.17504C10.2814 5.84044 10.403 5.53626 10.616 5.32333C10.8289 5.1104 11.1331 4.98873 11.4677 4.98873H20.5627C20.8973 4.98873 21.2015 5.1104 21.4144 5.32333C21.6274 5.53626 21.7491 5.84044 21.7491 6.17504V7.05717C21.7491 7.51345 22.1141 7.87847 22.5703 7.87847H28.4411C28.9886 7.87847 29.4753 8.09139 29.8403 8.45641C30.2053 8.82143 30.4183 9.30812 30.4183 9.85565V25.0039Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M16.0005 9.94702C13.932 9.94702 12.0461 10.7987 10.7077 12.1371C9.33887 13.506 8.51758 15.3615 8.51758 17.4299C8.51758 19.4984 9.36929 21.3843 10.7077 22.7227C12.0765 24.0915 13.932 24.9128 16.0005 24.9128C18.0689 24.9128 19.9548 24.0611 21.2932 22.7227C22.6621 21.3539 23.4834 19.4984 23.4834 17.4299C23.4834 15.3615 22.6316 13.4755 21.2932 12.1371C19.9548 10.7987 18.0689 9.94702 16.0005 9.94702ZM20.1373 21.5972C19.0727 22.6314 17.6126 23.3006 16.0005 23.3006C14.3883 23.3006 12.9282 22.6314 11.8636 21.5972C10.7989 20.5326 10.1602 19.0725 10.1602 17.4603C10.1602 15.8482 10.8294 14.3881 11.8636 13.3234C12.9282 12.2588 14.3883 11.62 16.0005 11.62C17.6126 11.62 19.0727 12.2892 20.1373 13.3234C21.202 14.3881 21.8408 15.8482 21.8408 17.4603C21.8712 19.0725 21.202 20.5326 20.1373 21.5972Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M26.8284 12.8975C27.6516 12.8975 28.3189 12.2302 28.3189 11.407C28.3189 10.5838 27.6516 9.9165 26.8284 9.9165C26.0052 9.9165 25.3379 10.5838 25.3379 11.407C25.3379 12.2302 26.0052 12.8975 26.8284 12.8975Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_3510_124323">
                                    <rect width="32" height="32" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>

                              <p class="flwmn-caption no-select">
                                Upload an image here
                              </p>
                            </div>

                            <img
                              [src]="profilePicturePreview"
                              alt=""
                              class="profile-picture img-fluid"
                            />
                          </div>

                          <input
                            #profilePictureUpload
                            type="file"
                            accept="image/*"
                            name=""
                            class="d-none"
                            (change)="onUploadProfilePicture($event)"
                          />
                        </div>

                        <div class="theme-container">
                          <div
                            matRipple
                            (click)="onSelectTheme($event)"
                            data-theme="theme1"
                            class="theme theme1"
                            [ngClass]="{
                              selected: selectedTheme === 'theme1'
                            }"
                          ></div>

                          <div
                            matRipple
                            (click)="onSelectTheme($event)"
                            data-theme="theme2"
                            class="theme theme2"
                            [ngClass]="{
                              selected: selectedTheme === 'theme2'
                            }"
                          ></div>

                          <div
                            matRipple
                            (click)="onSelectTheme($event)"
                            data-theme="theme3"
                            class="theme theme3"
                            [ngClass]="{
                              selected: selectedTheme === 'theme3'
                            }"
                          ></div>

                          <div
                            matRipple
                            (click)="onSelectTheme($event)"
                            data-theme="theme4"
                            class="theme theme4"
                            [ngClass]="{
                              selected: selectedTheme === 'theme4'
                            }"
                          ></div>

                          <div
                            matRipple
                            (click)="onSelectTheme($event)"
                            data-theme="theme5"
                            class="theme theme5"
                            [ngClass]="{
                              selected: selectedTheme === 'theme5'
                            }"
                          ></div>

                          <div
                            matRipple
                            (click)="onSelectTheme($event)"
                            data-theme="theme6"
                            class="theme theme6"
                            [ngClass]="{
                              selected: selectedTheme === 'theme6'
                            }"
                          ></div>

                          <div
                            matRipple
                            (click)="onSelectTheme($event)"
                            data-theme="theme7"
                            class="theme theme7"
                            [ngClass]="{
                              selected: selectedTheme === 'theme7'
                            }"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="registrationType === 'corporate'"
                    class="scroll-snap-section"
                  >
                    <div class="bullet-container">
                      <div class="bullet-header-container">
                        <div class="bullet"></div>
                      </div>

                      <!-- <div
                        *ngIf="registrationType === 'corporate'"
                        class="bullet-body"
                      >
                        &nbsp;
                      </div> -->
                    </div>

                    <div class="invitee-section">
                      <p class="input-label">Would you like to invite users?</p>

                      <div class="invitees-scroll-area">
                        <div
                          formArrayName="invitees"
                          *ngFor="
                            let invitee of inviteesFormArray.controls;
                            let i = index
                          "
                        >
                          <div [formGroupName]="i">
                            <div class="registration-form-row invitee-form">
                              <div class="input-style1">
                                <div class="input-label">Email</div>

                                <mat-form-field
                                  appearance="outline"
                                  [style.width.%]="100"
                                >
                                  <input
                                    #inviteeEmail
                                    type="email"
                                    inputmode="email"
                                    matInput
                                    formControlName="email"
                                    (input)="
                                      inviteesFormArray.controls[i].patchValue({
                                        email: inviteeEmail.value.replaceAll(
                                          ' ',
                                          ''
                                        )
                                      })
                                    "
                                  />

                                  <mat-error
                                    *ngIf="
                                      inviteesFormArray.controls[i].get(
                                        'email'
                                      )!.errors?.required
                                    "
                                  >
                                    <img
                                      src="/assets/icons/error.svg"
                                      alt=""
                                      class="img-fluid error-icon"
                                    />
                                    Please enter invitee's email
                                  </mat-error>

                                  <mat-error
                                    *ngIf="
                                      inviteesFormArray.controls[i].get(
                                        'email'
                                      )!.errors?.email
                                    "
                                  >
                                    <img
                                      src="/assets/icons/error.svg"
                                      alt=""
                                      class="img-fluid error-icon"
                                    />
                                    Sorry, this is not a valid email
                                  </mat-error>
                                </mat-form-field>
                              </div>

                              <div class="input-style1">
                                <div class="input-label">Role</div>

                                <mat-form-field
                                  appearance="outline"
                                  [style.width.%]="100"
                                >
                                  <mat-select formControlName="role">
                                    <mat-option [value]="1">Admin</mat-option>
                                    <mat-option [value]="2">Manager</mat-option>
                                    <mat-option [value]="3">User</mat-option>
                                  </mat-select>
                                  <mat-error
                                    *ngIf="
                                      inviteesFormArray.controls[i].get('role')!
                                        .errors?.required
                                    "
                                  >
                                    <img
                                      src="/assets/icons/error.svg"
                                      alt=""
                                      class="img-fluid error-icon"
                                    />
                                    Required
                                  </mat-error>
                                </mat-form-field>
                              </div>

                              <div class="">
                                <div
                                  matRipple
                                  class="remove-invitee-icon-container no-select"
                                  (click)="removeInvitee(i)"
                                >
                                  <svg
                                    class="remove-invitee-icon"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14 3.98665C11.78 3.76665 9.54667 3.65332 7.32 3.65332C6 3.65332 4.68 3.71999 3.36 3.85332L2 3.98665"
                                      stroke="#E1004B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M5.66699 3.3135L5.81366 2.44016C5.92033 1.80683 6.00033 1.3335 7.12699 1.3335H8.87366C10.0003 1.3335 10.087 1.8335 10.187 2.44683L10.3337 3.3135"
                                      stroke="#E1004B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M12.5669 6.09326L12.1336 12.8066C12.0603 13.8533 12.0003 14.6666 10.1403 14.6666H5.86026C4.00026 14.6666 3.94026 13.8533 3.86693 12.8066L3.43359 6.09326"
                                      stroke="#E1004B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M6.88672 11H9.10672"
                                      stroke="#E1004B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M6.33301 8.3335H9.66634"
                                      stroke="#E1004B"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          matRipple
                          (click)="addInvitee()"
                          class="add-invitee-container"
                        >
                          <svg
                            class="add-invitee-icon"
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.5 8H12.5"
                              stroke="var(--flwmn-primary-color)"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.5 12V4"
                              stroke="var(--flwmn-primary-color)"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <p class="flwmn-caption add-invitee-text">
                            Add invitee
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  matRipple
                  type="submit"
                  class="btn flwmn-btn-primary flwmn-btn-md signup-btn"
                  [disabled]="validateSignUpBtn()"
                >
                  Sign Up
                </button>
              </div>
            </form>
          </div>

          <div class="illustration-section">
            <img
              src="/assets/images/auth/auth-right-illustration-2.png"
              alt="Illustration Image"
              class="illustration"
            />
          </div>
        </div>
      </div>

      <footer>
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>
  </div>
</section>

<!-- <section id="register">
  <header class="container-fluid">
    <nav>
      <a routerLink="/" class="brand">
        <svg
          width="180"
          height="38"
          viewBox="0 0 180 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_7294_35825)">
            <path
              d="M18.9692 29.6058C16.0582 29.6058 13.4228 28.4257 11.5152 26.5184C9.60793 24.6112 8.42745 21.9754 8.42745 19.0644C8.42745 16.1542 9.60793 13.518 11.5152 11.6111C13.4224 9.70319 16.0582 8.52343 18.9692 8.52343C21.8794 8.52343 24.5156 9.70355 26.4224 11.6111C28.3304 13.518 29.5102 16.1542 29.5102 19.0644H27.3234C27.3234 16.7572 26.3882 14.6686 24.8766 13.1566C23.365 11.645 21.276 10.7098 18.9688 10.7098C16.6616 10.7098 14.5722 11.645 13.061 13.1566C11.5494 14.6686 10.6142 16.7572 10.6142 19.0644C10.6142 21.3716 11.5494 23.461 13.061 24.9722C14.5726 26.4838 16.6616 27.419 18.9688 27.419V29.6058H18.9692ZM18.0339 0.0238462C23.2698 -0.236559 28.119 1.65156 31.7243 4.91491C35.3303 8.17863 37.6913 12.8179 37.9517 18.0578C38.2121 23.2937 36.324 28.1429 33.0606 31.7482C29.7969 35.3538 25.1576 37.7148 19.9177 37.9755C14.6819 38.2359 9.83299 36.3478 6.22736 33.0844C2.62172 29.8207 0.260766 25.1814 0 19.9419L2.1781 19.8391C2.40893 24.477 4.49794 28.5825 7.68808 31.4701C10.8782 34.3576 15.1731 36.0286 19.8153 35.7978C24.4535 35.5669 28.5591 33.4779 31.4462 30.2874C34.3338 27.0973 36.0048 22.8024 35.7739 18.1606C35.5431 13.5223 33.4541 9.41682 30.264 6.52964C27.0738 3.64138 22.7782 1.97039 18.1367 2.20194L18.0339 0.0238462ZM33.9143 19.0644C33.9143 23.1916 32.2415 26.9274 29.5365 29.6325C26.8322 32.3368 23.0956 34.0096 18.9688 34.0096C14.8416 34.0096 11.1058 32.3368 8.40076 29.6325C5.69645 26.9274 4.02365 23.1916 4.02365 19.0644C4.02365 14.938 5.69645 11.2014 8.40076 8.49674C11.1058 5.79171 14.8416 4.11891 18.9688 4.11891V6.30566C15.4454 6.30566 12.2553 7.73356 9.9466 10.0422C7.63794 12.3512 6.20968 15.541 6.20968 19.0644C6.20968 22.5878 7.63794 25.778 9.9466 28.0866C12.2553 30.3953 15.4454 31.8232 18.9688 31.8232C22.4922 31.8232 25.682 30.3953 27.991 28.0866C30.2997 25.778 31.7276 22.5878 31.7276 19.0644H33.9143Z"
              fill="url(#paint0_linear_7294_35825)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.9705 13.1523C22.2356 13.1523 24.883 15.7997 24.883 19.0648C24.883 22.3304 22.2356 24.9777 18.9705 24.9777C15.7049 24.9777 13.0576 22.3304 13.0576 19.0648C13.0576 15.7997 15.7049 13.1523 18.9705 13.1523Z"
              fill="var(--flwmn-primary-color)"
            />
            <path
              d="M46.3945 30.8295V13.0011C46.3945 11.5638 46.6834 10.3981 47.2663 9.50908C47.8495 8.61498 48.5445 7.98416 49.3549 7.61916C50.1628 7.25452 50.9671 7.02441 51.7541 7.02441H55.9318V9.87733H52.5357C50.6115 9.87733 49.3831 10.7516 49.3831 12.5207V14.53H55.5184V17.3822H49.3831V30.8298H46.3945V30.8295Z"
              fill="var(--flwmn-primary-color)"
            />
            <path
              d="M57.1357 7.02441H60.1239V30.8298H57.1357V7.02441Z"
              fill="var(--flwmn-primary-color)"
            />
            <path
              d="M62.6529 22.4701C62.6757 20.9131 63.0948 19.4985 63.9041 18.2362C64.7192 16.9681 65.7717 15.9777 67.0737 15.2646C68.3699 14.5462 69.7001 14.1895 71.0634 14.1895C72.4787 14.2176 73.8424 14.5519 75.1502 15.2022C76.4634 15.8478 77.5324 16.7982 78.3703 18.0605C79.2081 19.3229 79.6272 20.8172 79.6272 22.5487C79.6272 23.8508 79.321 25.1582 78.7046 26.4656C78.0933 27.7731 77.1819 28.8648 75.9707 29.7481C74.7542 30.6364 73.3281 31.1064 71.6805 31.1684C70.3168 31.1684 69.0772 30.9423 67.962 30.4951C66.8475 30.0424 65.8907 29.4141 65.0875 28.6105C64.2835 27.8066 63.6722 26.8952 63.2535 25.8767C62.8289 24.852 62.6082 23.766 62.5801 22.6111C62.614 22.5826 62.6363 22.5603 62.6421 22.5321C62.6479 22.5037 62.6533 22.4813 62.6533 22.4701H62.6529ZM65.7717 22.4077C65.7717 23.9867 66.2077 25.3677 67.0737 26.545C67.9397 27.728 69.3376 28.3166 71.273 28.3166C72.2746 28.3166 73.1633 28.1186 73.9391 27.7222C74.7196 27.3201 75.337 26.6694 75.7897 25.7695C76.2427 24.8635 76.4688 23.6978 76.4688 22.2717C76.401 20.6981 75.8744 19.43 74.8894 18.4739C73.9048 17.5174 72.654 17.042 71.1431 17.042C69.9601 17.0647 68.9701 17.3421 68.1719 17.8798C67.3734 18.4172 66.7794 19.0852 66.3945 19.9003C66.0039 20.71 65.7998 21.5417 65.7717 22.4077Z"
              fill="var(--flwmn-primary-color)"
            />
            <path
              d="M81.5752 14.5293H84.5637V25.1247C84.5637 25.9849 84.8122 26.7319 85.3103 27.3656C85.8084 27.9997 86.5103 28.3167 87.4159 28.3167C89.51 28.3167 90.5851 27.0944 90.6421 24.6548V14.5297H93.6981V24.9156C93.7601 27.1849 94.8468 28.3171 96.9582 28.3171C97.8238 28.3171 98.5199 27.9946 99.035 27.3494C99.55 26.6984 99.8108 25.7758 99.8108 24.5762V14.53H102.799V25.9063C102.748 26.9479 102.437 27.8698 101.854 28.6738C101.277 29.4777 100.552 30.0944 99.6863 30.5244C98.8203 30.9546 97.9825 31.1696 97.162 31.1696C95.9851 31.1696 95.034 30.994 94.2986 30.6492C93.5682 30.3036 92.861 29.7211 92.1761 28.9003C91.0554 30.4115 89.4707 31.1696 87.4163 31.1696C86.0353 31.1303 84.9089 30.7959 84.026 30.168C83.143 29.534 82.5144 28.7928 82.1414 27.9326C81.762 27.072 81.5752 26.2742 81.5752 25.5323V14.53V14.5293Z"
              fill="var(--flwmn-primary-color)"
            />
            <path
              d="M105.392 30.831V19.6249C105.42 18.6742 105.68 17.7797 106.173 16.9531C106.665 16.121 107.373 15.453 108.295 14.9495C109.218 14.446 110.287 14.1914 111.504 14.1914C113.333 14.2423 114.833 15.0123 115.992 16.495C116.434 15.844 117.085 15.2951 117.94 14.8536C118.795 14.4121 119.785 14.1914 120.917 14.1914C121.901 14.1914 122.841 14.4345 123.718 14.9268C124.601 15.4137 125.303 16.0759 125.829 16.9138C126.356 17.7458 126.616 18.6515 126.616 19.6249V30.831H123.628V20.5418C123.628 19.6588 123.401 18.861 122.954 18.153C122.502 17.44 121.755 17.0717 120.707 17.0436C119.558 17.0436 118.738 17.3891 118.251 18.0733C117.758 18.764 117.515 19.5849 117.515 20.5414V30.8306H114.459V20.7394C114.459 19.6015 114.204 18.702 113.689 18.0398C113.18 17.3776 112.432 17.0436 111.442 17.0436C110.231 17.0717 109.416 17.44 109.003 18.153C108.589 18.8607 108.38 19.7944 108.38 20.9489V30.8306H105.392L105.392 30.831Z"
              fill="var(--flwmn-primary-color)"
            />
            <path
              d="M128.465 22.4701C128.488 20.9131 128.907 19.4985 129.717 18.2362C130.532 16.9681 131.584 15.9777 132.886 15.2646C134.182 14.5462 135.513 14.1895 136.876 14.1895C138.291 14.2176 139.655 14.5519 140.963 15.2022C142.276 15.8478 143.345 16.7982 144.183 18.0605C145.021 19.3229 145.44 20.8172 145.44 22.5487C145.44 23.8508 145.134 25.1582 144.517 26.4656C143.906 27.7731 142.994 28.8648 141.783 29.7481C140.566 30.6364 139.141 31.1064 137.493 31.1684C136.129 31.1684 134.89 30.9423 133.775 30.4951C132.66 30.0424 131.703 29.4141 130.9 28.6105C130.096 27.8066 129.485 26.8952 129.066 25.8767C128.641 24.852 128.421 23.766 128.393 22.6111C128.426 22.5826 128.449 22.5603 128.455 22.5321C128.46 22.5037 128.466 22.4813 128.466 22.4701H128.465ZM131.584 22.4077C131.584 23.9867 132.02 25.3677 132.886 26.545C133.752 27.728 135.15 28.3166 137.085 28.3166C138.087 28.3166 138.976 28.1186 139.751 27.7222C140.532 27.3201 141.15 26.6694 141.602 25.7695C142.055 24.8635 142.281 23.6978 142.281 22.2717C142.214 20.6981 141.687 19.43 140.702 18.4739C139.717 17.5174 138.466 17.042 136.956 17.042C135.773 17.0647 134.782 17.3421 133.984 17.8798C133.186 18.4172 132.592 19.0852 132.207 19.9003C131.816 20.71 131.612 21.5417 131.584 22.4077Z"
              fill="var(--flwmn-primary-color)"
            />
            <path
              d="M147.253 30.8292V20.8794C147.253 19.674 147.559 18.5588 148.176 17.5399C148.793 16.5152 149.641 15.7005 150.728 15.0891C151.809 14.4832 152.998 14.1608 154.283 14.1211C155.205 14.1211 156.207 14.3591 157.294 14.8287C158.375 15.3041 159.303 16.068 160.078 17.1266C160.854 18.1851 161.244 19.5265 161.244 21.1509V30.8288H158.256V21.2188C158.256 20.0866 157.882 19.102 157.141 18.2699C156.394 17.4378 155.442 17.0075 154.282 16.9736C153.173 16.9736 152.233 17.3643 151.463 18.1455C150.694 18.926 150.309 19.8601 150.309 20.9468V30.8285H147.253L147.253 30.8292Z"
              fill="var(--flwmn-primary-color)"
            />
            <path
              d="M162.896 22.4701C162.919 20.9131 163.338 19.4985 164.147 18.2362C164.962 16.9681 166.015 15.9777 167.317 15.2646C168.613 14.5462 169.943 14.1895 171.307 14.1895C172.722 14.2176 174.086 14.5519 175.393 15.2022C176.707 15.8478 177.776 16.7982 178.613 18.0605C179.451 19.3229 179.87 20.8172 179.87 22.5487C179.87 23.8508 179.564 25.1582 178.947 26.4656C178.337 27.7731 177.425 28.8648 176.214 29.7481C174.997 30.6364 173.571 31.1064 171.924 31.1684C170.56 31.1684 169.32 30.9423 168.205 30.4951C167.091 30.0424 166.134 29.4141 165.331 28.6105C164.527 27.8066 163.915 26.8952 163.497 25.8767C163.072 24.852 162.851 23.766 162.823 22.6111C162.857 22.5826 162.879 22.5603 162.885 22.5321C162.891 22.5037 162.896 22.4813 162.896 22.4701H162.896ZM166.015 22.4077C166.015 23.9867 166.451 25.3677 167.317 26.545C168.183 27.728 169.581 28.3166 171.516 28.3166C172.518 28.3166 173.406 28.1186 174.182 27.7222C174.963 27.3201 175.58 26.6694 176.033 25.7695C176.485 24.8635 176.712 23.6978 176.712 22.2717C176.644 20.6981 176.118 19.43 175.133 18.4739C174.148 17.5174 172.897 17.042 171.386 17.042C170.203 17.0647 169.213 17.3421 168.415 17.8798C167.617 18.4172 167.023 19.0852 166.638 19.9003C166.247 20.71 166.043 21.5417 166.015 22.4077Z"
              fill="var(--flwmn-primary-color)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_7294_35825"
              x1="-13.2976"
              y1="16.3363"
              x2="34.3179"
              y2="47.7526"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E413FF" />
              <stop offset="1" stop-color="var(--flwmn-primary-color)" />
            </linearGradient>
            <clipPath id="clip0_7294_35825">
              <rect width="179.871" height="38" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </a>
    </nav>
  </header>

  <form [formGroup]="registrationForm">
    <ng-container *ngIf="registrationType === 'individual' && page === 1">
      <div class="container individual-signup-container">
        <div class="form-container">
          <h1 class="header">Let’s get you started</h1>

          <p class="subheader">
            Not an individual account?
            <a routerLink="/register/corporate">Sign up as a corporate</a>
          </p>

          <div class="w-100">
            <div class="individual-signup-content">
              <div class="customization-section">
                <div class="profile-picture-section">
                  <div
                    class="profile-picture-container"
                    matRipple
                    (click)="profilePictureUpload.click()"
                  >
                    <div
                      class="edit-container"
                      [ngClass]="{ 'd-none': profilePicturePreview }"
                    >
                      <svg
                        width="51"
                        height="50"
                        viewBox="0 0 51 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_15161_72242)">
                          <path
                            d="M48.8365 11.4544C47.8384 10.4088 46.4601 9.79092 44.8916 9.79092H37.0019V9.69587C37.0019 8.50765 36.5266 7.36697 35.7186 6.60651C34.9106 5.79853 33.8175 5.32324 32.6293 5.32324H18.3707C17.135 5.32324 16.0418 5.79853 15.2338 6.60651C14.4259 7.4145 13.9506 8.50765 13.9506 9.69587V9.79092H6.10837C4.53992 9.79092 3.1616 10.4088 2.1635 11.4544C1.1654 12.4525 0.5 13.8784 0.5 15.3993V39.0685C0.5 40.6369 1.11787 42.0153 2.1635 43.0134C3.1616 44.0115 4.58745 44.6769 6.10837 44.6769H44.8916C46.4601 44.6769 47.8384 44.059 48.8365 43.0134C49.8346 42.0153 50.5 40.5894 50.5 39.0685V15.3993C50.5 13.8308 49.8821 12.4525 48.8365 11.4544ZM48.0285 39.0685H47.981C47.981 39.924 47.6483 40.6845 47.0779 41.2548C46.5076 41.8251 45.7472 42.1578 44.8916 42.1578H6.10837C5.25285 42.1578 4.4924 41.8251 3.92205 41.2548C3.35171 40.6845 3.01901 39.924 3.01901 39.0685V15.3993C3.01901 14.5438 3.35171 13.7833 3.92205 13.213C4.4924 12.6426 5.25285 12.3099 6.10837 12.3099H15.2814C15.9943 12.3099 16.5646 11.7396 16.5646 11.0267V9.64834C16.5646 9.12552 16.7548 8.65024 17.0875 8.31754C17.4202 7.98484 17.8954 7.79472 18.4183 7.79472H32.6293C33.1521 7.79472 33.6274 7.98484 33.9601 8.31754C34.2928 8.65024 34.4829 9.12552 34.4829 9.64834V11.0267C34.4829 11.7396 35.0532 12.3099 35.7662 12.3099H44.9392C45.7947 12.3099 46.5551 12.6426 47.1255 13.213C47.6958 13.7833 48.0285 14.5438 48.0285 15.3993V39.0685Z"
                            fill="white"
                          />
                          <path
                            d="M25.5001 15.542C22.2682 15.542 19.3214 16.8728 17.2302 18.964C15.0914 21.1028 13.8081 24.0021 13.8081 27.234C13.8081 30.4659 15.1389 33.4127 17.2302 35.504C19.3689 37.6427 22.2682 38.926 25.5001 38.926C28.7321 38.926 31.6788 37.5952 33.7701 35.504C35.9089 33.3652 37.1921 30.4659 37.1921 27.234C37.1921 24.0021 35.8613 21.0553 33.7701 18.964C31.6788 16.8728 28.7321 15.542 25.5001 15.542ZM31.964 33.7454C30.3005 35.3614 28.0191 36.407 25.5001 36.407C22.9811 36.407 20.6997 35.3614 19.0362 33.7454C17.3727 32.0819 16.3746 29.8005 16.3746 27.2815C16.3746 24.7625 17.4203 22.4812 19.0362 20.8177C20.6997 19.1542 22.9811 18.1561 25.5001 18.1561C28.0191 18.1561 30.3005 19.2017 31.964 20.8177C33.6275 22.4812 34.6256 24.7625 34.6256 27.2815C34.6731 29.8005 33.6275 32.0819 31.964 33.7454Z"
                            fill="white"
                          />
                          <path
                            d="M42.42 20.1524C43.7062 20.1524 44.7489 19.1097 44.7489 17.8235C44.7489 16.5373 43.7062 15.4946 42.42 15.4946C41.1337 15.4946 40.0911 16.5373 40.0911 17.8235C40.0911 19.1097 41.1337 20.1524 42.42 20.1524Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_15161_72242">
                            <rect
                              width="50"
                              height="50"
                              fill="white"
                              transform="translate(0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      <p class="no-select">Upload an image here</p>
                    </div>

                    <img
                      [src]="profilePicturePreview"
                      alt=""
                      class="profile-picture img-fluid"
                    />
                  </div>

                  <input
                    #profilePictureUpload
                    type="file"
                    accept="image/*"
                    name=""
                    class="d-none"
                    (change)="onUploadProfilePicture($event)"
                  />
                </div>
                <div class="theme-section">
                  <p class="theme-title">Select Theme</p>

                  <div class="theme-grid">
                    <div
                      matRipple
                      (click)="onSelectTheme($event)"
                      data-theme="theme1"
                      data-theme-color="#4E33FF"
                      class="theme theme1"
                      [ngClass]="{ selected: selectedTheme === 'theme1' }"
                    ></div>

                    <div
                      matRipple
                      (click)="onSelectTheme($event)"
                      data-theme="theme2"
                      data-theme-color="#316DF3"
                      class="theme theme2"
                      [ngClass]="{ selected: selectedTheme === 'theme2' }"
                    ></div>

                    <div
                      matRipple
                      (click)="onSelectTheme($event)"
                      data-theme="theme3"
                      data-theme-color="#FE337A"
                      class="theme theme3"
                      [ngClass]="{ selected: selectedTheme === 'theme3' }"
                    ></div>

                    <div
                      matRipple
                      (click)="onSelectTheme($event)"
                      data-theme="theme4"
                      data-theme-color="#230A84"
                      class="theme theme4"
                      [ngClass]="{ selected: selectedTheme === 'theme4' }"
                    ></div>

                    <div
                      matRipple
                      (click)="onSelectTheme($event)"
                      data-theme="theme5"
                      data-theme-color="#08004E"
                      class="theme theme5"
                      [ngClass]="{ selected: selectedTheme === 'theme5' }"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="right-section w-100">
                <div class="input-style1 mb-1">
                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <mat-label>Email Address</mat-label>
                    <input
                      #email
                      type="email"
                      inputmode="email"
                      matInput
                      formControlName="email"
                      (input)="
                        registrationForm.patchValue({
                          email: email.value.replaceAll(' ', '')
                        })
                      "
                    />
                    <mat-error *ngIf="registrationFormControls.email.invalid">
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('email') }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="input-style1 mb-1">
                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <mat-label>Password</mat-label>
                    <input
                      matInput
                      [type]="show ? 'text' : 'password'"
                      formControlName="password"
                    />
                    <mat-error
                      *ngIf="registrationFormControls.password.invalid"
                    >
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />{{ getErrorMessage('password') }}
                    </mat-error>
                    <button
                      mat-icon-button
                      matSuffix
                      type="button"
                      (click)="show = !show"
                      [attr.aria-label]="'Show password'"
                      [attr.aria-pressed]="show"
                    >
                      <mat-icon color="primary">
                        {{ show ? 'visibility' : 'visibility_off' }}
                      </mat-icon>
                    </button>
                  </mat-form-field>
                </div>

                <div class="input-style1 mb-1">
                  <div class="row g-1 g-md-2">
                    <div class="col-12 col-md-6">
                      <mat-form-field
                        appearance="outline"
                        [style.width.%]="100"
                      >
                        <mat-label>First Name</mat-label>
                        <input
                          type="text"
                          matInput
                          formControlName="firstname"
                        />
                        <mat-error
                          *ngIf="registrationFormControls.firstname.invalid"
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          {{ getErrorMessage('firstname') }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-6">
                      <mat-form-field
                        appearance="outline"
                        [style.width.%]="100"
                      >
                        <mat-label>Last Name</mat-label>
                        <input
                          type="text"
                          matInput
                          formControlName="lastname"
                        />
                        <mat-error
                          *ngIf="registrationFormControls.lastname.invalid"
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          {{ getErrorMessage('lastname') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="input-style1 mb-1">
                  <div class="row g-1 g-md-2">
                    <div class="col-12 col-md-4">
                      <mat-form-field
                        appearance="outline"
                        [style.width.%]="100"
                      >
                        <mat-label>Country Code</mat-label>

                        <input
                          #countryCode
                          type="text"
                          matInput
                          formControlName="code"
                          [matAutocomplete]="forCountryCode"
                          (input)="onFilterCountryCode(countryCode.value)"
                        />

                        <mat-autocomplete
                          class="country-code"
                          autoActiveFirstOption
                          #forCountryCode="matAutocomplete"
                          (optionSelected)="onSelectCountryCode($event)"
                        >
                          <ng-container
                            *ngIf="
                              (allFilteredCountryCodes | async) !== null;
                              else noCountryCodes
                            "
                          >
                            <mat-option
                              *ngFor="
                                let countryCode of allFilteredCountryCodes
                                  | async
                              "
                              value="{{ countryCode.code }}"
                            >
                              <div class="country-code-container">
                                <img
                                  [src]="countryCode.flag"
                                  alt=""
                                  class="flag"
                                />

                                <p class="country">
                                  {{ countryCode.country }} <span></span>
                                </p>

                                <p class="code">({{ countryCode.code }})</p>
                              </div>
                            </mat-option>
                          </ng-container>

                          <ng-template #noCountryCodes>
                            <mat-option disabled
                              >Loading Country Codes...</mat-option
                            >
                          </ng-template>
                        </mat-autocomplete>

                        <mat-error
                          *ngIf="registrationFormControls.code.invalid"
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          {{ getErrorMessage('code') }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-8">
                      <mat-form-field
                        appearance="outline"
                        [style.width.%]="100"
                      >
                        <mat-label>Phone Number</mat-label>
                        <input
                          #individualPhoneNumber
                          type="tel"
                          inputmode="tel"
                          matInput
                          formControlName="phone_number"
                          (input)="
                            registrationForm.patchValue({
                              phone_number:
                                individualPhoneNumber.value.replaceAll(' ', '')
                            })
                          "
                        />
                        <mat-error
                          *ngIf="registrationFormControls.phone_number.invalid"
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          {{ getErrorMessage('phone_number') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="input-style1 mb-1">
                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <mat-label>How did you hear about us​</mat-label>
                    <mat-select formControlName="referrer">
                      <ng-container
                        *ngFor="
                          let referrerSource of referrerSources;
                          let i = index
                        "
                      >
                        <mat-option [value]="referrerSource.enum">
                          {{ referrerSource.source }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error
                      *ngIf="registrationFormControls.referrer.invalid"
                    >
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('referrer') }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <button
              matRipple
              type="button"
              class="btn onyx-btn-flat signup-btn"
              (click)="initiateCheckUpDomainName()"
              [disabled]="validateIndividualSignUpBtn()"
              [ngStyle]="{
                background: selectedThemeColor,
                borderColor: selectedThemeColor
              }"
            >
              Sign Up
            </button>

            <p class="subheader">
              You can <a routerLink="/login">Sign In</a> from here if you are a
              returning user
            </p>

            <div class="third-party-container">
              <div class="or-signup-with-container">
                <div class="line"></div>
                <p>Or sign up with</p>
                <div class="line"></div>
              </div>

              <div class="third-party-options">
                <div
                  class="third-party-option"
                  matRipple
                  (click)="onSignUpWithGoogle()"
                  matTooltip="Sign Up with Google"
                >
                  <img
                    src="assets/images/auth/auth/google-third-party-signup.svg"
                    alt=""
                    class="img-fluid"
                  />
                </div>

                <div
                  class="third-party-option"
                  matRipple
                  (click)="onSignUpWithLinkedIn()"
                  matTooltip="Sign Up with LinkedIn"
                >
                  <img
                    src="assets/images/auth/auth/linkedin-third-party-signup.svg"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="registrationType === 'corporate' && page === 1">
      <div class="container corporate-signup-container1">
        <div class="form-container">
          <h1 class="header">Let’s get you started</h1>

          <p class="subheader">
            Not an coporate account?
            <a routerLink="/register/individual">Sign up as an individual</a>
          </p>

          <div class="w-100">
            <div class="input-style1 mb-1">
              <mat-form-field appearance="outline" [style.width.%]="100">
                <mat-label>Email Address</mat-label>
                <input
                  #email
                  type="email"
                  inputmode="email"
                  matInput
                  formControlName="email"
                  (input)="
                    registrationForm.patchValue({
                      email: email.value.replaceAll(' ', '')
                    })
                  "
                />
                <mat-error *ngIf="registrationFormControls.email.invalid">
                  <img
                    src="/assets/icons/error.svg"
                    alt=""
                    class="img-fluid error-icon"
                  />
                  {{ getErrorMessage('email') }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="input-style1 mb-1">
              <mat-form-field appearance="outline" [style.width.%]="100">
                <mat-label>Password</mat-label>
                <input
                  matInput
                  [type]="show ? 'text' : 'password'"
                  formControlName="password"
                />
                <mat-error *ngIf="registrationFormControls.password.invalid">
                  <img
                    src="/assets/icons/error.svg"
                    alt=""
                    class="img-fluid error-icon"
                  />{{ getErrorMessage('password') }}
                </mat-error>
                <button
                  mat-icon-button
                  matSuffix
                  type="button"
                  (click)="show = !show"
                  [attr.aria-label]="'Show password'"
                  [attr.aria-pressed]="show"
                >
                  <mat-icon color="primary">
                    {{ show ? 'visibility' : 'visibility_off' }}
                  </mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="input-style1 mb-1">
              <div class="row g-1 g-md-2">
                <div class="col-12 col-md-6">
                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <mat-label>First Name</mat-label>
                    <input type="text" matInput formControlName="firstname" />
                    <mat-error
                      *ngIf="registrationFormControls.firstname.invalid"
                    >
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('firstname') }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6">
                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <mat-label>Last Name</mat-label>
                    <input type="text" matInput formControlName="lastname" />
                    <mat-error
                      *ngIf="registrationFormControls.lastname.invalid"
                    >
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('lastname') }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="input-style1 mb-1">
              <div class="row g-1 g-md-2">
                <div class="col-12 col-md-4">
                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <mat-label>Country Code</mat-label>

                    <input
                      #countryCode
                      type="text"
                      matInput
                      formControlName="code"
                      [matAutocomplete]="forCountryCode"
                      (input)="onFilterCountryCode(countryCode.value)"
                    />

                    <mat-autocomplete
                      class="country-code"
                      autoActiveFirstOption
                      #forCountryCode="matAutocomplete"
                      (optionSelected)="onSelectCountryCode($event)"
                    >
                      <ng-container
                        *ngIf="
                          (allFilteredCountryCodes | async) !== null;
                          else noCountryCodes
                        "
                      >
                        <mat-option
                          *ngFor="
                            let countryCode of allFilteredCountryCodes | async
                          "
                          value="{{ countryCode.code }}"
                        >
                          <div class="country-code-container">
                            <img [src]="countryCode.flag" alt="" class="flag" />

                            <p class="country">
                              {{ countryCode.country }} <span></span>
                            </p>

                            <p class="code">({{ countryCode.code }})</p>
                          </div>
                        </mat-option>
                      </ng-container>

                      <ng-template #noCountryCodes>
                        <mat-option disabled
                          >Loading Country Codes...</mat-option
                        >
                      </ng-template>
                    </mat-autocomplete>

                    <mat-error *ngIf="registrationFormControls.code.invalid">
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('code') }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-8">
                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <mat-label>Phone Number</mat-label>
                    <input
                      #individualPhoneNumber
                      type="tel"
                      inputmode="tel"
                      matInput
                      formControlName="phone_number"
                      (input)="
                        registrationForm.patchValue({
                          phone_number: individualPhoneNumber.value.replaceAll(
                            ' ',
                            ''
                          )
                        })
                      "
                    />
                    <mat-error
                      *ngIf="registrationFormControls.phone_number.invalid"
                    >
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('phone_number') }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="input-style1 mb-1">
              <mat-form-field appearance="outline" [style.width.%]="100">
                <mat-label>How did you hear about us​</mat-label>
                <mat-select formControlName="referrer">
                  <ng-container
                    *ngFor="
                      let referrerSource of referrerSources;
                      let i = index
                    "
                  >
                    <mat-option [value]="referrerSource.enum">
                      {{ referrerSource.source }}
                    </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error *ngIf="registrationFormControls.referrer.invalid">
                  <img
                    src="/assets/icons/error.svg"
                    alt=""
                    class="img-fluid error-icon"
                  />
                  {{ getErrorMessage('referrer') }}
                </mat-error>
              </mat-form-field>
            </div>

            <button
              matRipple
              type="button"
              class="btn onyx-btn-flat onyx-btn-full continue-btn"
              (click)="onClickContinue()"
              [disabled]="validateContinueBtn()"
            >
              Continue
            </button>

            <p class="subheader">
              You can <a routerLink="/login">Sign In</a> from here if you are a
              returning user
            </p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="page === 1.5">
      <div class="domain-name">
        <div class="container">
          <header class="container-fluid mb-5">
            <nav>
              <a routerLink="/" class="brand">
                <svg
                  width="180"
                  height="38"
                  viewBox="0 0 180 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7294_35825)">
                    <path
                      d="M18.9692 29.6058C16.0582 29.6058 13.4228 28.4257 11.5152 26.5184C9.60793 24.6112 8.42745 21.9754 8.42745 19.0644C8.42745 16.1542 9.60793 13.518 11.5152 11.6111C13.4224 9.70319 16.0582 8.52343 18.9692 8.52343C21.8794 8.52343 24.5156 9.70355 26.4224 11.6111C28.3304 13.518 29.5102 16.1542 29.5102 19.0644H27.3234C27.3234 16.7572 26.3882 14.6686 24.8766 13.1566C23.365 11.645 21.276 10.7098 18.9688 10.7098C16.6616 10.7098 14.5722 11.645 13.061 13.1566C11.5494 14.6686 10.6142 16.7572 10.6142 19.0644C10.6142 21.3716 11.5494 23.461 13.061 24.9722C14.5726 26.4838 16.6616 27.419 18.9688 27.419V29.6058H18.9692ZM18.0339 0.0238462C23.2698 -0.236559 28.119 1.65156 31.7243 4.91491C35.3303 8.17863 37.6913 12.8179 37.9517 18.0578C38.2121 23.2937 36.324 28.1429 33.0606 31.7482C29.7969 35.3538 25.1576 37.7148 19.9177 37.9755C14.6819 38.2359 9.83299 36.3478 6.22736 33.0844C2.62172 29.8207 0.260766 25.1814 0 19.9419L2.1781 19.8391C2.40893 24.477 4.49794 28.5825 7.68808 31.4701C10.8782 34.3576 15.1731 36.0286 19.8153 35.7978C24.4535 35.5669 28.5591 33.4779 31.4462 30.2874C34.3338 27.0973 36.0048 22.8024 35.7739 18.1606C35.5431 13.5223 33.4541 9.41682 30.264 6.52964C27.0738 3.64138 22.7782 1.97039 18.1367 2.20194L18.0339 0.0238462ZM33.9143 19.0644C33.9143 23.1916 32.2415 26.9274 29.5365 29.6325C26.8322 32.3368 23.0956 34.0096 18.9688 34.0096C14.8416 34.0096 11.1058 32.3368 8.40076 29.6325C5.69645 26.9274 4.02365 23.1916 4.02365 19.0644C4.02365 14.938 5.69645 11.2014 8.40076 8.49674C11.1058 5.79171 14.8416 4.11891 18.9688 4.11891V6.30566C15.4454 6.30566 12.2553 7.73356 9.9466 10.0422C7.63794 12.3512 6.20968 15.541 6.20968 19.0644C6.20968 22.5878 7.63794 25.778 9.9466 28.0866C12.2553 30.3953 15.4454 31.8232 18.9688 31.8232C22.4922 31.8232 25.682 30.3953 27.991 28.0866C30.2997 25.778 31.7276 22.5878 31.7276 19.0644H33.9143Z"
                      fill="url(#paint0_linear_7294_35825)"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.9705 13.1523C22.2356 13.1523 24.883 15.7997 24.883 19.0648C24.883 22.3304 22.2356 24.9777 18.9705 24.9777C15.7049 24.9777 13.0576 22.3304 13.0576 19.0648C13.0576 15.7997 15.7049 13.1523 18.9705 13.1523Z"
                      fill="var(--flwmn-primary-color)"
                    />
                    <path
                      d="M46.3945 30.8295V13.0011C46.3945 11.5638 46.6834 10.3981 47.2663 9.50908C47.8495 8.61498 48.5445 7.98416 49.3549 7.61916C50.1628 7.25452 50.9671 7.02441 51.7541 7.02441H55.9318V9.87733H52.5357C50.6115 9.87733 49.3831 10.7516 49.3831 12.5207V14.53H55.5184V17.3822H49.3831V30.8298H46.3945V30.8295Z"
                      fill="var(--flwmn-primary-color)"
                    />
                    <path
                      d="M57.1357 7.02441H60.1239V30.8298H57.1357V7.02441Z"
                      fill="var(--flwmn-primary-color)"
                    />
                    <path
                      d="M62.6529 22.4701C62.6757 20.9131 63.0948 19.4985 63.9041 18.2362C64.7192 16.9681 65.7717 15.9777 67.0737 15.2646C68.3699 14.5462 69.7001 14.1895 71.0634 14.1895C72.4787 14.2176 73.8424 14.5519 75.1502 15.2022C76.4634 15.8478 77.5324 16.7982 78.3703 18.0605C79.2081 19.3229 79.6272 20.8172 79.6272 22.5487C79.6272 23.8508 79.321 25.1582 78.7046 26.4656C78.0933 27.7731 77.1819 28.8648 75.9707 29.7481C74.7542 30.6364 73.3281 31.1064 71.6805 31.1684C70.3168 31.1684 69.0772 30.9423 67.962 30.4951C66.8475 30.0424 65.8907 29.4141 65.0875 28.6105C64.2835 27.8066 63.6722 26.8952 63.2535 25.8767C62.8289 24.852 62.6082 23.766 62.5801 22.6111C62.614 22.5826 62.6363 22.5603 62.6421 22.5321C62.6479 22.5037 62.6533 22.4813 62.6533 22.4701H62.6529ZM65.7717 22.4077C65.7717 23.9867 66.2077 25.3677 67.0737 26.545C67.9397 27.728 69.3376 28.3166 71.273 28.3166C72.2746 28.3166 73.1633 28.1186 73.9391 27.7222C74.7196 27.3201 75.337 26.6694 75.7897 25.7695C76.2427 24.8635 76.4688 23.6978 76.4688 22.2717C76.401 20.6981 75.8744 19.43 74.8894 18.4739C73.9048 17.5174 72.654 17.042 71.1431 17.042C69.9601 17.0647 68.9701 17.3421 68.1719 17.8798C67.3734 18.4172 66.7794 19.0852 66.3945 19.9003C66.0039 20.71 65.7998 21.5417 65.7717 22.4077Z"
                      fill="var(--flwmn-primary-color)"
                    />
                    <path
                      d="M81.5752 14.5293H84.5637V25.1247C84.5637 25.9849 84.8122 26.7319 85.3103 27.3656C85.8084 27.9997 86.5103 28.3167 87.4159 28.3167C89.51 28.3167 90.5851 27.0944 90.6421 24.6548V14.5297H93.6981V24.9156C93.7601 27.1849 94.8468 28.3171 96.9582 28.3171C97.8238 28.3171 98.5199 27.9946 99.035 27.3494C99.55 26.6984 99.8108 25.7758 99.8108 24.5762V14.53H102.799V25.9063C102.748 26.9479 102.437 27.8698 101.854 28.6738C101.277 29.4777 100.552 30.0944 99.6863 30.5244C98.8203 30.9546 97.9825 31.1696 97.162 31.1696C95.9851 31.1696 95.034 30.994 94.2986 30.6492C93.5682 30.3036 92.861 29.7211 92.1761 28.9003C91.0554 30.4115 89.4707 31.1696 87.4163 31.1696C86.0353 31.1303 84.9089 30.7959 84.026 30.168C83.143 29.534 82.5144 28.7928 82.1414 27.9326C81.762 27.072 81.5752 26.2742 81.5752 25.5323V14.53V14.5293Z"
                      fill="var(--flwmn-primary-color)"
                    />
                    <path
                      d="M105.392 30.831V19.6249C105.42 18.6742 105.68 17.7797 106.173 16.9531C106.665 16.121 107.373 15.453 108.295 14.9495C109.218 14.446 110.287 14.1914 111.504 14.1914C113.333 14.2423 114.833 15.0123 115.992 16.495C116.434 15.844 117.085 15.2951 117.94 14.8536C118.795 14.4121 119.785 14.1914 120.917 14.1914C121.901 14.1914 122.841 14.4345 123.718 14.9268C124.601 15.4137 125.303 16.0759 125.829 16.9138C126.356 17.7458 126.616 18.6515 126.616 19.6249V30.831H123.628V20.5418C123.628 19.6588 123.401 18.861 122.954 18.153C122.502 17.44 121.755 17.0717 120.707 17.0436C119.558 17.0436 118.738 17.3891 118.251 18.0733C117.758 18.764 117.515 19.5849 117.515 20.5414V30.8306H114.459V20.7394C114.459 19.6015 114.204 18.702 113.689 18.0398C113.18 17.3776 112.432 17.0436 111.442 17.0436C110.231 17.0717 109.416 17.44 109.003 18.153C108.589 18.8607 108.38 19.7944 108.38 20.9489V30.8306H105.392L105.392 30.831Z"
                      fill="var(--flwmn-primary-color)"
                    />
                    <path
                      d="M128.465 22.4701C128.488 20.9131 128.907 19.4985 129.717 18.2362C130.532 16.9681 131.584 15.9777 132.886 15.2646C134.182 14.5462 135.513 14.1895 136.876 14.1895C138.291 14.2176 139.655 14.5519 140.963 15.2022C142.276 15.8478 143.345 16.7982 144.183 18.0605C145.021 19.3229 145.44 20.8172 145.44 22.5487C145.44 23.8508 145.134 25.1582 144.517 26.4656C143.906 27.7731 142.994 28.8648 141.783 29.7481C140.566 30.6364 139.141 31.1064 137.493 31.1684C136.129 31.1684 134.89 30.9423 133.775 30.4951C132.66 30.0424 131.703 29.4141 130.9 28.6105C130.096 27.8066 129.485 26.8952 129.066 25.8767C128.641 24.852 128.421 23.766 128.393 22.6111C128.426 22.5826 128.449 22.5603 128.455 22.5321C128.46 22.5037 128.466 22.4813 128.466 22.4701H128.465ZM131.584 22.4077C131.584 23.9867 132.02 25.3677 132.886 26.545C133.752 27.728 135.15 28.3166 137.085 28.3166C138.087 28.3166 138.976 28.1186 139.751 27.7222C140.532 27.3201 141.15 26.6694 141.602 25.7695C142.055 24.8635 142.281 23.6978 142.281 22.2717C142.214 20.6981 141.687 19.43 140.702 18.4739C139.717 17.5174 138.466 17.042 136.956 17.042C135.773 17.0647 134.782 17.3421 133.984 17.8798C133.186 18.4172 132.592 19.0852 132.207 19.9003C131.816 20.71 131.612 21.5417 131.584 22.4077Z"
                      fill="var(--flwmn-primary-color)"
                    />
                    <path
                      d="M147.253 30.8292V20.8794C147.253 19.674 147.559 18.5588 148.176 17.5399C148.793 16.5152 149.641 15.7005 150.728 15.0891C151.809 14.4832 152.998 14.1608 154.283 14.1211C155.205 14.1211 156.207 14.3591 157.294 14.8287C158.375 15.3041 159.303 16.068 160.078 17.1266C160.854 18.1851 161.244 19.5265 161.244 21.1509V30.8288H158.256V21.2188C158.256 20.0866 157.882 19.102 157.141 18.2699C156.394 17.4378 155.442 17.0075 154.282 16.9736C153.173 16.9736 152.233 17.3643 151.463 18.1455C150.694 18.926 150.309 19.8601 150.309 20.9468V30.8285H147.253L147.253 30.8292Z"
                      fill="var(--flwmn-primary-color)"
                    />
                    <path
                      d="M162.896 22.4701C162.919 20.9131 163.338 19.4985 164.147 18.2362C164.962 16.9681 166.015 15.9777 167.317 15.2646C168.613 14.5462 169.943 14.1895 171.307 14.1895C172.722 14.2176 174.086 14.5519 175.393 15.2022C176.707 15.8478 177.776 16.7982 178.613 18.0605C179.451 19.3229 179.87 20.8172 179.87 22.5487C179.87 23.8508 179.564 25.1582 178.947 26.4656C178.337 27.7731 177.425 28.8648 176.214 29.7481C174.997 30.6364 173.571 31.1064 171.924 31.1684C170.56 31.1684 169.32 30.9423 168.205 30.4951C167.091 30.0424 166.134 29.4141 165.331 28.6105C164.527 27.8066 163.915 26.8952 163.497 25.8767C163.072 24.852 162.851 23.766 162.823 22.6111C162.857 22.5826 162.879 22.5603 162.885 22.5321C162.891 22.5037 162.896 22.4813 162.896 22.4701H162.896ZM166.015 22.4077C166.015 23.9867 166.451 25.3677 167.317 26.545C168.183 27.728 169.581 28.3166 171.516 28.3166C172.518 28.3166 173.406 28.1186 174.182 27.7222C174.963 27.3201 175.58 26.6694 176.033 25.7695C176.485 24.8635 176.712 23.6978 176.712 22.2717C176.644 20.6981 176.118 19.43 175.133 18.4739C174.148 17.5174 172.897 17.042 171.386 17.042C170.203 17.0647 169.213 17.3421 168.415 17.8798C167.617 18.4172 167.023 19.0852 166.638 19.9003C166.247 20.71 166.043 21.5417 166.015 22.4077Z"
                      fill="var(--flwmn-primary-color)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_7294_35825"
                      x1="-13.2976"
                      y1="16.3363"
                      x2="34.3179"
                      y2="47.7526"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#E413FF" />
                      <stop offset="1" stop-color="#4E33FF" />
                    </linearGradient>
                    <clipPath id="clip0_7294_35825">
                      <rect width="179.871" height="38" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>

              <button
                matRipple
                class="btn onyx-btn-outline onyx-btn-border-radius"
                (click)="goToIndividualSignUp()"
              >
                Sign up as an individual
              </button>
            </nav>
          </header>

          <div class="body">
            <h1 class="header">
              We noticed that there is a company with this domain name
            </h1>

            <h2 class="subheader2">
              Would you like to join or sign up with a different company email?
            </h2>

            <div class="d-flex flex-column align-items-center">
              <div class="organization-card">
                <div class="organization-profile-container">
                  <img
                    [src]="discoveredCompany.logo"
                    alt=""
                    class="img-fluid organization-profile"
                  />
                </div>

                <div class="">
                  <h3 class="organization-name">
                    {{ discoveredCompany.name }}
                  </h3>

                  <div
                    class="d-flex align-items-center justify-content-center justify-content-md-start"
                  >
                    <p class="organization-text">
                      <span>Team members:</span>

                      <span class="d-flex">
                        <ng-container
                          *ngFor="let user of allUsers | slice: 0:6"
                        >
                          <div
                            class="user-initials-container"
                            [matTooltip]="user.firstName + ' ' + user.lastName"
                            randomColor
                            [backgroundColor]="true"
                          >
                            <ng-container
                              *ngIf="
                                user.profilePicture !== null;
                                else noProfilePicture
                              "
                            >
                              <img
                                class="img-fluid user-initials-img"
                                [src]="user.profilePicture"
                                alt=""
                              />
                            </ng-container>
                            <ng-template #noProfilePicture>
                              <p class="user-initials-text">
                                {{
                                  user!.firstName + ' ' + user!.lastName
                                    | nameInitials
                                }}
                              </p>
                            </ng-template>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="allUsers!.length - 6 > 0">
                          <div class="user-initials-container">
                            <p class="user-initials-text">
                              +{{ allUsers!.length - 6 }}
                            </p>
                          </div>
                        </ng-container>
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <p class="subtext">
                Let’s know few things about you so we can make your onboarding
                seamless.
              </p>

              <div class="row g-1 g-md-2 w-100">
                <div class="col-12 col-md-6">
                  <div class="input-style1 mb-1">
                    <mat-form-field appearance="outline" [style.width.%]="100">
                      <mat-label>First Name</mat-label>
                      <input type="text" matInput formControlName="firstname" />
                      <mat-error
                        *ngIf="registrationFormControls.firstname.invalid"
                      >
                        <img
                          src="/assets/icons/error.svg"
                          alt=""
                          class="img-fluid error-icon"
                        />
                        {{ getErrorMessage('firstname') }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="input-style1 mb-1">
                    <mat-form-field appearance="outline" [style.width.%]="100">
                      <mat-label>Last Name</mat-label>
                      <input type="text" matInput formControlName="lastname" />
                      <mat-error
                        *ngIf="registrationFormControls.lastname.invalid"
                      >
                        <img
                          src="/assets/icons/error.svg"
                          alt=""
                          class="img-fluid error-icon"
                        />
                        {{ getErrorMessage('lastname') }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <button
                matRipple
                class="btn onyx-btn-flat join-btn"
                (click)="initiateJoinExistingOrganization(discoveredCompany.id)"
                [disabled]="validateFirstNameAndLastNameForPage1_5()"
              >
                Join {{ discoveredCompany.name }}
              </button>

              <p class="subheader3">
                Don’t belong to this company?
                <a (click)="goToCorporateSignUp()"
                  >Sign up with a different company email
                </a>
              </p>
            </div>
          </div>
        </div>

        <footer>
          <p class="footer-text">
            &copy; {{ year }} All rights reserved | Powered by Flowmono and
            Revent Technologies.
          </p>
        </footer>
      </div>
    </ng-container>

    <ng-container *ngIf="registrationType === 'corporate' && page === 2">
      <div
        matRipple
        class="back-btn d-flex d-lg-none align-items-center justify-content-center"
        (click)="goToPage(1)"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.0002 36.6668C29.2049 36.6668 36.6668 29.2049 36.6668 20.0002C36.6668 10.7954 29.2049 3.3335 20.0002 3.3335C10.7954 3.3335 3.3335 10.7954 3.3335 20.0002C3.3335 29.2049 10.7954 36.6668 20.0002 36.6668Z"
            stroke="black"
            stroke-width="3.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.0002 13.3335L13.3335 20.0002L20.0002 26.6668"
            stroke="black"
            stroke-width="3.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M26.6668 20H13.3335"
            stroke="black"
            stroke-width="3.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div class="header-container container">
        <div matRipple class="back-btn d-none d-lg-flex" (click)="goToPage(1)">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.0002 36.6668C29.2049 36.6668 36.6668 29.2049 36.6668 20.0002C36.6668 10.7954 29.2049 3.3335 20.0002 3.3335C10.7954 3.3335 3.3335 10.7954 3.3335 20.0002C3.3335 29.2049 10.7954 36.6668 20.0002 36.6668Z"
              stroke="black"
              stroke-width="3.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.0002 13.3335L13.3335 20.0002L20.0002 26.6668"
              stroke="black"
              stroke-width="3.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M26.6668 20H13.3335"
              stroke="black"
              stroke-width="3.33333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <h1 class="header2">You are almost done!</h1>
      </div>

      <div class="container individual-signup-container">
        <div class="form-container">
          <div class="w-100">
            <div class="corporate-signup-content">
              <div class="customization-section">
                <div class="profile-picture-section">
                  <div
                    class="profile-picture-container"
                    matRipple
                    (click)="profilePictureUpload.click()"
                  >
                    <div
                      class="edit-container"
                      [ngClass]="{ 'd-none': profilePicturePreview }"
                    >
                      <svg
                        width="51"
                        height="50"
                        viewBox="0 0 51 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_15161_72242)">
                          <path
                            d="M48.8365 11.4544C47.8384 10.4088 46.4601 9.79092 44.8916 9.79092H37.0019V9.69587C37.0019 8.50765 36.5266 7.36697 35.7186 6.60651C34.9106 5.79853 33.8175 5.32324 32.6293 5.32324H18.3707C17.135 5.32324 16.0418 5.79853 15.2338 6.60651C14.4259 7.4145 13.9506 8.50765 13.9506 9.69587V9.79092H6.10837C4.53992 9.79092 3.1616 10.4088 2.1635 11.4544C1.1654 12.4525 0.5 13.8784 0.5 15.3993V39.0685C0.5 40.6369 1.11787 42.0153 2.1635 43.0134C3.1616 44.0115 4.58745 44.6769 6.10837 44.6769H44.8916C46.4601 44.6769 47.8384 44.059 48.8365 43.0134C49.8346 42.0153 50.5 40.5894 50.5 39.0685V15.3993C50.5 13.8308 49.8821 12.4525 48.8365 11.4544ZM48.0285 39.0685H47.981C47.981 39.924 47.6483 40.6845 47.0779 41.2548C46.5076 41.8251 45.7472 42.1578 44.8916 42.1578H6.10837C5.25285 42.1578 4.4924 41.8251 3.92205 41.2548C3.35171 40.6845 3.01901 39.924 3.01901 39.0685V15.3993C3.01901 14.5438 3.35171 13.7833 3.92205 13.213C4.4924 12.6426 5.25285 12.3099 6.10837 12.3099H15.2814C15.9943 12.3099 16.5646 11.7396 16.5646 11.0267V9.64834C16.5646 9.12552 16.7548 8.65024 17.0875 8.31754C17.4202 7.98484 17.8954 7.79472 18.4183 7.79472H32.6293C33.1521 7.79472 33.6274 7.98484 33.9601 8.31754C34.2928 8.65024 34.4829 9.12552 34.4829 9.64834V11.0267C34.4829 11.7396 35.0532 12.3099 35.7662 12.3099H44.9392C45.7947 12.3099 46.5551 12.6426 47.1255 13.213C47.6958 13.7833 48.0285 14.5438 48.0285 15.3993V39.0685Z"
                            fill="white"
                          />
                          <path
                            d="M25.5001 15.542C22.2682 15.542 19.3214 16.8728 17.2302 18.964C15.0914 21.1028 13.8081 24.0021 13.8081 27.234C13.8081 30.4659 15.1389 33.4127 17.2302 35.504C19.3689 37.6427 22.2682 38.926 25.5001 38.926C28.7321 38.926 31.6788 37.5952 33.7701 35.504C35.9089 33.3652 37.1921 30.4659 37.1921 27.234C37.1921 24.0021 35.8613 21.0553 33.7701 18.964C31.6788 16.8728 28.7321 15.542 25.5001 15.542ZM31.964 33.7454C30.3005 35.3614 28.0191 36.407 25.5001 36.407C22.9811 36.407 20.6997 35.3614 19.0362 33.7454C17.3727 32.0819 16.3746 29.8005 16.3746 27.2815C16.3746 24.7625 17.4203 22.4812 19.0362 20.8177C20.6997 19.1542 22.9811 18.1561 25.5001 18.1561C28.0191 18.1561 30.3005 19.2017 31.964 20.8177C33.6275 22.4812 34.6256 24.7625 34.6256 27.2815C34.6731 29.8005 33.6275 32.0819 31.964 33.7454Z"
                            fill="white"
                          />
                          <path
                            d="M42.42 20.1524C43.7062 20.1524 44.7489 19.1097 44.7489 17.8235C44.7489 16.5373 43.7062 15.4946 42.42 15.4946C41.1337 15.4946 40.0911 16.5373 40.0911 17.8235C40.0911 19.1097 41.1337 20.1524 42.42 20.1524Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_15161_72242">
                            <rect
                              width="50"
                              height="50"
                              fill="white"
                              transform="translate(0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      <p class="no-select">Upload an image here</p>
                    </div>

                    <img
                      [src]="profilePicturePreview"
                      alt=""
                      class="profile-picture img-fluid"
                    />
                  </div>

                  <input
                    #profilePictureUpload
                    type="file"
                    accept="image/*"
                    name=""
                    class="d-none"
                    (change)="onUploadProfilePicture($event)"
                  />
                </div>
                <div class="theme-section">
                  <p class="theme-title">Select Theme</p>

                  <div class="theme-grid">
                    <div
                      matRipple
                      (click)="onSelectTheme($event)"
                      data-theme="theme1"
                      data-theme-color="#4E33FF"
                      class="theme theme1"
                      [ngClass]="{ selected: selectedTheme === 'theme1' }"
                    ></div>

                    <div
                      matRipple
                      (click)="onSelectTheme($event)"
                      data-theme="theme2"
                      data-theme-color="#316DF3"
                      class="theme theme2"
                      [ngClass]="{ selected: selectedTheme === 'theme2' }"
                    ></div>

                    <div
                      matRipple
                      (click)="onSelectTheme($event)"
                      data-theme="theme3"
                      data-theme-color="#FE337A"
                      class="theme theme3"
                      [ngClass]="{ selected: selectedTheme === 'theme3' }"
                    ></div>

                    <div
                      matRipple
                      (click)="onSelectTheme($event)"
                      data-theme="theme4"
                      data-theme-color="#230A84"
                      class="theme theme4"
                      [ngClass]="{ selected: selectedTheme === 'theme4' }"
                    ></div>

                    <div
                      matRipple
                      (click)="onSelectTheme($event)"
                      data-theme="theme5"
                      data-theme-color="#08004E"
                      class="theme theme5"
                      [ngClass]="{ selected: selectedTheme === 'theme5' }"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="right-section w-100">
                <h2 class="title">
                  Tell us a little about your organization or team
                </h2>

                <div class="input-style1 mb-1">
                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <mat-label>Company Name</mat-label>
                    <input
                      type="text"
                      matInput
                      formControlName="company_name"
                    />
                    <mat-error
                      *ngIf="registrationFormControls.company_name.invalid"
                    >
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('company_name') }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="input-style1 mb-1">
                  <div class="row g-1 g-md-2">
                    <div class="col-12 col-md-6">
                      <mat-form-field
                        appearance="outline"
                        [style.width.%]="100"
                      >
                        <mat-label>Country</mat-label>

                        <input
                          #country
                          type="text"
                          matInput
                          formControlName="country"
                          [matAutocomplete]="forCountries"
                          (input)="onFilterCountry(country.value)"
                        />

                        <mat-autocomplete
                          autoActiveFirstOption
                          #forCountries="matAutocomplete"
                          (optionSelected)="onSelectCountry($event)"
                        >
                          <ng-container
                            *ngIf="
                              (allFilteredCountries | async) !== null;
                              else noCountries
                            "
                          >
                            <mat-option
                              *ngFor="
                                let country of allFilteredCountries | async
                              "
                              [value]="country"
                            >
                              {{ country }}
                            </mat-option>
                          </ng-container>

                          <ng-template #noCountries>
                            <mat-option disabled
                              >Loading Countries...</mat-option
                            >
                          </ng-template>
                        </mat-autocomplete>

                        <mat-error
                          *ngIf="registrationFormControls.country.invalid"
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          {{ getErrorMessage('country') }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-6">
                      <mat-form-field
                        appearance="outline"
                        [style.width.%]="100"
                      >
                        <mat-label>State</mat-label>

                        <input
                          #states
                          type="text"
                          matInput
                          formControlName="state"
                          [matAutocomplete]="forStates"
                          (input)="onFilterStates(states.value)"
                        />

                        <mat-autocomplete
                          autoActiveFirstOption
                          #forStates="matAutocomplete"
                        >
                          <ng-container
                            *ngIf="
                              (allFilteredStates | async) !== null;
                              else noStates
                            "
                          >
                            <mat-option
                              *ngFor="let state of allFilteredStates | async"
                              [value]="state.name"
                            >
                              {{ state.name }}
                            </mat-option>
                          </ng-container>

                          <ng-template #noStates>
                            <ng-container
                              *ngIf="
                                registrationFormControls.country.value === null
                              "
                            >
                              <mat-option disabled>Select Country</mat-option>
                            </ng-container>
                            <ng-container
                              *ngIf="
                                registrationFormControls.country.value !== null
                              "
                            >
                              <mat-option disabled
                                >Loading states...</mat-option
                              >
                            </ng-container>
                          </ng-template>
                        </mat-autocomplete>

                        <mat-error
                          *ngIf="registrationFormControls.state.invalid"
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          {{ getErrorMessage('state') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <h3 class="subtitle">
                  Would you like to invite people to your organization?
                </h3>

                <div
                  formArrayName="invitees"
                  *ngFor="
                    let invitee of inviteesFormArray.controls;
                    let i = index
                  "
                >
                  <div [formGroupName]="i">
                    <div class="input-style1 mb-1">
                      <div class="row g-1 g-md-2">
                        <div class="col-12 col-md-8">
                          <mat-form-field
                            appearance="outline"
                            [style.width.%]="100"
                          >
                            <mat-label>Email</mat-label>
                            <input
                              #inviteeEmail
                              type="email"
                              inputmode="email"
                              matInput
                              formControlName="email"
                              (input)="
                                inviteesFormArray.controls[i].patchValue({
                                  email: inviteeEmail.value.replaceAll(' ', '')
                                })
                              "
                            />

                            <mat-error
                              *ngIf="
                                inviteesFormArray.controls[i].get('email')!
                                  .errors?.required
                              "
                            >
                              <img
                                src="/assets/icons/error.svg"
                                alt=""
                                class="img-fluid error-icon"
                              />
                              Please enter invitee's email
                            </mat-error>

                            <mat-error
                              *ngIf="
                                inviteesFormArray.controls[i].get('email')!
                                  .errors?.email
                              "
                            >
                              <img
                                src="/assets/icons/error.svg"
                                alt=""
                                class="img-fluid error-icon"
                              />
                              Sorry, this is not a valid email
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-11 col-md-3">
                          <mat-form-field
                            appearance="outline"
                            [style.width.%]="100"
                          >
                            <mat-label>Role</mat-label>
                            <mat-select formControlName="role">
                              <mat-option [value]="1">Admin</mat-option>
                              <mat-option [value]="2">Manager</mat-option>
                              <mat-option [value]="3">User</mat-option>
                            </mat-select>
                            <mat-error
                              *ngIf="
                                inviteesFormArray.controls[i].get('role')!
                                  .errors?.required
                              "
                            >
                              <img
                                src="/assets/icons/error.svg"
                                alt=""
                                class="img-fluid error-icon"
                              />
                              Required
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="col-1">
                          <div
                            matRipple
                            class="remove-invitee-icon-container no-select"
                          >
                            <svg
                              (click)="removeInvitee(i)"
                              class="remove-invitee-icon"
                              width="23"
                              height="24"
                              viewBox="0 0 23 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.5 0.5C5.14718 0.5 0 5.64718 0 12C0 18.3528 5.14718 23.5 11.5 23.5C17.8528 23.5 23 18.3528 23 12C23 5.64718 17.8528 0.5 11.5 0.5ZM5.37903 13.8548C5.07298 13.8548 4.82258 13.6044 4.82258 13.2984V10.7016C4.82258 10.3956 5.07298 10.1452 5.37903 10.1452H17.621C17.927 10.1452 18.1774 10.3956 18.1774 10.7016V13.2984C18.1774 13.6044 17.927 13.8548 17.621 13.8548H5.37903Z"
                                fill="#ee0020"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  matRipple
                  (click)="addInvitee()"
                  class="add-invitee-container no-select"
                >
                  <svg
                    class="add-invitee-icon"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5 0C5.14718 0 0 5.14718 0 11.5C0 17.8528 5.14718 23 11.5 23C17.8528 23 23 17.8528 23 11.5C23 5.14718 17.8528 0 11.5 0ZM18.1774 12.7984C18.1774 13.1044 17.927 13.3548 17.621 13.3548H13.3548V17.621C13.3548 17.927 13.1044 18.1774 12.7984 18.1774H10.2016C9.89557 18.1774 9.64516 17.927 9.64516 17.621V13.3548H5.37903C5.07298 13.3548 4.82258 13.1044 4.82258 12.7984V10.2016C4.82258 9.89557 5.07298 9.64516 5.37903 9.64516H9.64516V5.37903C9.64516 5.07298 9.89557 4.82258 10.2016 4.82258H12.7984C13.1044 4.82258 13.3548 5.07298 13.3548 5.37903V9.64516H17.621C17.927 9.64516 18.1774 9.89557 18.1774 10.2016V12.7984Z"
                      fill="var(--flwmn-primary-color)"
                    />
                  </svg>

                  <p class="add-invitee-text">Add invitee</p>
                </div>
              </div>
            </div>

            <button
              matRipple
              type="button"
              class="btn onyx-btn-flat signup-btn"
              (click)="onSubmit()"
              [disabled]="validateCorporateSignUpBtn()"
              [ngStyle]="{
                background: selectedThemeColor,
                borderColor: selectedThemeColor
              }"
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </form>

  <footer>
    <p class="footer-text">
      &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
      Technologies.
    </p>
  </footer>
</section> -->
