import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HelperService } from '../../services/helper.service';
import { NotificationService } from '../../services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as ManageSLAActions from './manage-sla.actions';
import { catchError, map, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { Notification } from '../../interfaces';

@Injectable()
export class ManageSLAEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store,
    private helperService: HelperService,
    private notificationService: NotificationService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getSigningRequestCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetCategories),
      switchMap((data) => {
        return this.http
          .post(`${data.payload.categoryUrl}`, data.payload, {
            context: new HttpContext().set(USE_ACCESS_TOKEN, true),
          })
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.SaveSigningRequestCategories({
                    payload: response.entity.entity,
                  })
                );
                const notification: Notification = {
                  state: 'success',
                  message: '' || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Manage SLA] Get Signing Request Categories Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );
                return {
                  type: '[Manage SLA] Get Categories Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Failed to fetch categories'
              );
            })
          );
      })
    )
  );

  getProcessCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetProcessCategories),
      switchMap((data) => {
        return this.http
          .get(
            `${environment.onyxDocProcessUrl}/ProcessCategory/getallprocesscategories/${data.payload.subscriberId}/${data.payload.userId}/${data.payload.skip}/${data.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.SaveProcessCategories({
                    payload: response.entity.pageItems,
                  })
                );
                const notification: Notification = {
                  state: 'success',
                  message: '' || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Manage SLA] Get Process Categories Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );
                return {
                  type: '[Manage SLA] Get Process Categories Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed to get process categories'
              );
            })
          );
      })
    )
  );

  getAllDepartments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetAllDepartments),
      switchMap((data: any) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Department/getalldepartment/${data.payload.subscriberId}/${data.payload.userId}/${data.payload.skip}/${data.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.SaveAllDepartments({
                    payload: response.entity.departments,
                  })
                );
                const notification: Notification = {
                  state: 'success',
                  message: '' || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Manage SLA] Get Departments Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );
                return {
                  type: '[Manage SLA] Get Departments Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To get departments'
              );
            })
          );
      })
    )
  );

  getAllDivisions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetAllDivisions),
      switchMap((data: any) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Division/getalldivision/${data.payload.subscriberId}/${data.payload.userId}/${data.payload.skip}/${data.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.SaveAllDivisions({
                    payload: response.entity.divisions,
                  })
                );
                const notification: Notification = {
                  state: 'success',
                  message: '' || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Manage SLA] Get Divisions Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );
                return {
                  type: '[Manage SLA] Get Divisions Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To get divisions'
              );
            })
          );
      })
    )
  );

  getAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetAllUsers),
      switchMap((data: any) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Users/getall/${data.payload.userId}/${data.payload.skip}/${data.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.SaveAllUsers({
                    payload: response.entity,
                  })
                );
                const notification: Notification = {
                  state: 'success',
                  message: '' || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Manage SLA] Get Users Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );
                return {
                  type: '[Manage SLA] Get Users Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To get users'
              );
            })
          );
      })
    )
  );

  getAllUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetAllUnits),
      switchMap((data: any) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Units/getallunits/${data.payload.subscriberId}/${data.payload.userId}/${data.payload.skip}/${data.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.SaveAllUnits({
                    payload: response.entity.units,
                  })
                );
                const notification: Notification = {
                  state: 'success',
                  message: '' || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Manage SLA] Get Units Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );
                return {
                  type: '[Manage SLA] Get Units Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To get units'
              );
            })
          );
      })
    )
  );

  getAllRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetAllRoles),
      switchMap((data: any) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Roles/getall/${data.payload.userId}/${data.payload.skip}/${data.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.SaveAllRoles({
                    payload: response.entity.roleList,
                  })
                );
                const notification: Notification = {
                  state: 'success',
                  message: '' || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return {
                  type: '[Manage SLA] Get Departments Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };
                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error',
                  true
                );
                return {
                  type: '[Manage SLA] Get Departments Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To get departments'
              );
            })
          );
      })
    )
  );

  createSla$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.CreateSla),
      switchMap((data) => {
        return this.http
          .post(`${environment.onyxSLA}api/sla/create`, data.payload, {
            context: new HttpContext().set(USE_ACCESS_TOKEN, true),
          })
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'SLA created successfully!' || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return ManageSLAActions.CreationStatus({
                  payload: true,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Create SLA  Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To create SLA'
              );
            })
          );
      })
    )
  );

  saveSlaAsDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.SaveAsDraft),
      switchMap((data) => {
        return this.http
          .post(`${environment.onyxSLA}api/sla/draft/create`, data.payload, {
            context: new HttpContext().set(USE_ACCESS_TOKEN, true),
          })
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'Draft saved successfully!' || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return ManageSLAActions.CreationStatus({
                  payload: true,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Save Draft  Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To create SLA'
              );
            })
          );
      })
    )
  );

  updateSla$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.UpdateSla),
      switchMap((data) => {
        return this.http
          .post(`${environment.onyxSLA}api/sla/update`, data.payload, {
            context: new HttpContext().set(USE_ACCESS_TOKEN, true),
          })
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: 'SLA updated successfully!' || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return ManageSLAActions.CreationStatus({
                  payload: true,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Update SLA  Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To update SLA'
              );
            })
          );
      })
    )
  );

  activateSla$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.ActivateSla),
      switchMap((data) => {
        return this.http
          .post(`${environment.onyxSLA}api/sla/activate`, data.payload, {
            context: new HttpContext().set(USE_ACCESS_TOKEN, true),
          })
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message:
                    'SLA activated successfully!' || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return ManageSLAActions.GetSlaDashboardData({
                  payload: {
                    ownerId: data.payload.ownerId,
                    subscriberId: data.payload.subscriberId,
                    take: 100,
                    skip: 0,
                    dateRange: 'ThisYear',
                    product: 'All',
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Activate SLA  Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To activate SLA'
              );
            })
          );
      })
    )
  );

  deactivateSla$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.DeactivateSla),
      switchMap((data) => {
        return this.http
          .post(`${environment.onyxSLA}api/sla/deactivate`, data.payload, {
            context: new HttpContext().set(USE_ACCESS_TOKEN, true),
          })
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message:
                    'SLA deactivated successfully!' || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return ManageSLAActions.GetSlaDashboardData({
                  payload: {
                    ownerId: data.payload.ownerId,
                    subscriberId: data.payload.subscriberId,
                    take: 100,
                    skip: 0,
                    dateRange: 'ThisYear',
                    product: 'All',
                  },
                });

                // return {
                //   type: '[Manage SLA] Deactivate SLA successful',
                // };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Deactivate SLA  Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Deactivate SLA'
              );
            })
          );
      })
    )
  );

  getSlaById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetSlaById),
      switchMap((data) => {
        return this.http
          .get(
            `${environment.onyxSLA}api/sla/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.id}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.SaveSlaById({
                    payload: response.entity.entity[0],
                  })
                );
                return {
                  type: '[Manage SLA] Get SLA By Id Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Get SLA By Id Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get SLA By Id'
              );
            })
          );
      })
    )
  );

  getSlaByCategoryName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetSlaByCategoryName),
      switchMap((data) => {
        return this.http
          .get(
            `${environment.onyxSLA}api/sla/bycategoryname/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.categoryName}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );

                this.store.dispatch(
                  ManageSLAActions.SaveSlaByCategoryName({
                    payload: response.entity,
                  })
                );
                return {
                  type: '[Manage SLA] Get SLA By Category Name Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Get SLA By Category Name Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get SLA By Category Name'
              );
            })
          );
      })
    )
  );

  getAllSlaInstances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetAllSlaInstances),
      switchMap((data) => {
        return this.http
          .get(
            data.payload.searchTerm
              ? `${environment.onyxSLA}api/slainstance/slainstances/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}&searchTerm=${data.payload.searchTerm}`
              : `${environment.onyxSLA}api/slainstance/slainstances/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );

                if (response.entity.result) {
                  this.store.dispatch(
                    ManageSLAActions.SaveAllSlaInstances({
                      payload: response.entity,
                    })
                  );
                } else {
                  this.store.dispatch(
                    ManageSLAActions.SaveAllSlaInstances({
                      payload: [],
                    })
                  );
                }

                return { type: '[Manage SLA] Get All SLA Instance Successful' };
              } else {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );

                return {
                  type: '[Manage SLA] Get All SLA Instance Failed',
                };
              }
            }),
            catchError((errorRes) => {
              this.store.dispatch(
                ManageSLAActions.IsLoading({
                  payload: false,
                })
              );

              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get All SLA Instances'
              );
            })
          );
      })
    )
  );

  getRunningSlaInstances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetRunningSlaInstances),
      switchMap((data) => {
        return this.http
          .get(
            data.payload.searchTerm
              ? `${environment.onyxSLA}api/slainstance/runnings/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}&seachTerm=${data.payload.searchTerm}`
              : `${environment.onyxSLA}api/slainstance/runnings/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );

                if (response.entity.entity) {
                  this.store.dispatch(
                    ManageSLAActions.SaveRunningSlaInstances({
                      payload: response.entity,
                    })
                  );
                } else {
                  this.store.dispatch(
                    ManageSLAActions.SaveRunningSlaInstances({
                      payload: [],
                    })
                  );
                }

                return {
                  type: '[Manage SLA] Get Running SLA Instance Successful',
                };
              } else {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );

                return {
                  type: '[Manage SLA] Get Running SLA Instances Failed',
                };
              }
            }),
            catchError((errorRes) => {
              this.store.dispatch(
                ManageSLAActions.IsLoading({
                  payload: false,
                })
              );

              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get Running SLA Instances'
              );
            })
          );
      })
    )
  );

  getPausedSlaInstances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetPausedSlaInstances),
      switchMap((data) => {
        return this.http
          .get(
            data.payload.searchTerm
              ? `${environment.onyxSLA}api/slainstance/pauses/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}&seachTerm=${data.payload.searchTerm}`
              : `${environment.onyxSLA}api/slainstance/pauses/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );

                if (response.entity.entity) {
                  this.store.dispatch(
                    ManageSLAActions.SavePausedSlaInstances({
                      payload: response.entity,
                    })
                  );
                }
                return {
                  type: '[Manage SLA] Get Paused SLA Instances Successful',
                };
              } else {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );
                return {
                  type: '[Manage SLA] Get Paused SLA Instances Failed',
                };
              }
            }),
            catchError((errorRes) => {
              this.store.dispatch(
                ManageSLAActions.IsLoading({
                  payload: false,
                })
              );
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get Paused SLA Instances'
              );
            })
          );
      })
    )
  );

  getCompletedSlaInstances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetCompletedSlaInstances),
      switchMap((data) => {
        return this.http
          .get(
            data.payload.searchTerm
              ? `${environment.onyxSLA}api/slainstance/completes/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}&seachTerm=${data.payload.searchTerm}`
              : `${environment.onyxSLA}api/slainstance/completes/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );

                if (response.entity.result) {
                  this.store.dispatch(
                    ManageSLAActions.SaveCompletedSlaInstances({
                      payload: response.entity,
                    })
                  );
                } else {
                  this.store.dispatch(
                    ManageSLAActions.SaveCompletedSlaInstances({
                      payload: [],
                    })
                  );
                }

                return {
                  type: '[Manage SLA] Get All Completed Instances Successful',
                };
              } else {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );
                return {
                  type: '[Manage SLA] Get Completed SLA Instances Failed',
                };
              }
            }),
            catchError((errorRes) => {
              this.store.dispatch(
                ManageSLAActions.IsLoading({
                  payload: false,
                })
              );
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get Completed SLA Instances'
              );
            })
          );
      })
    )
  );

  getPassedSlaInstances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetPassedSlaInstances),
      switchMap((data) => {
        return this.http
          .get(
            data.payload.searchTerm
              ? `${environment.onyxSLA}api/slainstance/passes/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}&seachTerm=${data.payload.searchTerm}`
              : `${environment.onyxSLA}api/slainstance/passes/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );

                if (response.entity.entity) {
                  this.store.dispatch(
                    ManageSLAActions.SavePassedSlaInstances({
                      payload: response.entity,
                    })
                  );
                } else {
                  this.store.dispatch(
                    ManageSLAActions.SavePassedSlaInstances({
                      payload: [],
                    })
                  );
                }

                return {
                  type: '[Manage SLA] Get Passed SLA Instance Successful',
                };
              } else {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );

                return {
                  type: '[Manage SLA] Get Passed SLA Instance Failed',
                };
              }
            }),
            catchError((errorRes) => {
              this.store.dispatch(
                ManageSLAActions.IsLoading({
                  payload: false,
                })
              );

              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get Passed SLA Instances'
              );
            })
          );
      })
    )
  );

  getFailedSlaInstances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetFailedSlaInstances),
      switchMap((data) => {
        return this.http
          .get(
            data.payload.searchTerm
              ? `${environment.onyxSLA}api/slainstance/fails/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}&seachTerm=${data.payload.searchTerm}`
              : `${environment.onyxSLA}api/slainstance/fails/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );

                if (response.entity.entity) {
                  this.store.dispatch(
                    ManageSLAActions.SaveFailedSlaInstances({
                      payload: response.entity,
                    })
                  );
                } else {
                  this.store.dispatch(
                    ManageSLAActions.SaveFailedSlaInstances({
                      payload: [],
                    })
                  );
                }

                return {
                  type: '[Manage SLA] Get Failed SLA Instance Successful',
                };
              } else {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );

                return {
                  type: '[Manage SLA] Get Failed SLA Instance Failed',
                };
              }
            }),
            catchError((errorRes) => {
              this.store.dispatch(
                ManageSLAActions.IsLoading({
                  payload: false,
                })
              );

              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get Failed SLA Instances'
              );
            })
          );
      })
    )
  );

  getSlaDashboardData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetSlaDashboardData),
      switchMap((data) => {
        return this.http
          .get(
            data.payload.searchTerm
              ? `${environment.onyxSLA}api/sla/dashboard/slas/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}&searchTerm=${data.payload.searchTerm}`
              : `${environment.onyxSLA}api/sla/dashboard/slas/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );

                if (response.entity.entity) {
                  this.store.dispatch(
                    ManageSLAActions.SaveSlaDashboardData({
                      payload: response.entity,
                    })
                  );
                } else {
                  this.store.dispatch(
                    ManageSLAActions.SaveSlaDashboardData({
                      payload: [],
                    })
                  );
                }

                return {
                  type: '[Manage SLA] Get SLA Dashboard Data Successful',
                };
              } else {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({
                    payload: false,
                  })
                );
                return {
                  type: '[Manage SLA] Get SLA Dashboard Data Failed',
                };
              }
            }),
            catchError((errorRes) => {
              this.store.dispatch(
                ManageSLAActions.IsLoading({
                  payload: false,
                })
              );
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get SLA Dashboard Data'
              );
            })
          );
      })
    )
  );

  getSlaInstancesById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetSlaInstancesById),
      switchMap((data) => {
        return this.http
          .post(
            `${environment.onyxSLA}api/slainstance/sla/slainstances?dateRange=${data.payload.dateRange}`,
            data.payload,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({ payload: false })
                );

                if (response.entity.entity) {
                  this.store.dispatch(
                    ManageSLAActions.SaveSlaInstancesById({
                      payload: response.entity,
                    })
                  );
                } else {
                  this.store.dispatch(
                    ManageSLAActions.SaveSlaInstancesById({
                      payload: [],
                    })
                  );
                }

                return {
                  type: '[Manage SLA] Get SLA Instances By Id Successful',
                };
              } else {
                this.store.dispatch(
                  ManageSLAActions.IsLoading({ payload: false })
                );
                return {
                  type: '[Manage SLA] Get SLA Instances By Id Failed',
                };
              }
            }),
            catchError((errorRes) => {
              this.store.dispatch(
                ManageSLAActions.IsLoading({ payload: false })
              );
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get SLA Instances By Id'
              );
            })
          );
      })
    )
  );

  getTopFiveTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetTopFiveTeams),
      switchMap((data) => {
        return this.http
          .get(
            `${environment.onyxSLA}api/sla/dashboard/top-teams/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}?dateRange=${data.payload.dateRange}&product=${data.payload.product}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                if (
                  response.entity.entity &&
                  typeof response.entity !== 'string'
                ) {
                  this.store.dispatch(
                    ManageSLAActions.SaveTopFiveTeams({
                      payload: response.entity.entity,
                    })
                  );
                } else {
                  this.store.dispatch(
                    ManageSLAActions.SaveTopFiveTeams({
                      payload: [],
                    })
                  );
                }

                return {
                  type: '[Manage SLA] Get Top Five Teams Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Get Top Five Teams Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Top Five Teams'
              );
            })
          );
      })
    )
  );

  getTopFivePerformers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetTopFivePerformers),
      switchMap((data) => {
        return this.http
          .post(
            `${environment.onyxSLA}api/sla/dashboard/top-team/members?dateRange=${data.payload.dateRange}`,
            data.payload,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                if (
                  response.entity.entities &&
                  typeof response.entity !== 'string'
                ) {
                  this.store.dispatch(
                    ManageSLAActions.SaveTopFivePerformers({
                      payload: response.entity.entities,
                    })
                  );
                } else {
                  this.store.dispatch(
                    ManageSLAActions.SaveTopFivePerformers({
                      payload: [],
                    })
                  );
                }

                return {
                  type: '[Manage SLA] Get Top Five Performers Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Get Top Five Performers Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Top Five Performers'
              );
            })
          );
      })
    )
  );

  getSlaMetricsPercentageData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetSlaMetricsPercentageData),
      switchMap((data) => {
        return this.http
          .get(
            `${environment.onyxSLA}api/sla/dashboard/metrics-percentage/${data.payload.subscriberId}/${data.payload.ownerId}?dateRange=${data.payload.dateRange}&product=${data.payload.product}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                if (typeof response.entity !== 'string') {
                  this.store.dispatch(
                    ManageSLAActions.SaveSlaMetricsPercentageData({
                      payload: response.entity,
                    })
                  );
                } else {
                  this.store.dispatch(
                    ManageSLAActions.SaveSlaMetricsPercentageData({
                      payload: [],
                    })
                  );
                }

                return {
                  type: '[Manage SLA] Get SLA Metrics Percentage Data Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Get SLA Metrics Percentage Data Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get SLA Metrics Percentage Data'
              );
            })
          );
      })
    )
  );

  getSlaLineChartData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetSlaLineChartData),
      switchMap((data) => {
        return this.http
          .get(
            `${environment.onyxSLA}api/sla/dashboard/chart/line-chart/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.year}?dateRange=${data.payload.dateRange}&product=${data.payload.product}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                if (
                  response.entity.length &&
                  typeof response.entity !== 'string'
                ) {
                  this.store.dispatch(
                    ManageSLAActions.SaveSlaLineChartData({
                      payload: response.entity,
                    })
                  );
                } else {
                  this.store.dispatch(
                    ManageSLAActions.SaveSlaLineChartData({
                      payload: [],
                    })
                  );
                }

                return {
                  type: '[Manage SLA] Get SLA Line Chart Data Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Get SLA Line Chart Data Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get SLA Line Chart Data'
              );
            })
          );
      })
    )
  );

  getSlaDonutChartData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetSlaDonutChartData),
      switchMap((data) => {
        return this.http
          .get(
            `${environment.onyxSLA}api/sla/dashboard/chart/donut-chart/${data.payload.subscriberId}/${data.payload.dropdowntype}/${data.payload.ownerId}?dateRange=${data.payload.dateRange}&product=${data.payload.product}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                this.store.dispatch(
                  ManageSLAActions.SaveSlaDonutData({
                    payload: response.entity,
                  })
                );
                return {
                  type: '[Manage SLA] Get SLA Donut Chart Data Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Get SLA Donut Chart Data Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get SLA DonutChart Data'
              );
            })
          );
      })
    )
  );

  getSlaEscalationRateData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetEscalationRateData),
      switchMap((data) => {
        return this.http
          .get(
            `${environment.onyxSLA}api/sla/dashboard/escalation-rate/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.year}?dateRange=${data.payload.dateRange}&product=${data.payload.product}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                if (response.entity.escalationCounts) {
                  this.store.dispatch(
                    ManageSLAActions.SaveEscalationRateData({
                      payload: response.entity.escalationCounts,
                    })
                  );
                } else {
                  this.store.dispatch(
                    ManageSLAActions.SaveEscalationRateData({
                      payload: {},
                    })
                  );
                }

                return {
                  type: '[Manage SLA] Get SLA Escalation Rate Data Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Get SLA Escalation Rate Data Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get SLA Escalation Rate Data'
              );
            })
          );
      })
    )
  );

  getAverageCompletiontData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageSLAActions.GetAverageCompletionData),
      switchMap((data) => {
        return this.http
          .get(
            `${environment.onyxSLA}api/sla/dashboard/chart/average-completion-time/${data.payload.subscriberId}/${data.payload.year}/${data.payload.ownerId}?dateRange=${data.payload.dateRange}&product=${data.payload.product}`,
            { context: new HttpContext().set(USE_ACCESS_TOKEN, true) }
          )
          .pipe(
            map((response: any) => {
              if (response.succeeded === true) {
                if (typeof response.entity !== 'string') {
                  this.store.dispatch(
                    ManageSLAActions.SaveAverageCompletionData({
                      payload: response.entity,
                    })
                  );
                } else {
                  this.store.dispatch(
                    ManageSLAActions.SaveAverageCompletionData({
                      payload: [],
                    })
                  );
                }
                return {
                  type: '[Manage SLA] Get SLA Average Completion Rate Data Successful',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: response.message || response.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Manage SLA] Get SLA Average Completion Data Failed',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                '[Manage SLA][CatchError] Failed To Get SLA Average Completion Data'
              );
            })
          );
      })
    )
  );
}
