import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import * as AppActions from '../app/app.actions';

import * as fromAuth from '../auth/auth.reducer';
import * as fromUser from '../user/user.reducer';
import * as fromFeature from '../feature/feature.reducer';
import * as fromSubscription from '../subscription/subscription.reducer';
import * as fromSubscriptionPlan from '../subscription-plan/subscription-plan.reducer';
import * as fromSubscribers from '../subscribers/subscribers.reducer';
import * as fromGeneral from '../general/general.reducer';
import * as fromEditor from '../editor/editor.reducer';
import * as fromExternalVerification from '../external-verification/external-verification.reducer';
import * as fromPayment from '../payment/payment.reducer';
import * as fromUtilities from '../utilities/utilities.reducer';
import * as fromDashbaord from '../dashboard/dashboard.reducers';
import * as fromTransaction from '../transaction/transaction.reducers';
import * as fromDocument from '../document/document.reducer';
import * as fromAudit from '../audit/audit.reducer';
import * as fromReleaseNote from '../release-note/release-note.reducers';
import * as fromInbox from '../inbox/inbox.reducer';
import * as fromOrganization from '../organization/organization.reducer';
import * as fromProcessCategory from '../process-category/process-category.reducer';
import * as fromProcessWorkflow from '../process-workflow/process-workflow.reducer';
import * as fromApplicationManagement from '../application-management/application-management.reducer';
import * as fromDeveloperDashboard from '../developer-dashboard/developer-dashboard.reducers';
import * as fromAuditLog from '../audit-log/audit-log.reducer';
import * as fromDocumentation from '../documentation/documentation.reducer';
import * as fromProductConfiguration from '../product-configuration/product-configuration.reducer';

import * as fromContactCategory from '../contact-category/contact-category.reducer';
import * as fromDefaultRole from '../default-role/default-role.reducer';
import * as fromCheckout from '../checkout/checkout.reducer';
import * as fromUserLicense from '../user-license/user-license.reducer';
import * as fromUserRole from '../user-role/user-role.reducer';
import * as fromSigningRequest from '../signing-request/signing-request.reducer';
// import * as fromSLA from '../sla/sla.reducer';
import * as fromSigningRequestAudit from '../signing-request-audit/signing-request-audit.reducer';
import * as fromSigningRequestCategory from '../signing-request-category/signing-request-category.reducer';

import * as fromDrive from '../drive/drive.reducer';
import * as fromSLAConfiguration from '../sla-configuration/sla-configuration.reducer';
import * as fromCalendar from '../calendar/calendar.reducer';
import * as fromFormBuilder from '../form-builder/form-builder.reducer';
import * as fromFormbuilderDashboard from '../fmbr-dashboard/fmbr-dashboard.reducers';
import * as fromConditionBuilder from '../condition-builder/condition.reducer';
import * as fromManageSla from '../manage-sla/manage-sla.reducer';
import * as fromMyForms from '../my-forms/my-forms.reducer';
import * as fromResourceConfiguration from '../resource-configuration/resource-configuration.reducer';
import * as fromApiRepository from '../repository/api-repository/api-repository.reducer';
import * as fromRecipientSignature from '../recipient-signature/recipient-signature.reducer';
import * as fromFlowmonoAssistance from '../flowmono-assistant/flowmono-assistant.reducer';

/**
 * INSTRUCTIONS: Arrange each store in alphabetical order
 */
export interface AppState {
  apiRepository: fromApiRepository.State;
  applicationManagement: fromApplicationManagement.State;
  audit: fromAudit.State;
  auditLog: fromAuditLog.State;
  auth: fromAuth.authReducerState;
  checkout: fromCheckout.State;
  contactCategory: fromContactCategory.State;
  dashboard: fromDashbaord.State;
  defaultRole: fromDefaultRole.State;
  developerDashboard: fromDeveloperDashboard.State;
  document: fromDocument.State;
  documentation: fromDocumentation.State;
  drive: fromDrive.State;
  editor: fromEditor.State;
  externalVerification: fromExternalVerification.State;
  feature: fromFeature.State;
  formBuilder: fromFormBuilder.State;
  formbuilderDashboard: fromFormbuilderDashboard.State;
  flowmonoAssistance: fromFlowmonoAssistance.flowmonoAssistanceReducerState;
  general: fromGeneral.State;
  inbox: fromInbox.State;
  myForms: fromMyForms.State;
  organization: fromOrganization.organizationReducerState;
  payment: fromPayment.State;
  processCategory: fromProcessCategory.State;
  processWorkflow: fromProcessWorkflow.State;
  productConfiguration: fromProductConfiguration.State;
  resourceConfiguration: fromResourceConfiguration.resourceReducerState;
  signingRequest: fromSigningRequest.signingRequestReducerState;
  // sla: fromSLA.State;
  signingRequestAudit: fromSigningRequestAudit.State;
  signingRequestCategory: fromSigningRequestCategory.State;
  subscribers: fromSubscribers.State;
  subscription: fromSubscription.State;
  subscriptionPlan: fromSubscriptionPlan.State;
  transaction: fromTransaction.transactionReducerState;
  user: fromUser.userReducerState;
  userLicense: fromUserLicense.State;
  userRole: fromUserRole.userRoleReducerState;
  utilities: fromUtilities.State;
  allReleaseNotes: fromReleaseNote.State;
  configurations: fromSLAConfiguration.SLAConfigurationState;
  calendars: fromCalendar.State;
  conditionBuilder: fromConditionBuilder.State;
  manageSla: fromManageSla.ManageSLAState;
  recipientSignature: fromRecipientSignature.recipientReducerState;
}

export const appReducer: ActionReducerMap<AppState> = {
  apiRepository: fromApiRepository.apiRepositoryReducer,
  applicationManagement: fromApplicationManagement.applicationManagementReducer,
  audit: fromAudit.auditReducer,
  auditLog: fromAuditLog.auditLogReducer,
  auth: fromAuth.authReducer,
  checkout: fromCheckout.checkoutReducer,
  contactCategory: fromContactCategory.contactCategoryReducer,
  dashboard: fromDashbaord.dashboardReducer,
  defaultRole: fromDefaultRole.defaultRoleReducer,
  developerDashboard: fromDeveloperDashboard.developerDashboardReducer,
  document: fromDocument.documentReducer,
  documentation: fromDocumentation.documentationReducer,
  drive: fromDrive.driveReducer,
  editor: fromEditor.editorReducer,
  externalVerification: fromExternalVerification.externalVerificationReducer,
  feature: fromFeature.featuresReducer,
  formBuilder: fromFormBuilder.formBuilderReducer,
  formbuilderDashboard: fromFormbuilderDashboard.formbuilderDashboardReducer,
  general: fromGeneral.generalReducer,
  inbox: fromInbox.inboxReducer,
  myForms: fromMyForms.myFormsReducer,
  organization: fromOrganization.organizationReducer,
  payment: fromPayment.paymentReducer,
  processCategory: fromProcessCategory.processCategoryReducer,
  processWorkflow: fromProcessWorkflow.processWorkflowReducer,
  productConfiguration: fromProductConfiguration.productConfigurationReducer,
  resourceConfiguration: fromResourceConfiguration.resourceConfigurationReducer,
  flowmonoAssistance: fromFlowmonoAssistance.flowmonoAssistanceReducer,
  signingRequest: fromSigningRequest.signingRequestReducer,
  // sla: fromSLA.slaReducer,
  signingRequestAudit: fromSigningRequestAudit.signingRequestAuditReducer,
  signingRequestCategory:
    fromSigningRequestCategory.signingRequestCategoryReducer,
  subscribers: fromSubscribers.subscribersReducer,
  subscription: fromSubscription.subscriptionReducer,
  subscriptionPlan: fromSubscriptionPlan.subscriptionPlanReducer,
  transaction: fromTransaction.transactionReducer,
  user: fromUser.userReducer,
  userLicense: fromUserLicense.userLicenseReducer,
  userRole: fromUserRole.userRoleReducer,
  utilities: fromUtilities.utilitiesReducer,
  allReleaseNotes: fromReleaseNote.releaseNoteReducer,
  configurations: fromSLAConfiguration.slaConfigurationReducer,
  calendars: fromCalendar.calendarReducer,
  conditionBuilder: fromConditionBuilder.conditionBuilderReducer,
  manageSla: fromManageSla.manageSLAReducer,
  recipientSignature: fromRecipientSignature.recipientReducer,
};

export function resetStoreMetaReducer<State extends {}>(
  reducer: ActionReducer<State>
): ActionReducer<State> {
  return (state, action) => {
    if (action !== null && action.type === AppActions.ResetAllStores.type) {
      state = {} as State; // ==> Emptying state here
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [resetStoreMetaReducer];
