import { DocumentationEffects } from './@core/stores/documentation/documentation.effect';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, SecurityContext } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleModule } from '@ng-idle/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import { AuthModule } from './@auth/auth.module';
import { AppInterceptorService } from './@core/interceptors/app.interceptor.service';
import * as fromApp from './@core/stores/app/app.reducer';
import { AuthEffects } from './@core/stores/auth/auth.effects';
import { DocumentEffects } from './@core/stores/document/document.effects';
import { EditorEffects } from './@core/stores/editor/editor.effects';
import { GeneralEffects } from './@core/stores/general/general.effects';
import { PaymentEffects } from './@core/stores/payment/payment.effects';
import { SubscribersEffects } from './@core/stores/subscribers/subscribers.effects';
import { SubscriptionPlanEffects } from './@core/stores/subscription-plan/subscription-plan.effects';
import { TransactionEffects } from './@core/stores/transaction/transaction.effects';
import { DashboardEffects as DashboardEffects } from './@core/stores/dashboard/dashboard.effects';
import { AuditEffects } from './@core/stores/audit/audit.effects';
import { InboxEffects } from './@core/stores/inbox/inbox.effects';
import { UserEffects } from './@core/stores/user/user.effects';
import { FeatureEffects } from './@core/stores/feature/feature.effects';
import { UtilitiesEffects } from './@core/stores/utilities/utilities.effects';
import { SLAConfigurationEffects } from './@core/stores/sla-configuration/sla-configuration.effects';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProcessCategoryEffects } from './@core/stores/process-category/process-category.effects';
import { ProcessWorkflowEffects } from './@core/stores/process-workflow/process-workflow.effects';
import { RetryInterceptorService } from './@core/interceptors/retry.interceptor.service';
import { APP_INITIALIZER } from '@angular/core';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { ApplicationManagementEffects } from './@core/stores/application-management/application-management.effect';
import { AuditLogsEffects } from './@core/stores/audit-log/audit-log.effect';
import { DeveloperDashboardEffects } from './@core/stores/developer-dashboard/developer-dashboard.effects';
import { ProductConfigurationEffects } from './@core/stores/product-configuration/product-configuration.effects';
import { ContactCategoryEffects } from './@core/stores/contact-category/contact-category.effects';
import { PageNotFoundComponent } from './@components/page-not-found/page-not-found.component';
import { UnauthorizedPageComponent } from './@components/unauthorized-page/unauthorized-page.component';
import { SubscriptionEffects } from './@core/stores/subscription/subscription.effects';
import { PwaUpdateService } from './@core/services/pwa-update.service';
import { MatRippleModule } from '@angular/material/core';
// import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import {
  // provideAnalytics,
  // getAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DefaultRoleEffects } from './@core/stores/default-role/default-role.effects';
import { GeneralDateModule } from './@core/shared/general-date/general-date.module';
import { CheckoutEffects } from './@core/stores/checkout/checkout.effect';
import { CheckoutSuccessComponent } from './@components/checkout-success/checkout-success.component';
import { ButtonLoaderModule } from './@core/shared/loaders/button-loader/button-loader.module';
import { UserLicenseEffects } from './@core/stores/user-license/user-license.effects';
import { UserRoleEffects } from './@core/stores/user-role/user-role.effects';
import { OrganizationEffects } from './@core/stores/organization/organization.effects';
import { HotToastModule } from '@ngneat/hot-toast';
import { SigningRequestEffects } from './@core/stores/signing-request/signing-request.effects';
import { ResourceConfigurationEffects } from './@core/stores/resource-configuration/resource-configuration.effects';

import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  LogLevel,
  InteractionType,
} from '@azure/msal-browser';
import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalBroadcastService,
  MsalService,
  MsalGuard,
  MsalRedirectComponent,
  MsalModule,
  MsalInterceptor,
} from '@azure/msal-angular';
import { DriveEffects } from './@core/stores/drive/drive.effects';
import { FormControl } from '@angular/forms';
import { ReleaseNoteEffects } from './@core/stores/release-note/release-note.effects';
import { SigningRequestAuditEffects } from './@core/stores/signing-request-audit/signing-request-audit.effects';
import { SigningRequestCategoryEffects } from './@core/stores/signing-request-category/signing-request-category.effects';
import { CalendarEffects } from './@core/stores/calendar/calendar.effects';
import { FormBuilderEffects } from './@core/stores/form-builder/form-builder.effects';
import { FmbrDashboardEffects } from './@core/stores/fmbr-dashboard/fmbr-dashboard.effects';
import { ExternalVerificationEffects } from './@core/stores/external-verification/external-verification.effects';
import { ConditionBuilderEffects } from './@core/stores/condition-builder/condition.effects';
import { ManageSLAEffects } from './@core/stores/manage-sla/manage-sla.effects';
import { MyFormsEffects } from './@core/stores/my-forms/my-forms.effects';
import { APIRepositoryEffects } from './@core/stores/repository/api-repository/api-repository.effect';
import { RecipientSignatureEffects } from './@core/stores/recipient-signature/recipient-signature.effects';
import { FlowmonoAssistanceEffects } from './@core/stores/flowmono-assistant/flowmono-assistant.effects';

const initializeCheckForAppUpdates = (
  swUpdate: SwUpdate,
  pwaUpdateService: PwaUpdateService
): (() => Promise<any>) => {
  return (): Promise<any> => {
    return pwaUpdateService.checkForAppUpdates(swUpdate);
  };
};

const GRAPH_ENDPOINT = 'Enter_the_Graph_Endpoint_Herev1.0/me';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '0f2d1bc0-489a-472d-a7e3-c309c6fcad7d',
      authority: 'https://login.microsoftonline.com/common/',
      redirectUri: '/azure-active-directory',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(GRAPH_ENDPOINT, ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}

type SetValueArguments = [
  value: any,
  options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
    emitModelToViewChange?: boolean;
    emitViewToModelChange?: boolean;
  }
];

export function sanitizeInputFactory(sanitizer: DomSanitizer) {
  return () => {
    const originalSetValue = FormControl.prototype.setValue;

    FormControl.prototype.setValue = function (...args: SetValueArguments) {
      const value = args[0];
      const sanitizedValue: string | null = sanitizer.sanitize(
        SecurityContext.HTML,
        value
      );

      args[0] = sanitizedValue !== null ? sanitizedValue : '';

      return originalSetValue.apply(this, args);
    };
  };
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    UnauthorizedPageComponent,
    CheckoutSuccessComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    MsalModule,
    ButtonLoaderModule,
    StoreModule.forRoot(fromApp.appReducer, {
      metaReducers: fromApp.metaReducers,
    }),
    EffectsModule.forRoot([
      /**
       * INSTRUCTIONS: Arrange each effect in alphabetical order
       */
      APIRepositoryEffects,
      ApplicationManagementEffects,
      AuditEffects,
      ReleaseNoteEffects,
      AuditLogsEffects,
      AuthEffects,
      CheckoutEffects,
      ContactCategoryEffects,
      DashboardEffects,
      DefaultRoleEffects,
      DeveloperDashboardEffects,
      DocumentEffects,
      DocumentationEffects,
      DriveEffects,
      EditorEffects,
      FeatureEffects,
      FmbrDashboardEffects,
      GeneralEffects,
      InboxEffects,
      MyFormsEffects,
      OrganizationEffects,
      PaymentEffects,
      ProcessCategoryEffects,
      ProcessWorkflowEffects,
      ProductConfigurationEffects,
      ResourceConfigurationEffects,
      SigningRequestEffects,
      SigningRequestAuditEffects,
      SigningRequestCategoryEffects,
      SubscribersEffects,
      SubscriptionEffects,
      SubscriptionPlanEffects,
      TransactionEffects,
      UserEffects,
      UserLicenseEffects,
      UserRoleEffects,
      UtilitiesEffects,
      SLAConfigurationEffects,
      CalendarEffects,
      FormBuilderEffects,
      ExternalVerificationEffects,
      ConditionBuilderEffects,
      ManageSLAEffects,
      FormBuilderEffects,
      RecipientSignatureEffects,
      FlowmonoAssistanceEffects,
    ]),
    AuthModule,
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          logOnly: environment.production, //eslint-disable-line
        }),
    NgIdleModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000',
      registrationStrategy: 'registerImmediately',
    }),
    MatRippleModule,
    MatProgressBarModule,
    HotToastModule.forRoot(),
    AppRoutingModule,
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAnalytics(() => getAnalytics()),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    GeneralDateModule,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientID),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookClientID),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptorService,
      multi: true,
    },
    PwaUpdateService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeCheckForAppUpdates,
      deps: [
        SwUpdate,
        PwaUpdateService /* Add whatever dependency is required here */,
      ],
      multi: true,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: sanitizeInputFactory,
    //   deps: [DomSanitizer],
    //   multi: true,
    // },
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  exports: [],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
//eslint-disable-next-line
export class AppModule {}
