import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';

export interface userReducerState {
  isLoading: boolean;
  users: { users: any[]; total: number } | null;
  usersWithRequest: { users: any[]; total: number } | null;
  activeUsers: { users: any[]; total: number } | null;
  inactiveUsers: { users: any[]; total: number } | null;
  invitedUsers: { users: any[]; total: number } | null;
  rejectedUsers: { users: any[]; total: number } | null;
  licensedUsers: { users: any[]; total: number } | null;
  unlicensedUsers: { users: any[]; total: number } | null;
  permissions: any;
  response: any;
  features?: any;
}

export const initialState: userReducerState = {
  isLoading: false,
  users: null,
  inactiveUsers: null,
  activeUsers: null,
  invitedUsers: null,
  rejectedUsers: null,
  licensedUsers: null,
  unlicensedUsers: null,
  usersWithRequest: null,
  permissions: null,
  response: null,
  features: null,
};

const userReducerInternal = createReducer(
  initialState,
  on(UserActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(UserActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(UserActions.SaveUsers, (state, { payload }) => ({
    ...state,
    users: payload,
  })),

  on(UserActions.SaveActiveUsers, (state, { payload }) => ({
    ...state,
    activeUsers: payload,
  })),

  on(UserActions.SaveInactiveUsers, (state, { payload }) => ({
    ...state,
    inactiveUsers: payload,
  })),

  on(UserActions.SaveInvitedUsers, (state, { payload }) => ({
    ...state,
    invitedUsers: payload,
  })),

  on(UserActions.SaveRejectedUsers, (state, { payload }) => ({
    ...state,
    rejectedUsers: payload,
  })),

  on(UserActions.SaveLicensedUsers, (state, { payload }) => ({
    ...state,
    licensedUsers: payload,
  })),

  on(UserActions.SaveUnLicensedUsers, (state, { payload }) => ({
    ...state,
    unlicensedUsers: payload,
  })),

  on(UserActions.SaveUsersWithRequest, (state, { payload }) => ({
    ...state,
    usersWithRequest: payload,
  }))
);

export function userReducer(
  state: userReducerState | undefined,
  action: Action
) {
  return userReducerInternal(state, action);
}
