import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, share, concatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromApp from '../app/app.reducer';
import * as CalendarActions from './calendar.actions';
import { Notification } from 'src/app/@core/interfaces/index';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';
import { Subject } from 'rxjs';
import { Calendar } from '../../interfaces/calendar.interface';

@Injectable()
export class CalendarEffects {
  newCustomCalendar: Subject<Calendar> = new Subject();

  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private helperService: HelperService,
    private notificationService: NotificationService
  ) {}

  private handleCatchError = (errorRes: any, type: string) => {
    return this.helperService.handleErrorMessages(errorRes, type);
  };

  createCalendar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CalendarActions.CreateCalendar),
        switchMap((data) => {
          return this.http
            .post(`${environment.onyxCore}/calendar/create`, data.payload, {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            })
            .pipe(
              map((response: any) => {
                if (response.succeeded === true) {
                  this.store.dispatch(
                    CalendarActions.IsLoading({ payload: false })
                  );

                  this.store.dispatch(
                    CalendarActions.SaveLastCreatedCalendar({
                      payload: response.entity,
                    })
                  );

                  this.store.dispatch(
                    CalendarActions.GetAllCalendars({
                      payload: {
                        ownerId: response.entity.ownerId,
                        subscriberId: response.entity.subscriberId,
                        take: 100,
                        skip: 0,
                      },
                    })
                  );

                  this.store.dispatch(
                    CalendarActions.CreateSuccessful({
                      payload: true,
                    })
                  );

                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Calendar Created Successfully' || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success',
                    true
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error',
                    true
                  );

                  return {
                    type: '[Create Calendar] Create Calendar Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[Calendar][CatchError] Failed To Create Calendar'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  updateCalendar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CalendarActions.UpdateCalendar),
        switchMap((data) => {
          return this.http
            .post(`${environment.onyxCore}/calendar/update`, data.payload, {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            })
            .pipe(
              map((response: any) => {
                if (response.succeeded === true) {
                  this.store.dispatch(
                    CalendarActions.IsLoading({ payload: false })
                  );

                  this.store.dispatch(
                    CalendarActions.GetAllCalendars({
                      payload: {
                        ownerId: response.entity.ownerId,
                        subscriberId: response.entity.subscriberId,
                        take: 100,
                        skip: 0,
                      },
                    })
                  );

                  this.store.dispatch(
                    CalendarActions.CreateSuccessful({
                      payload: true,
                    })
                  );

                  const notification: Notification = {
                    state: 'success',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Update Calendar] Update Calendar Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[Calendar][CatchError] Failed To Update Calendar'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getAllCalendars$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CalendarActions.GetAllCalendars),
        switchMap((data) => {
          return this.http
            .get(
              `${environment.onyxCore}/calendar/calendars/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.skip}/${data.payload.take}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response: any) => {
                if (response.succeeded === true) {
                  this.store.dispatch(
                    CalendarActions.SaveAllCalendars({
                      payload: response.entity.calendars,
                    })
                  );

                  // const notification: Notification = {
                  //   state: 'success',
                  //   message:
                  //     'Calendars loaded successfully' || response.messages[0],
                  // };

                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Calendar] Get All Calendars Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[Calendar][CatchError] Failed To Get All Calendar'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getCalendarById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CalendarActions.GetCalendarById),
        concatMap((data) => {
          return this.http
            .get(
              `${environment.onyxCore}/calendar/${data.payload.subscriberId}/${data.payload.ownerId}/${data.payload.id}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((response: any) => {
                if (response.succeeded === true) {
                  this.store.dispatch(
                    CalendarActions.SaveCalendarById({
                      payload: response.entity,
                    })
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Calendar] Get CalendarById Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[Calendar][CatchError] Failed To Get CalendarById Failed'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  makeDefaultCalendars$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CalendarActions.MakeDefaultCalendar),
        switchMap((data) => {
          return this.http
            .post(`${environment.onyxCore}/calendar/setdefault`, data.payload, {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            })
            .pipe(
              map((response: any) => {
                if (response.succeeded === true) {
                  this.store.dispatch(
                    CalendarActions.SaveDefaultCalendar({
                      payload: response.entity,
                    })
                  );

                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Calendar successfully set to default' ||
                      response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Make Default Calendar] Setting Calendar to Default Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[Calendar][CatchError] Failed To Make Default Calendar'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  deactivateCalendars$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CalendarActions.DeactivateCalendar),
        switchMap((data) => {
          return this.http
            .post(`${environment.onyxCore}/calendar/deactivate`, data.payload, {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            })
            .pipe(
              map((response: any) => {
                if (response.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message:
                      'Calendar successfully deactivated' ||
                      response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: response.message || response.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Activate Calendar] Calendar Activation Failed',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  '[Calendar][CatchError] Failed To Deactivate Calendar'
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );
}
