import { createAction, props } from '@ngrx/store';

export const CreationStatus = createAction(
  '[SLA Configuration] Creation Status',
  props<{
    payload: boolean;
  }>()
);

export const IsLoading = createAction(
  '[SLA Configuration] SLA Configuration Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const GetAllProducts = createAction(
  '[SLA Configuration] Get All Products',
  props<{
    payload: {
      skip: number;
      take: number;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
    };
  }>()
);

export const SaveAllProducts = createAction(
  '[SLA Configuration] Save All Products',
  props<{
    payload: any;
  }>()
);

export const GetMicroservices = createAction(
  '[SLA Configuration] Get Microservices',
  props<{
    payload: {
      ownerId: string;
      subscriberId: number;
      productGuId: string;
    };
  }>()
);

export const SaveMicroservices = createAction(
  '[SLA Configuration] Save Microservices',
  props<{
    payload: any;
  }>()
);

export const GetTargetObjects = createAction(
  '[SLA Configuration] Get Target Objects',
  props<{
    payload: {
      ownerId: string;
      subscriberId: number;
      serviceGuId: string;
      serviceName: string;
    };
  }>()
);

export const SaveTargetObjects = createAction(
  '[SLA Configuration] Save Target Objects',
  props<{
    payload: any;
  }>()
);

export const GetAllConfigurations = createAction(
  '[SLA Configuration] Get All SLA Configurations',
  props<{
    payload: {
      ownerId: string;
      subscriberId: number;
      skip: number;
      take: number;
    };
  }>()
);

export const GetConfigurationById = createAction(
  '[SLA Configuration] Get SLA Configuration By Id',
  props<{
    payload: {
      ownerId: string;
      subscriberId: number;
      id: string;
    };
  }>()
);

export const CreateConfiguration = createAction(
  '[SLA Configuration] Create SLA Configuration',
  props<{
    payload: {
      productObjectName: string;
      productObjectGuId: string;
      targetObject: string;
      categoryUrl: string;
      processIsPreconfigured: boolean;
      allowSLAForTargetObject: boolean;
      allowUsersCreateSLAWhenProcessIsInitiated: boolean;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
    };
  }>()
);

export const UpdateConfiguration = createAction(
  '[SLA Configuration] Update SLA Configuration',
  props<{
    payload: {
      productObjectName: string;
      productObjectGuId: string;
      targetObject: string;
      categoryUrl: string;
      processIsPreconfigured: boolean;
      allowSLAForTargetObject: boolean;
      allowUsersCreateSLAWhenProcessIsInitiated: boolean;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
      slaBuilderGuId: string;
    };
  }>()
);

export const GetCurrentConfiguration = createAction(
  '[SLA Configuration] Edit Current SLA Configuration',
  props<{
    payload: string;
  }>()
);

export const ActivateConfiguration = createAction(
  '[SLA Configuration] Activate SLA Configuration',
  props<{
    payload: {
      slaBuilderGuId: string;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
    };
  }>()
);

export const DeactivateConfiguration = createAction(
  '[SLA Configuration] Deactivate SLA Configuration',
  props<{
    payload: {
      slaBuilderGuId: string;
      subscriberId: number;
      ownerId: string;
      actorEmail: string;
    };
  }>()
);

export const SaveAllConfigurations = createAction(
  '[SLA Configuration] Save All SLA Configurations',
  props<{
    payload: any;
  }>()
);
