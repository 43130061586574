export const environment = {
  production: true,
  AES_SecretKey: `#?t?v?VX?\r??z@????Vfk?u\u007f?^?\fms\u0011?`,
  googleClientID: '119815205192-bcea8f14rhf2a576ne3t6b8vlsp8a8ak.apps.googleusercontent.com',
  facebookClientID: '540239653739413',
  onyxDocAuthUrl: 'https://auth.flowmono.com/api',
  onyxDocAPIUrl: 'https://api-poc.flowmono.com/api',
  reventUtilityServiceUrl: 'https://revent-email.reventholdings.com/api',
  onyxDocDocumentUrl: 'https://document.flowmono.com/api',
  onyxDocCoreUrl: 'https://core.flowmono.com/api',
  onyxDocReminderUrl: 'https://reminder.flowmono.com/api',
  // onyxDocAlternateDocumentUrl:
  //   'https://document-serviceproxy.eastus.cloudapp.azure.com/api',
  // onyxDocAlternateDocumentUrl: 'https://asposeservice.azurewebsites.net/api',
  onyxDocAlternateDocumentUrl: 'https://api.flowmono.com/api',
  onyxDocFormBuilderUrl: 'https://formbuilder.flowmono.com/api',
  onyxDocPhoenixFormBuilderUrl: 'https://phoenix.flowmono.com/api',
  onyxSLA: 'https://sla.flowmono.com/',
  onyxCore: 'https://core.flowmono.com/api',
  onyxDocSubscriptionUrl: 'https://subscription.flowmono.com/api',
  onyxDocProcessUrl: 'https://process.flowmono.com/api',
  onyxDocProcessWorkflowUrl: 'https://processworkflow.flowmono.com/api',
  onyxDocProductUrl: 'https://product.flowmono.com/api',
  onyxDocFlowmonoAssistantUrl: 'https://ai.flowmono.com/api',

  onyxDocConditionBuilderUrl: 'https://conditionbuilder.flowmono.com/api',
  onyxDocLiveBaseUrl: 'https://flowmono.com',
  onyxDocAdminUserId: '2a44179a-b46d-4b2a-b1a2-48d6ed488fe6',
  onyxDocAdminSubscriberId: 1,
  api_ipapi_access_token: '525a1f42c01d96851600f24f8ff4ca8b',
  abstractApiKey: '14b75dc13daf4ff1ba36bcb7c47d19ab',
  onyxDocAuth: {
    name: 'OnyxDocWeb',
  },
  onyxDocAudit: 'https://onyxdoc',
  onyxDocDriveUrl: 'https://drive.flowmono.com/api',
  documentNameGUID: '2e826b46-8971-46ce-a7c0-e7077930cb9c',
  firebase: {
    projectId: 'flowmono-signup',
    appId: '1:1065152333469:web:10327abfafdbc7ea56b674',
    storageBucket: 'flowmono-signup.appspot.com',
    apiKey: 'AIzaSyAcvzqqH_Vdpta1kbnZ2XeJnSwsmHu1Q7U',
    authDomain: 'flowmono-signup.firebaseapp.com',
    messagingSenderId: '1065152333469',
    measurementId: 'G-YGHG3M13MB',
  },
  paystack: {
    fixedFee: 100,
    localTransactionPercent: 1.5,
    foreignTransactionPercentage: 3.9,
    waiverAmount: 2500,
    decimalFee: 0,
    flatFee: 0,
  },
};
