import {
  FmbrFillableControlTypeEnum,
  FmbrLayoutControlTypeEnum,
  FmbrPageOrientationEnum,
  FmbrPageSizeEnum,
} from '../enums';
import { DynamicForm } from '../interfaces';
import { FmbrOptionSource } from '../types';

export const FmbrPageSizeDimensionDefaultDetails = {
  [FmbrPageSizeEnum.Letter]: {
    [FmbrPageOrientationEnum.Portrait]: {
      // width: '100%',
      width: '816px',
      height: '1056px',
      marginTop: '65.28px',
      marginLeft: '50.88px',
      marginBottom: '65.28px',
      marginRight: '50.88px',
    },
    [FmbrPageOrientationEnum.Landscape]: {
      // width: '100%',
      width: '1056px',
      // height: '650px',
      height: '816px',
      marginTop: '65.28px',
      marginLeft: '50.88px',
      marginBottom: '65.28px',
      marginRight: '50.88px',
    },
  },

  [FmbrPageSizeEnum.A4]: {
    [FmbrPageOrientationEnum.Portrait]: {
      // width: '100%',
      width: '2480px',
      height: '3508px',
      marginTop: '65.28px',
      marginLeft: '50.88px',
      marginBottom: '65.28px',
      marginRight: '50.88px',
    },
    [FmbrPageOrientationEnum.Landscape]: {
      // width: '100%',
      width: '3508px',
      height: '2480px',
      marginTop: '65.28px',
      marginLeft: '50.88px',
      marginBottom: '65.28px',
      marginRight: '50.88px',
    },
  },
};

export const FmbrLayoutControlTypeDefaultDimensions = {
  [FmbrLayoutControlTypeEnum.Section]: {
    width: '100%',
    height: '100px',
  },

  [FmbrLayoutControlTypeEnum.Grid]: {
    width: '100%',
    height: '100px',
  },

  [FmbrLayoutControlTypeEnum.Image]: {
    width: '100%',
    height: '100px',
  },
};

export const FmbrFillableControlTypeDefaultDimensions = {
  [FmbrFillableControlTypeEnum.Text]: {
    width: '14.375rem',
    height: '1.75rem',
  },

  // [FmbrFillableControlTypeEnum.Signature]: {
  //   width: '132px',
  //   height: '48px',
  // },

  [FmbrFillableControlTypeEnum.Email]: {
    width: '230px',
    height: '27.98px',
  },

  [FmbrFillableControlTypeEnum.Name]: {
    width: '230px',
    height: '27.98px',
  },

  [FmbrFillableControlTypeEnum.Date]: {
    width: '230px',
    height: '27.98px',
  },

  [FmbrFillableControlTypeEnum.Select]: {
    width: '230px',
    height: '27.98px',
  },

  [FmbrFillableControlTypeEnum.PhoneNumber]: {
    width: '260px',
    height: '27.98px',
  },

  [FmbrFillableControlTypeEnum.Address]: {
    width: '400px', //formerly 37.5rem
    height: '27.98px', //formerly 1.75rem
  },
};

export const ThemesAndColors: { themes: string[]; themeColors: DynamicForm } = {
  themes: [
    'theme1',
    'theme2',
    'theme3',
    'theme4',
    'theme5',
    'theme6',
    'theme7',
    'theme8',
  ],
  themeColors: {
    theme1: '#4e33ff',
    theme2: '#fb8500',
    theme3: '#be0424',
    theme4: '#906e18',
    theme5: '#208b3a',
    theme6: '#094cf4',
    theme7: '#6c27a7',
    theme8: '#222222',
  },
};

export const FmbrOptionSources: { name: string; value: FmbrOptionSource }[] = [
  { name: 'Manual Input', value: 'manual input' },
  {
    name: 'Import from File',
    value: 'import from file',
  },
  { name: 'Import from API', value: 'import from api' },
  { name: 'Import from URL', value: 'import from url' },
];
