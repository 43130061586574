<section id="linkedin-response">
  <div class="wallpaper-container">
    <div class="wallpaper-illustrations-container">
      <img
        src="/assets/images/auth/auth-wallpaper-illustration-left.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-left"
      />

      <img
        src="/assets/images/auth/auth-wallpaper-illustration-right.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-right"
      />
    </div>
  </div>

  <div class="page">
    <div class="page-inner">
      <div class="body">
        <div class="logo-container">
          <a routerLink="/">
            <img
              src="/assets/logo/flowmono-logo-white.svg"
              alt="Flowmono Logo White"
              class="logo"
            />
          </a>
        </div>

        <div class="content-section">
          <div class="form-section">
            <div class="linkedin-response-image-container">
              <img
                src="/assets/images/auth/welcome-onboard.svg"
                alt="Verify Email"
              />
            </div>

            <h5 class="flwmn-heading-5 fw-700 header-text">
              Verifying LinkedIn Login Details
            </h5>

            <p class="flwmn-caption subheader-text">
              Please wait while we verify your LinkedIn Login Details. This may
              take a few seconds
            </p>
          </div>
        </div>
      </div>

      <footer>
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>
  </div>
</section>

<!-- <ng-container *ngIf="(isLoading | async) !== false">
  <div class="spinner">
    <app-general-loader></app-general-loader>
  </div>
</ng-container>

<section id="email-confirmation">
  <header class="container-fluid">
    <nav>
      <a routerLink="/" class="brand">
        <svg
          width="180"
          height="38"
          viewBox="0 0 180 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_7265_33563)">
            <path
              d="M18.9692 29.6058C16.0582 29.6058 13.4228 28.4257 11.5152 26.5184C9.60795 24.6112 8.42747 21.9754 8.42747 19.0644C8.42747 16.1542 9.60795 13.518 11.5152 11.6111C13.4224 9.70319 16.0582 8.52343 18.9692 8.52343C21.8794 8.52343 24.5156 9.70355 26.4225 11.6111C28.3304 13.518 29.5102 16.1542 29.5102 19.0644H27.3234C27.3234 16.7572 26.3882 14.6686 24.8766 13.1566C23.365 11.645 21.276 10.7098 18.9688 10.7098C16.6616 10.7098 14.5722 11.645 13.061 13.1566C11.5494 14.6686 10.6142 16.7572 10.6142 19.0644C10.6142 21.3716 11.5494 23.461 13.061 24.9722C14.5726 26.4838 16.6616 27.419 18.9688 27.419V29.6058H18.9692ZM18.034 0.0238462C23.2698 -0.236559 28.1191 1.65156 31.7243 4.91491C35.3303 8.17863 37.6913 12.8179 37.9517 18.0578C38.2121 23.2937 36.324 28.1429 33.0606 31.7482C29.7969 35.3538 25.1576 37.7148 19.9177 37.9755C14.6819 38.2359 9.83301 36.3478 6.22737 33.0844C2.62174 29.8207 0.260781 25.1814 1.52588e-05 19.9419L2.17811 19.8391C2.40894 24.477 4.49795 28.5825 7.68809 31.4701C10.8782 34.3576 15.1731 36.0286 19.8153 35.7978C24.4536 35.5669 28.5591 33.4779 31.4463 30.2874C34.3338 27.0973 36.0048 22.8024 35.774 18.1606C35.5431 13.5223 33.4541 9.41682 30.264 6.52964C27.0738 3.64138 22.7782 1.97039 18.1367 2.20194L18.034 0.0238462ZM33.9143 19.0644C33.9143 23.1916 32.2415 26.9274 29.5365 29.6325C26.8322 32.3368 23.0956 34.0096 18.9688 34.0096C14.8417 34.0096 11.1058 32.3368 8.40078 29.6325C5.69646 26.9274 4.02367 23.1916 4.02367 19.0644C4.02367 14.938 5.69646 11.2014 8.40078 8.49674C11.1058 5.79171 14.8417 4.11891 18.9688 4.11891V6.30566C15.4454 6.30566 12.2553 7.73356 9.94662 10.0422C7.63796 12.3512 6.2097 15.541 6.2097 19.0644C6.2097 22.5878 7.63796 25.778 9.94662 28.0866C12.2553 30.3953 15.4454 31.8232 18.9688 31.8232C22.4922 31.8232 25.682 30.3953 27.991 28.0866C30.2997 25.778 31.7276 22.5878 31.7276 19.0644H33.9143Z"
              fill="url(#paint0_linear_7265_33563)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.9709 13.1528C22.236 13.1528 24.8834 15.8002 24.8834 19.0653C24.8834 22.3308 22.236 24.9782 18.9709 24.9782C15.7054 24.9782 13.058 22.3308 13.058 19.0653C13.058 15.8002 15.7054 13.1528 18.9709 13.1528Z"
              fill="#4E33FF"
            />
            <path
              d="M46.3943 30.83V13.0016C46.3943 11.5643 46.6832 10.3986 47.266 9.50957C47.8492 8.61546 48.5442 7.98465 49.3547 7.61965C50.1626 7.25501 50.9669 7.0249 51.7538 7.0249H55.9315V9.87781H52.5354C50.6112 9.87781 49.3828 10.7521 49.3828 12.5212V14.5305H55.5182V17.3827H49.3828V30.8303H46.3943V30.83Z"
              fill="#4E33FF"
            />
            <path
              d="M57.1355 7.0249H60.1237V30.8303H57.1355V7.0249Z"
              fill="#4E33FF"
            />
            <path
              d="M62.6526 22.4701C62.6753 20.9131 63.0944 19.4985 63.9037 18.2362C64.7189 16.9681 65.7713 15.9777 67.0733 15.2646C68.3696 14.5462 69.6997 14.1895 71.0631 14.1895C72.4783 14.2176 73.842 14.5519 75.1498 15.2022C76.463 15.8478 77.5321 16.7982 78.3699 18.0605C79.2078 19.3229 79.6269 20.8172 79.6269 22.5487C79.6269 23.8508 79.3206 25.1582 78.7043 26.4656C78.0929 27.7731 77.1815 28.8648 75.9704 29.7481C74.7538 30.6364 73.3277 31.1064 71.6802 31.1684C70.3165 31.1684 69.0768 30.9423 67.9617 30.4951C66.8472 30.0424 65.8903 29.4141 65.0871 28.6105C64.2832 27.8066 63.6718 26.8952 63.2531 25.8767C62.8286 24.852 62.6078 23.766 62.5797 22.6111C62.6136 22.5826 62.636 22.5603 62.6417 22.5321C62.6475 22.5037 62.6529 22.4813 62.6529 22.4701H62.6526ZM65.7713 22.4077C65.7713 23.9867 66.2073 25.3677 67.0733 26.545C67.9393 27.728 69.3373 28.3166 71.2726 28.3166C72.2742 28.3166 73.1629 28.1186 73.9387 27.7222C74.7192 27.3201 75.3367 26.6694 75.7893 25.7695C76.2423 24.8635 76.4685 23.6978 76.4685 22.2717C76.4006 20.6981 75.8741 19.43 74.8891 18.4739C73.9044 17.5174 72.6536 17.042 71.1428 17.042C69.9598 17.0647 68.9697 17.3421 68.1716 17.8798C67.373 18.4172 66.779 19.0852 66.3942 19.9003C66.0036 20.71 65.7994 21.5417 65.7713 22.4077Z"
              fill="#4E33FF"
            />
            <path
              d="M81.5752 14.5298H84.5638V25.1252C84.5638 25.9854 84.8123 26.7324 85.3103 27.3661C85.8084 28.0001 86.5103 28.3172 87.4159 28.3172C89.51 28.3172 90.5852 27.0949 90.6421 24.6553V14.5301H93.6981V24.916C93.7602 27.1854 94.8469 28.3175 96.9582 28.3175C97.8238 28.3175 98.5199 27.9951 99.035 27.3499C99.55 26.6988 99.8108 25.7762 99.8108 24.5767V14.5305H102.799V25.9068C102.748 26.9484 102.437 27.8703 101.854 28.6742C101.277 29.4782 100.552 30.0949 99.6864 30.5249C98.8204 30.9551 97.9825 31.1701 97.162 31.1701C95.9851 31.1701 95.0341 30.9944 94.2986 30.6496C93.5683 30.3041 92.861 29.7216 92.1761 28.9007C91.0555 30.412 89.4707 31.1701 87.4163 31.1701C86.0353 31.1308 84.9089 30.7964 84.026 30.1685C83.1431 29.5344 82.5144 28.7933 82.1415 27.9331C81.7621 27.0725 81.5752 26.2747 81.5752 25.5328V14.5305V14.5298Z"
              fill="#4E33FF"
            />
            <path
              d="M105.392 30.8315V19.6254C105.42 18.6747 105.68 17.7802 106.173 16.9536C106.665 16.1215 107.373 15.4535 108.295 14.95C109.218 14.4465 110.288 14.1919 111.504 14.1919C113.333 14.2427 114.833 15.0128 115.993 16.4955C116.434 15.8445 117.085 15.2956 117.94 14.8541C118.795 14.4126 119.785 14.1919 120.917 14.1919C121.902 14.1919 122.841 14.435 123.719 14.9273C124.601 15.4142 125.303 16.0764 125.829 16.9142C126.356 17.7463 126.616 18.652 126.616 19.6254V30.8315H123.628V20.5422C123.628 19.6593 123.402 18.8615 122.954 18.1535C122.502 17.4405 121.755 17.0722 120.707 17.0441C119.558 17.0441 118.738 17.3896 118.251 18.0738C117.758 18.7645 117.515 19.5854 117.515 20.5419V30.8311H114.459V20.7399C114.459 19.602 114.204 18.7025 113.689 18.0403C113.18 17.3781 112.432 17.0441 111.442 17.0441C110.231 17.0722 109.416 17.4405 109.003 18.1535C108.589 18.8611 108.38 19.7949 108.38 20.9494V30.8311H105.392L105.392 30.8315Z"
              fill="#4E33FF"
            />
            <path
              d="M128.465 22.4701C128.488 20.9131 128.907 19.4985 129.717 18.2362C130.532 16.9681 131.584 15.9777 132.886 15.2646C134.182 14.5462 135.513 14.1895 136.876 14.1895C138.291 14.2176 139.655 14.5519 140.963 15.2022C142.276 15.8478 143.345 16.7982 144.183 18.0605C145.021 19.3229 145.44 20.8172 145.44 22.5487C145.44 23.8508 145.133 25.1582 144.517 26.4656C143.906 27.7731 142.994 28.8648 141.783 29.7481C140.566 30.6364 139.141 31.1064 137.493 31.1684C136.129 31.1684 134.89 30.9423 133.774 30.4951C132.66 30.0424 131.703 29.4141 130.9 28.6105C130.096 27.8066 129.485 26.8952 129.066 25.8767C128.641 24.852 128.421 23.766 128.393 22.6111C128.426 22.5826 128.449 22.5603 128.455 22.5321C128.46 22.5037 128.466 22.4813 128.466 22.4701H128.465ZM131.584 22.4077C131.584 23.9867 132.02 25.3677 132.886 26.545C133.752 27.728 135.15 28.3166 137.085 28.3166C138.087 28.3166 138.976 28.1186 139.751 27.7222C140.532 27.3201 141.15 26.6694 141.602 25.7695C142.055 24.8635 142.281 23.6978 142.281 22.2717C142.213 20.6981 141.687 19.43 140.702 18.4739C139.717 17.5174 138.466 17.042 136.956 17.042C135.773 17.0647 134.782 17.3421 133.984 17.8798C133.186 18.4172 132.592 19.0852 132.207 19.9003C131.816 20.71 131.612 21.5417 131.584 22.4077Z"
              fill="#4E33FF"
            />
            <path
              d="M147.253 30.8297V20.8798C147.253 19.6745 147.558 18.5593 148.175 17.5404C148.792 16.5157 149.641 15.701 150.728 15.0896C151.809 14.4837 152.997 14.1613 154.282 14.1216C155.205 14.1216 156.206 14.3596 157.293 14.8292C158.374 15.3046 159.302 16.0685 160.078 17.1271C160.853 18.1856 161.244 19.527 161.244 21.1514V30.8293H158.255V21.2192C158.255 20.0871 157.881 19.1025 157.14 18.2704C156.393 17.4383 155.442 17.008 154.282 16.9741C153.172 16.9741 152.233 17.3647 151.463 18.146C150.694 18.9264 150.308 19.8606 150.308 20.9473V30.829H147.252L147.253 30.8297Z"
              fill="#4E33FF"
            />
            <path
              d="M162.896 22.4701C162.919 20.9131 163.338 19.4985 164.147 18.2362C164.962 16.9681 166.015 15.9777 167.317 15.2646C168.613 14.5462 169.943 14.1895 171.306 14.1895C172.722 14.2176 174.085 14.5519 175.393 15.2022C176.706 15.8478 177.775 16.7982 178.613 18.0605C179.451 19.3229 179.87 20.8172 179.87 22.5487C179.87 23.8508 179.564 25.1582 178.947 26.4656C178.337 27.7731 177.425 28.8648 176.214 29.7481C174.997 30.6364 173.571 31.1064 171.923 31.1684C170.56 31.1684 169.32 30.9423 168.205 30.4951C167.09 30.0424 166.134 29.4141 165.33 28.6105C164.526 27.8066 163.915 26.8952 163.496 25.8767C163.072 24.852 162.851 23.766 162.823 22.6111C162.857 22.5826 162.879 22.5603 162.885 22.5321C162.891 22.5037 162.896 22.4813 162.896 22.4701H162.896ZM166.015 22.4077C166.015 23.9867 166.451 25.3677 167.317 26.545C168.183 27.728 169.581 28.3166 171.516 28.3166C172.518 28.3166 173.406 28.1186 174.182 27.7222C174.962 27.3201 175.58 26.6694 176.033 25.7695C176.485 24.8635 176.712 23.6978 176.712 22.2717C176.644 20.6981 176.117 19.43 175.132 18.4739C174.148 17.5174 172.897 17.042 171.386 17.042C170.203 17.0647 169.212 17.3421 168.415 17.8798C167.616 18.4172 167.022 19.0852 166.637 19.9003C166.247 20.71 166.043 21.5417 166.015 22.4077Z"
              fill="#4E33FF"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_7265_33563"
              x1="-13.2976"
              y1="16.3363"
              x2="34.3179"
              y2="47.7526"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#E413FF" />
              <stop offset="1" stop-color="#4E33FF" />
            </linearGradient>
            <clipPath id="clip0_7265_33563">
              <rect width="179.871" height="38" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </a>
    </nav>
  </header>

  <div class="container content">
    <svg
      class="img-fluid image"
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78 69.3334C78 74.1196 74.1195 78.0001 69.3333 78.0001H8.66667C3.8805 78.0001 0 74.1196 0 69.3334V30.3334C0 25.5472 3.8805 21.6667 8.66667 21.6667H69.3333C74.1195 21.6667 78 25.5472 78 30.3334V69.3334Z"
        fill="#CCD6DD"
      />
      <path
        d="M25.8915 49.0446L1.38 73.5561C1.3215 73.6167 1.29983 73.6926 1.25 73.7532C1.98667 74.9882 3.01367 76.0131 4.24867 76.7519C4.3115 76.7021 4.38517 76.6804 4.44367 76.6219L28.9573 52.1082C29.8023 51.2611 29.8023 49.8917 28.9573 49.0446C28.108 48.1974 26.7387 48.1974 25.8915 49.0446ZM76.7497 73.7532C76.7042 73.6926 76.6782 73.6167 76.6197 73.5582L52.1103 49.0446C51.261 48.1974 49.8917 48.1974 49.0445 49.0446C48.1973 49.8939 48.1973 51.2632 49.0445 52.1082L73.556 76.6219C73.6123 76.6782 73.6903 76.7021 73.751 76.7519C74.9882 76.0152 76.013 74.9882 76.7497 73.7532Z"
        fill="#99AAB5"
      />
      <path
        d="M69.3333 21.6667H8.66667C3.8805 21.6667 0 25.5472 0 30.3334V32.5651L31.4773 63.9731C35.581 68.0767 42.2847 68.0767 46.3927 63.9731L78 32.5196V30.3334C78 25.5472 74.1195 21.6667 69.3333 21.6667Z"
        fill="#99AAB5"
      />
      <path
        d="M69.3328 21.6667H8.66612C5.22545 21.6667 2.27662 23.6904 0.876953 26.5959L32.87 58.5911C36.2543 61.9754 41.7446 61.9754 45.1268 58.5911L77.122 26.5959C75.7223 23.6904 72.7756 21.6667 69.3328 21.6667Z"
        fill="#E1E8ED"
      />
      <path
        d="M57.8848 15.1667H47.6668V4.33333C47.6668 1.94133 45.7276 0 43.3335 0H34.6668C32.2748 0 30.3334 1.94133 30.3334 4.33333V15.1667H19.8208C17.1709 15.1667 16.5383 16.5187 18.4103 18.3928L35.4468 35.4293C37.321 37.3035 40.3868 37.3035 42.2609 35.4293L59.2974 18.3928C61.1694 16.5187 60.5368 15.1667 57.8848 15.1667Z"
        fill="#DB0A68"
      />
    </svg>

    <h1 class="header">Verifying LinkedIn Login Details</h1>

    <p class="text">
      Please wait while we verify your LinkedIn Login Details. This may take a
      few seconds
    </p>
  </div>

  <footer>
    <p class="footer-text">
      &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
      Technologies.
    </p>
  </footer>
</section> -->
