import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
} from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  share,
  mergeMap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
// import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
// import * as GeneralActions from 'src/app/@core/stores/general/general.actions';
import * as fromApp from '../app/app.reducer';
import * as TransactionActions from './transaction.actions';
// import { GenericResponse } from 'src/app/@core/interfaces/index';
import { Notification } from '../../interfaces';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';

@Injectable()
export class TransactionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  //   Error handler
  // private handleError = (errorRes: any) => {
  //   this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

  //   // this.store.dispatch(
  //   //   GeneralActions.SetNotification({
  //   //     payload: {
  //   //       isSuccess: false,
  //   //       message:
  //   //         'Connection error! If error persists, please contact support',
  //   //     },
  //   //   })
  //   // );
  //   return of({ type: '[Subscription Plan] Error' });
  // };

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(TransactionActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  // getAllTransaction$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(TransactionActions.GetAllTransaction),
  //     withLatestFrom(this.store.select('auth')),
  //     switchMap(([susbscriptionData, authState]) => {
  //       return this.http
  //         .get<GenericResponse>(
  //           `${environment.onyxDocSubscriptionUrl}/Subscriptions/getallsubscriptions/${authState.user.SubscriberId}/${authState.user.UserId}`
  //         )
  //         .pipe(
  //           map((resData) => {
  //             this.store.dispatch(GeneralActions.IsLoading({ payload: false }));

  //             if (resData.succeeded === true) {
  //               return TransactionActions.SaveAllTransactions({
  //                 payload: resData.entity.reverse(),
  //               });
  //               // return ascendingSort(resData.entity, 'name');
  //             } else {
  //               // this.store.dispatch(
  //               //   GeneralActions.SetNotification({
  //               //     payload: {
  //               //       isSuccess: false,
  //               //       message: resData.message || resData.messages[0],
  //               //     },
  //               //   })
  //               // );

  //               return {
  //                 type: '[Transaction] Failed To Get All Transactions',
  //               };
  //             }
  //           }),
  //           catchError((errorRes) => {
  //             return this.handleCatchError(
  //               errorRes,
  //               `[Transaction] Failed To Get All Transactions ${errorRes.message}`
  //             );
  //           })
  //         );
  //     })
  //   )
  // );

  getTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.GetAllTransactions),
      withLatestFrom(this.store.select('auth')),
      switchMap(([transactionData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocProductUrl}/Transaction/transactions/${authState.user.SubscriberId}/${authState.user.UserId}/${transactionData?.payload?.skip}/${transactionData?.payload?.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                TransactionActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return TransactionActions.SaveAllTransactions({
                  payload: {
                    transaction: resData.entity.transaction,
                    totalCount: resData.entity.count,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return TransactionActions.SaveAllTransactions({
                  payload: {
                    // transaction: resData.entity.transaction,
                    // totalCount: resData.entity.count,
                    transaction: [],
                    totalCount: 0,
                  },
                });

                // return {
                //   type: '[Transaction] Failed To Get Transactions',
                // };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Transaction] Failed To Get Transactions ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  addTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.AddTransaction),
      withLatestFrom(this.store.select('auth')),
      switchMap(([transactionData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Transaction/create`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              // productId: transactionData.payload.productId,
              // subscriptionPlanId: transactionData.payload.subscriptionPlanId,
              ...transactionData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                TransactionActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  TransactionActions.GetAllTransactions({
                    payload: {
                      skip: 0,
                      take: 10,
                    },
                  })
                );

                return {
                  type: '[Transaction] Transaction Was Added Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Transaction] Failed To Add Transactions',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Transaction] Failed To Add Transactions ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getAllSubscriptionPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TransactionActions.GetAllSubscriptionPlan),
        withLatestFrom(this.store.select('auth')),
        switchMap(([transactionData, authState]) => {
          return this.http
            .get(
              `${environment.onyxDocProductUrl}/SubscriptionPlans/getsubscriptionplans/${authState.user.SubscriberId}/${authState.user.UserId}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                if (resData.succeeded === true) {
                  this.store.dispatch(
                    TransactionActions.SaveAllSubscriptionPlan({
                      payload: resData.entity,
                    })
                  );

                  // const notification: Notification = {
                  //   state: 'success',
                  //   message:
                  //     'Calendars loaded successfully' || response.messages[0],
                  // };

                  // this.notificationService.openNotification(
                  //   notification,
                  //   'flwmn-notification-success'
                  // );
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[Transaction] Failed To Get All Subscription Plan',
                  };
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Transaction] Failed To Get All Subscription Plan ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  getTransactionById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TransactionActions.GetTransactionById),
        withLatestFrom(this.store.select('auth')),
        switchMap(([transactionData, authState]) => {
          return this.http
            .get(
              `${environment.onyxDocProductUrl}/Transaction/transactionbyid/${authState.user.SubscriberId}/${transactionData?.payload?.guId}/${authState.user.UserId}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  TransactionActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  // this.store.dispatch({
                  //   type: '[Resource Configuration] Get Resource By Id Was Successful',
                  // });
                  return TransactionActions.SaveTransactionById({
                    payload: resData.data,
                  });
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  // this.store.dispatch({
                  //   type: '[Resource Configuration] Failed To Get Resource By Id',
                  // });
                }

                return {
                  type: '[Transaction] Failed To Get Transaction By Id',
                };
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Transaction][CatchError] Failed To Get Transaction By Id ${errorRes.message}`
                );
              })
            );
        }),
        share()
      ),
    { dispatch: false }
  );

  checkTransactionEmail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TransactionActions.CheckTransactionEmail),
        switchMap((transactionData) => {
          return this.http
            .post<any>(`${environment.onyxDocAuthUrl}/Subscribers/checkemail`, {
              contactEmail: transactionData.payload.contactEmail,
            })
            .pipe(
              map((resData) => {
                this.store.dispatch(
                  TransactionActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === false) {
                  this.store.dispatch({
                    type: '[Transaction] Check Transaction Email Was Successful',
                  });
                } else {
                  this.store.dispatch({
                    type: '[Transaction] Check Transaction Email Was Failed',
                  });
                }

                return resData;
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[Transaction][CatchError]  Check Transaction Email Was Failed${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  getTransactionProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionActions.GetTransactionProducts),
      withLatestFrom(this.store.select('auth')),
      mergeMap(([transactionData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Product/products/`,
            {
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              ...transactionData.payload,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                TransactionActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return TransactionActions.SaveTransactionProducts({
                  payload: {
                    products: resData.entity.product,
                    totalCount: resData.entity.count,
                  },
                });
              } else {
                return TransactionActions.SaveTransactionProducts({
                  payload: {
                    products: [],
                    totalCount: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Transaction][CatchError] Failed To Get Transaction Products ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getTransactionActiveProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TransactionActions.GetTransactionActiveProducts),
      withLatestFrom(this.store.select('auth')),
      switchMap(([productConfigurationData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocProductUrl}/Product/activeproducts/`,
            {
              ...productConfigurationData.payload,
              subscriberId: authState.user.SubscriberId,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                TransactionActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                // return TransactionActions.SaveActiveProductsForSelectField({
                //   payload: {
                //     products: resData.entity.product,
                //     totalCount: resData.entity.count,
                //   },
                // });
                return TransactionActions.SaveTransactionActiveProducts({
                  payload: {
                    products: resData.entity.product,
                    totalCount: resData.entity.count,
                  },
                });
              } else {
                return TransactionActions.SaveTransactionActiveProducts({
                  payload: {
                    products: [],
                    totalCount: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Transaction][CatchError] Failed To Get Transaction Active Products ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getSubscriberByEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.GetSubscriberByEmail),
      withLatestFrom(this.store.select('auth')),
      switchMap(([transactionData, authState]) => {
        return this.http
          .get(
            `${environment.onyxDocAuthUrl}/Subscribers/getactivesubscribers/${authState.user.UserId}/${authState.user.Email}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                TransactionActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return TransactionActions.SaveSubscriberEmail({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[Transaction] Failed To Get Subscriber Email',
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Transaction] FFailed To Get Subscriber Email ${errorRes.message}`
              );
            })
          );
      })
    )
  );

  getActiveSubscriber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionActions.GetActiveSubscriber),
      withLatestFrom(this.store.select('auth')),
      switchMap(([transactionData, authState]) => {
        return this.http
          .post<any>(
            `${environment.onyxDocAuthUrl}/Subscribers/getactivesubscribers`,
            {
              subscriberId: 0,
              userId: authState.user.UserId,
              ...transactionData.payload,
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                TransactionActions.IsLoading({ payload: false })
              );

              if (resData.succeeded === true) {
                return TransactionActions.SaveActiveSubscriber({
                  payload: {
                    subscriberList: resData.entity.subscriberlist,
                    totalCount: resData.entity.totalCount,
                  },
                });
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return TransactionActions.SaveActiveSubscriber({
                  payload: {
                    subscriberList: [],
                    totalCount: 0,
                  },
                });
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[Transaction] Failed To Get Active Subscribers ${errorRes.message}`
              );
            })
          );
      })
    )
  );
}
