import { createAction, props } from '@ngrx/store';

export const IsLoading = createAction(
  '[FormBuilder] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const ToggleDashboard = createAction(
  '[Manage SLA] Toggle Dashboard',
  props<{
    payload: any;
  }>()
);

export const ActivateSla = createAction(
  '[Manage SLA] Activate SLA',
  props<{
    payload: any;
  }>()
);
export const DeactivateSla = createAction(
  '[Manage SLA] Deactivate SLA',
  props<{
    payload: any;
  }>()
);

export const GetCategories = createAction(
  '[Manage SLA] Get Categories',
  props<{
    payload: any;
  }>()
);

export const SaveSigningRequestCategories = createAction(
  '[Manage SLA] Save Signing Request Categories',
  props<{
    payload: any;
  }>()
);

export const GetProcessCategories = createAction(
  '[Manage SLA] Get Process Manager Categories',
  props<{
    payload: any;
  }>()
);

export const GetSlaByCategoryName = createAction(
  '[Manage SLA] Get Sla By Category Name',
  props<{
    payload: {
      subscriberId: number;
      ownerId: string;
      categoryName: string;
    };
  }>()
);

export const SaveSlaByCategoryName = createAction(
  '[Manage SLA] Save Sla By Category Name',
  props<{
    payload: any;
  }>()
);

export const SaveProcessCategories = createAction(
  '[Manage SLA] Save Process Manager Categories',
  props<{
    payload: any;
  }>()
);

export const GetAllDepartments = createAction(
  '[Manage SLA] Get Departments',
  props<{
    payload: any;
  }>()
);

export const SaveAllDepartments = createAction(
  '[Manage SLA] Save All Departments',
  props<{
    payload: any;
  }>()
);

export const GetAllUsers = createAction(
  '[Manage SLA] Get Users',
  props<{
    payload: any;
  }>()
);

export const SaveAllUsers = createAction(
  '[Manage SLA] Save All Users',
  props<{
    payload: any;
  }>()
);

export const GetAllUnits = createAction(
  '[Manage SLA] Get Units',
  props<{
    payload: any;
  }>()
);

export const SaveAllUnits = createAction(
  '[Manage SLA] Save All Units',
  props<{
    payload: any;
  }>()
);

export const GetAllRoles = createAction(
  '[Manage SLA] Get Roles',
  props<{
    payload: any;
  }>()
);

export const SaveAllRoles = createAction(
  '[Manage SLA] Save All Roles',
  props<{
    payload: any;
  }>()
);

export const GetAllDivisions = createAction(
  '[Manage SLA] Get Divisions',
  props<{
    payload: any;
  }>()
);

export const SaveAllDivisions = createAction(
  '[Manage SLA] Save All Divisions',
  props<{
    payload: any;
  }>()
);

export const CreateSla = createAction(
  '[Manage SLA] Create SLA',
  props<{
    payload: any;
  }>()
);

export const CreationStatus = createAction(
  '[Manage SLA] Create SLA Successful',
  props<{
    payload: any;
  }>()
);

export const SaveAsDraft = createAction(
  '[Manage SLA] Save SLA as Draft',
  props<{
    payload: any;
  }>()
);

export const SetCurrentSla = createAction(
  '[Manage SLA] Set Current SLA',
  props<{
    payload: string;
  }>()
);

export const UpdateSla = createAction(
  '[Manage SLA] Update SLA',
  props<{
    payload: any;
  }>()
);

export const GetSlaById = createAction(
  '[Manage SLA] Get SLA by Id',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      id: string;
    };
  }>()
);
export const SaveSlaById = createAction(
  '[Manage SLA] Save SLA by Id',
  props<{
    payload: any;
  }>()
);

export const GetAllSlaInstances = createAction(
  '[Manage SLA] Get All SLA Instances',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      take: number;
      skip: number;
      dateRange: string;
      product: string;
      searchTerm?: string;
    };
  }>()
);

export const SaveAllSlaInstances = createAction(
  '[Manage SLA] Save All SLA Instances',
  props<{
    payload: any;
  }>()
);

export const GetRunningSlaInstances = createAction(
  '[Manage SLA] Get Running SLA Instances',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      take: number;
      skip: number;
      dateRange: string;
      product: string;
      searchTerm?: string;
    };
  }>()
);

export const SaveRunningSlaInstances = createAction(
  '[Manage SLA] Save Running SLA Instances',
  props<{
    payload: any;
  }>()
);

export const GetPausedSlaInstances = createAction(
  '[Manage SLA] Get Paused SLA Instances',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      take: number;
      skip: number;
      dateRange: string;
      product: string;
      searchTerm?: string;
    };
  }>()
);

export const SavePausedSlaInstances = createAction(
  '[Manage SLA] Save Paused SLA Instances',
  props<{
    payload: any;
  }>()
);

export const GetCompletedSlaInstances = createAction(
  '[Manage SLA] Get Completed SLA Instances',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      take: number;
      skip: number;
      dateRange: string;
      product: string;
      searchTerm?: string;
    };
  }>()
);

export const SaveCompletedSlaInstances = createAction(
  '[Manage SLA] Save Completed SLA Instances',
  props<{
    payload: any;
  }>()
);

export const GetPassedSlaInstances = createAction(
  '[Manage SLA] Get Passed SLA Instances',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      take: number;
      skip: number;
      dateRange: string;
      product: string;
      searchTerm?: string;
    };
  }>()
);

export const SavePassedSlaInstances = createAction(
  '[Manage SLA] Save Passed SLA Instances',
  props<{
    payload: any;
  }>()
);

export const GetFailedSlaInstances = createAction(
  '[Manage SLA] Get Failed SLA Instances',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      take: number;
      skip: number;
      dateRange: string;
      product: string;
      searchTerm?: string;
    };
  }>()
);

export const SaveFailedSlaInstances = createAction(
  '[Manage SLA] Save Failed SLA Instances',
  props<{
    payload: any;
  }>()
);

export const GetSlaInstancesById = createAction(
  '[Manage SLA] Get SLA Instances By Id',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      guId: string;
      skip: number;
      take: number;
      dateRange?: string;
      product?: string;
    };
  }>()
);

export const SaveSlaInstancesById = createAction(
  '[Manage SLA] Save SLA Instances By Id',
  props<{
    payload: any;
  }>()
);

export const GetSlaDashboardData = createAction(
  '[Manage SLA] Get SLA Dashboard Data',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      take: number;
      skip: number;
      dateRange: string;
      product: string;
      searchTerm?: string;
    };
  }>()
);

export const SaveSlaDashboardData = createAction(
  '[Manage SLA] Save SLA Dashboard Data',
  props<{
    payload: any;
  }>()
);

export const GetTopFiveTeams = createAction(
  '[Manage SLA] Get Top Five Teams',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      take: number;
      skip: number;
      dateRange: string;
      product: string;
    };
  }>()
);

export const SaveTopFiveTeams = createAction(
  '[Manage SLA] Save Top Five Teams',
  props<{
    payload: any;
  }>()
);

export const GetTopFivePerformers = createAction(
  '[Manage SLA] Get Top Five Performers',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      productCategory: string;
      take: number;
      skip: number;
      dateRange: string;
      product: string;
    };
  }>()
);

export const SaveTopFivePerformers = createAction(
  '[Manage SLA] Save Top Five Performers',
  props<{
    payload: any;
  }>()
);

export const GetSlaLineChartData = createAction(
  '[Manage SLA] Get SLA Line Chart Data',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      year: number;
      dateRange: string;
      product: string;
    };
  }>()
);

export const SaveSlaLineChartData = createAction(
  '[Manage SLA] Save SLA Line Chart Data',
  props<{
    payload: any;
  }>()
);

export const GetSlaDonutChartData = createAction(
  '[Manage SLA] Get SLA Donut Chart Data',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      dropdowntype: string;
      dateRange: string;
      product: string;
    };
  }>()
);

export const SaveSlaDonutData = createAction(
  '[Manage SLA] Save SLA Donut Chart Data',
  props<{
    payload: any;
  }>()
);

export const GetEscalationRateData = createAction(
  '[Manage SLA] Get SLA Escalation Rate Chart Data',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      year: number;
      dateRange: string;
      product: string;
    };
  }>()
);
export const SaveEscalationRateData = createAction(
  '[Manage SLA] Save SLA Escalation Rate Chart Data',
  props<{
    payload: any;
  }>()
);
export const GetAverageCompletionData = createAction(
  '[Manage SLA] Get SLA Average Completion Rate Chart Data',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      year: number;
      dateRange: string;
      product: string;
    };
  }>()
);
export const SaveAverageCompletionData = createAction(
  '[Manage SLA] Save SLA Average Completion Rate Chart Data',
  props<{
    payload: any;
  }>()
);

export const GetSlaMetricsPercentageData = createAction(
  '[Manage SLA] Get SLA Metrics Percentage Data',
  props<{
    payload: {
      ownerId: string;
      subscriberId: string;
      dateRange: string;
      product: string;
    };
  }>()
);

export const SaveSlaMetricsPercentageData = createAction(
  '[Manage SLA] Save SLA Metrics Percentage Data',
  props<{
    payload: any;
  }>()
);

export const UpdateShowAnalytics = createAction(
  '[Manage SLA] Update Show Analyticstics',
  props<{
    payload: { [key: string]: boolean };
  }>()
);

export const TourNextStep = createAction(
  '[SLA Tour] Tour Next Step',
  props<{
    payload: number;
  }>()
);
