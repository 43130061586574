import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as UserActions from 'src/app/@core/stores/user/user.actions';
import * as userSelectors from 'src/app/@core/stores/user/user.selectors';
import * as UserRoleActions from 'src/app/@core/stores/user-role/user-role.actions';
import * as userRoleSelectors from 'src/app/@core/stores/user-role/user-role.selectors';
// import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import { HelperService } from '../../services/helper.service';
import { Notification } from 'src/app/@core/interfaces';
import { NotificationService } from '../../services/notification.service';
// import { CsvFormatService } from '../../services/csv-format.service';
@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.scss'],
})
export class InviteUsersComponent implements OnInit, OnDestroy {
  isLoading: Observable<any>;
  isUserLoading!: Observable<boolean>;
  isUserRoleLoading!: Observable<boolean>;
  inviteUserByEmailForm!: FormGroup;
  inviteUserByLinkForm!: FormGroup;
  // inviteUserByBulk!: FormGroup;
  uploadedFileName: string;
  document: any;
  selectedTab: number = 0;
  link!: string;
  allRoles: any[] | null = null;
  signatureBase64!: string | null;
  // csvFile: string | null = null;
  bulkUpload: string | null = null;

  private subscription: Subscription = new Subscription();

  @ViewChild('documentUpload', { static: false }) documentUpload: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<InviteUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private store: Store<fromApp.AppState>,
    private helperService: HelperService,
    private notificationService: NotificationService // private csvFormat: CsvFormatService
  ) {}

  ngOnInit(): void {
    this.isUserLoading = this.store.pipe(
      select(userSelectors.getUserIsLoading)
    );

    this.isUserRoleLoading = this.store.pipe(
      select(userRoleSelectors.getUserRoleIsLoading)
    );

    this.createEmailInviteForm();

    this.createLinkInviteForm();

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.getAllRoles.bind(this)
    );
  }

  // initiateCheckUpDomainName() {
  //   this.store.dispatch(AuthActions.IsLoading({ payload: true }));

  //   this.helperService.manageDeveloperTokenAndCallFunction(
  //     this.CheckUpDomainName.bind(this)
  //   );
  // }

  // CheckUpDomainName() {
  //   this.store.dispatch(
  //     AuthActions.CheckUpDomainName({
  //       payload: {
  //         email: (this.registrationForm.value.email as string)
  //           .toLowerCase()
  //           .trim(),
  //       },
  //     })
  //   );
  // }

  createEmailInviteForm() {
    this.inviteUserByEmailForm = this.fb.group({
      invites: this.fb.array([this.createInvitee()]),
    });
  }

  createInvitee() {
    return this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      role: [null, Validators.required],
    });
  }

  createLinkInviteForm() {
    this.inviteUserByLinkForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      role: [null, Validators.required],
    });
  }

  get inviteUserByEmailFormArray() {
    return this.inviteUserByEmailForm.get('invites') as FormArray;
  }

  get inviteUserByLinkFormControls() {
    return this.inviteUserByLinkForm.controls;
  }

  getAllRoles() {
    this.store.dispatch(
      UserRoleActions.GetAllUserRoles({
        payload: {
          skip: 0,
          take: 0,
          userId: this.data?.userId,
          subscriberId: this.data?.subscriberId,
        },
      })
    );

    this.subscription.add(
      this.store
        .pipe(select(userRoleSelectors.getAllUserRoles))
        .subscribe((resData) => {
          if (resData) {
            this.allRoles = resData?.roles?.filter(
              (role: any) => role.status !== 2
            );
          }
        })
    );
  }

  addInvitee() {
    const control = <FormArray>this.inviteUserByEmailForm.controls['invites'];
    control.push(this.createInvitee());
  }

  removeInvitee(i: number) {
    const control = <FormArray>this.inviteUserByEmailForm.controls['invites'];
    control.removeAt(i);
  }

  onDropDocument(event: any) {
    this.onUploadDocument(event, 2);
  }

  onUploadDocument(event: any, useCase = 1) {
    const file =
      useCase === 2 ? event[0] : (event.target as HTMLInputElement).files![0];

    const extension = file.name.split('.')[1];

    if (extension !== 'csv') {
      const notification: Notification = {
        state: 'error',
        message: 'This file format is not supported ! please upload a csv file',
      };

      this.notificationService.openNotification(
        notification,
        'flwmn-notification-error'
      );
      return;
    }

    if (file && file.size <= 1000000) {
      this.uploadedFileName = file.name;

      const reader = new FileReader();

      reader.onload = () => {
        const fullBase64String = reader.result!.toString();

        this.bulkUpload = fullBase64String.split(',')[1];
      };

      reader.readAsDataURL(file);
    } else {
      this.documentUpload.nativeElement.value = null;

      const notification: Notification = {
        state: 'warning',
        message: "'Max file size is 1MB'",
      };

      this.notificationService.openNotification(
        notification,
        'flwmn-notification-warning'
      );
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.selectedTab = tabChangeEvent.index;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  submitInvite() {
    this.store.dispatch(UserActions.IsLoading({ payload: true }));

    const { invites } = this.inviteUserByEmailForm.value;

    const inviteesDictionary = invites.reduce(
      (previousValue: any, currentValue: any) => ({
        ...previousValue,
        [currentValue.email]: currentValue.role,
      }),
      {}
    );

    this.store.dispatch(
      UserActions.InviteMultipleUsers({
        payload: {
          roleId: invites[0].role,
          invitedRecipients: inviteesDictionary,
          userId: this.data?.userId,
          subscriberId: this.data?.subscriberId,
        },
      })
    );
  }

  onUploadFile() {
    this.store.dispatch(UserActions.IsLoading({ payload: true }));

    this.store.dispatch(
      UserActions.UploadBulkInvite({
        payload: {
          userId: this.data?.userId,
          csvFile: this.bulkUpload!,
          subscriberId: this.data?.subscriberId,
        },
      })
    );
  }

  onCancelFile() {
    this.bulkUpload = null;
  }

  downloadCsvTemplate() {
    // Define the CSV headers
    const csvHeaders = ['Email', 'Role'];

    // Convert the headers into a CSV string
    const csvContent = csvHeaders.join(',') + '\n';

    // Create a Blob from the CSV string
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute with a filename
    link.href = window.URL.createObjectURL(blob);
    link.download = 'user_template.csv';

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  }

  generateLink() {
    this.store.dispatch(UserActions.IsLoading({ payload: true }));

    this.store.dispatch(
      UserActions.InviteUserByLink({
        payload: {
          roleId: Number(this.inviteUserByLinkForm.value.role),
          recipientEmail: this.inviteUserByLinkForm.value.email,
          userId: this.data?.userId,
          subscriberId: this.data?.subscriberId,
        },
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
